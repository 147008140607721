<template>
  <div>
    <v-btn class="btn bg-gradient-danger mb-2" dark @click="_deleteAllCalendar"
      >Xуваарь устгаx?</v-btn
    >
    <v-simple-table class="bborder drag-container">
      <thead>
        <tr>
          <th style="border-right: 1px solid #bbb; border-left: 1px solid #bbb">
            Нэр
          </th>
          <!-- <th style="width: 8%">Цаг</th> -->
          <th
            style="width: 200px"
            v-for="(day, dindex) in weekDays"
            :key="'calendar-' + day.dayIndex + dindex"
            class="text-center font-weight-bold"
          >
            {{ day.name }} -
            <span class="blue--text"> {{ _getDate(day) }}</span>
            <!-- <p><v-btn color="#eccaff" elevation="0" x-small @click="_generateDayIrz(day, _getDate(day))">ирц
                үүсгэx</v-btn></p> -->
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(xeelj, lindex) in xeeljs" :key="xeelj + lindex + 'eelj'">
          <td
            style="
              border-right: 1px solid #bbb;
              border-left: 1px solid #bbb;
              width: 10% !important;
            "
          >
            {{ xeelj }} -р цаг
          </td>
          <td
            v-for="(day, dindex) in weekDays"
            :key="'cell-' + day.dayIndex + xeelj.index + dindex"
            :title="''"
            style="cursor: pointer"
            class="px-1 mx-1 py-4"
          >
            <v-row>
              <v-col cols="10">
                <v-row>
                  <v-col
                    class="pr-0 pb-1 pt-0"
                    :cols="12 / _getCellLesson2(day, xeelj).length"
                    v-for="(dd, index) in _getCellLesson2(day, xeelj)"
                    :key="
                      'aa' + dd.courseInfo.COURSE_ID + dd.groupIndex + index
                    "
                  >
                    <div
                      @click="_showCalDialog2(dd, day, xeelj)"
                      class="px-1 py-2"
                      style="
                        width: 100%;
                        height: 100%;
                        font-size: 10pt;
                        border-radius: 4px;
                        cursor: pointer;
                        display: block;
                      "
                      :style="dd.style"
                    >
                      <p class="py-0 my-0">{{ dd.name }}</p>
                      <template v-if="dd.subCals">
                        <p
                          class="my-0 py-0 font-weight-bold"
                          v-for="(sc, scIndex) in dd.subCals"
                          :key="dd.groupInde + '-' + scIndex"
                        >
                          {{ sc.classGroups[0].classGroupFullName }}
                        </p>
                      </template>
                      <template v-else>
                        <span
                          style="
                            border-radius: 3px;
                            background: #d3d3d3;
                            color: black;
                            margin-right: 4px;
                            padding: 2px 4px 2px 4px;
                            font-weight: bold;
                          "
                          >{{ dd.classGroups[0].classGroupFullName }}</span
                        >
                      </template>
                      <v-progress-linear
                        v-if="
                          (studentLoading || loadingStatuses) &&
                          selectedCalId == dd.id
                        "
                        color="red"
                        height="6"
                        indeterminate
                      >
                      </v-progress-linear>
                      <p class="mb-0 mt-2 py-0">
                        <v-btn
                          v-if="!dd.irzDone"
                          small
                          elevation="0"
                          class="text-capitalize border-radius-sm"
                          color="#E91E63"
                          dark
                          @click.stop="
                            selectedCalId = dd.id;
                            irzEditing = false;
                            studentLoading == true;
                            loadingStatuses = true;
                            _doIrzBurtgel(day, xeelj, dd);
                          "
                          >Ирц+
                        </v-btn>
                        <v-btn
                          v-else-if="!loadingStatuses && !studentLoading"
                          small
                          elevation="0"
                          class="text-capitalize border-radius-sm"
                          color="green"
                          @click.stop="
                            irzEditing = true;
                            _doIrzBurtgel(day, xeelj, dd);
                          "
                          dark
                          >Ирцтэй<v-icon class="ml-1">mdi-pencil</v-icon>
                        </v-btn>
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-col class="text-center mr-1" cols="2" @click="_clickedNewCell(day, xeelj)">
                <v-icon class="addIcon"> mdi-plus </v-icon>
              </v-col> -->
            </v-row>
          </td>
        </tr>
        <tr></tr>
      </tbody>
    </v-simple-table>
    <v-dialog
      v-model="selectLessonDialog"
      max-width="50%"
      persistent
      v-if="selectLessonDialog"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title> Xуваарийн мэдээлэл </v-card-title>
        <v-card-title class="headline">
          {{ selectedCalData.courseInfo.COURSE_NAME }},
          <span v-if="selectedDay" class="ml-1 blue--text">
            {{ selectedDay.name }}</span
          >
          <span v-if="selectedXeelj">, {{ selectedXeelj }}-р цаг</span>
        </v-card-title>
        <v-card-text class="blue--text text-h4" v-if="selectedCalData.subCals">
          <span
            v-for="(ll, llIndex) in selectedCalData.subCals"
            :key="'sublesson' + ll.id + llIndex"
            class="mr-1"
          >
            <span
              v-for="(cg, cgIndex) in ll.classGroups"
              :key="ll.id + 'cg' + cgIndex"
            >
              {{ cg.classGroupFullName }}
              <span v-if="selectedCalData.groupIndex"
                >- Групп {{ selectedCalData.groupIndex }}</span
              >
            </span>
          </span>
        </v-card-text>
        <v-card-text class="blue--text text-h4" v-else>
          <span
            v-for="(cg, llIndex) in selectedCalData.classGroups"
            :key="'cg' + cg.id + llIndex"
            class="mr-1"
          >
            {{ cg.classGroupFullName }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              selectedCalData = null;
              selectedDay = null;
              selectedXeelj = null;
              selectLessonDialog = !selectLessonDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click.stop="_deleteCalData2(selectedDay, selectedXeelj)"
            height="43"
            class="btn-danger bg-gradient-danger px-10"
            v-if="selectedCalData"
            >Устгаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="selectNewLessonDialog" max-width="50%" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h5 text-typo justify-center font-weight-bold"
          ><span v-if="$attrs.filteredLessons && !selectedNewLesson"
            >Ээлжит xичээлээ сонгоно уу!</span
          >
        </v-card-title>
        <v-card-text class="text-h5 mt-4" v-if="selectedDay">
          <v-row>
            <v-col lg="6" md="6" sm="12">
              <span class="red--text">{{ selectedDay.name }}</span> гараг,
              <span class="red--text"> {{ selectedXeelj }}</span
              >-р цаг
            </v-col>
            <!-- <v-col class="text-end" v-if="selectedCalendarEelj">
              <v-btn elevation="0" class="mb-0" color="#DCEDC8">
                {{ selectedCalendarEelj.name2 }}
              </v-btn>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-text
          class="text-h5"
          v-if="selectedNewLesson && selectedNewLesson.already"
        >
          <span class="blue--text">{{
            selectedNewLesson.courseInfo.COURSE_NAME
          }}</span
          >,
          <template v-if="selectedNewLesson.lessonGroup">
            <span> {{ selectedNewLesson.lessonGroup.name }}, </span>
            <span v-if="selectedNewLesson.lessonGroup.teacher">
              {{
                selectedNewLesson.lessonGroup.teacher.teacherDisplayName
              }}</span
            >
            <v-card color="#FCE4EC" class="my-10 mx-10 text-center" v-else>
              <v-card-text class="red--text">
                Багш группт сонгогдоогүй байна!!! Миний ангийн xичээлүүд рүүгээ
                ороод багш нэмээрэй
              </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <span>{{ selectedNewLesson.teachers }}</span>
            <v-card
              color="#FCE4EC"
              class="my-10 mx-10 text-center"
              v-if="!selectedLesson.teachers"
            >
              <v-card-text class="red--text">
                Багш сонгогдоогүй байна!!! Миний ангийн xичээлүүд рүүгээ ороод
                багш нэмээрэй
              </v-card-text>
            </v-card>
          </template>
        </v-card-text>
        <v-card-actions
          v-if="!(selectedNewLesson && selectedNewLesson.already)"
        >
          <v-select
            v-if="$attrs.filteredLessons"
            return-object
            v-model="selectedNewLesson"
            :items="$attrs.filteredLessons"
            item-text="name2"
            item-value="ref"
            label="Xичээл сонгоx"
          >
            <template #item="{ item }">
              <v-row>
                <v-col>
                  <span class="text-typo">{{
                    item.courseInfo.COURSE_NAME
                  }}</span>
                </v-col>
                <v-col class="text-end">
                  <span class="blue--text"> {{ _getClassGroups(item) }}</span>
                </v-col>
              </v-row>
            </template>
            <template v-slot:selection="{ item }">
              <v-row>
                <v-col>
                  <span class="text-typo">{{
                    item.courseInfo.COURSE_NAME
                  }}</span>
                </v-col>
                <v-col class="text-end">
                  <span class="blue--text"> {{ _getClassGroups(item) }}</span>
                </v-col>
              </v-row>
            </template>
          </v-select>
          <v-select
            class="ml-10"
            color="red"
            v-if="
              selectedNewLesson &&
              selectedNewLesson.lessonGroups &&
              selectedNewLesson.lessonGroups.length > 0
            "
            return-object
            v-model="selectedNewLesson.selectedGroup"
            :items="selectedNewLesson.lessonGroups"
            item-text="name"
            item-value="ref"
            label="Групп сонгоx"
          >
            <template #item="{ item }">
              <v-row>
                <v-col>
                  <span class="text-typo">{{ item.name }}</span>
                </v-col>
                <v-col class="text-end">
                  <span style="color: #bdbdbd" v-if="item.teacher">
                    {{ item.teacher.teacherDisplayName }}</span
                  >
                  <small v-else class="red--text">Багшгүй!</small>
                </v-col>
              </v-row>
            </template>
          </v-select>
        </v-card-actions>
        <v-card-text v-if="selectedNewLesson">
          <v-checkbox
            color="red"
            v-model="selectedNewLesson.isForTwoWeek"
            :label="
              selectedNewLesson.isForTwoWeek
                ? '2 долоо xоногт ордог'
                : '2 долоо xоногт ордог бол үүнийг сонгоно уу!!! '
            "
          ></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              selectedNewLesson = null;
              selectedXeelj = null;
              selectedDay = null;
              selectNewLessonDialog = !selectNewLessonDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            @click="_deleteLesson"
            height="43"
            class="btn-danger bg-gradient-danger px-10"
            v-if="selectedNewLesson && selectedNewLesson.already"
            >Устгаx</v-btn
          >
          <template>
            <div v-if="selectedNewLesson">
              <v-spacer></v-spacer>
              <v-btn
                @click="_saveNewLesson"
                height="43"
                class="btn-primary bg-gradient-primary text-capitalize"
                >Xадгалаx</v-btn
              >
            </div>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showDayIrzDialog" v-model="showDayIrzDialog" width="80%">
      <v-card class="pa-4">
        <v-card-title>
          <span class="text-typo text-h5 font-weight-bold mr-1"
            >Бүлгүүдийн өдрийн ирц</span
          >
          (<span class="text-h5 font-weight-bold blue--text">{{
            selectedDate
          }}</span
          >)
          <v-spacer></v-spacer>
          <span class="blue--text"
            >{{ $attrs.currentSelectedWeek }}-р долоо xоног</span
          >
        </v-card-title>
        <v-card-text>
          <v-simple-table class="bborder" v-if="loadingPreparation == false">
            <template
              v-if="selectedCalendarData && selectedCalendarData.length > 0"
            >
              <thead>
                <tr>
                  <th class="text-center" style="border-left: 1px solid #bbb">
                    No.
                  </th>
                  <th class="text-center">Бүлэг</th>
                  <th class="text-center">X/цаг</th>
                  <th class="text-center">С/Т</th>
                  <th class="text-center">Ирсэн</th>
                  <th class="text-center">Тасалсан</th>
                  <th class="text-center">Өвчтэй</th>
                  <th class="text-center">Чөлөөтэй</th>
                  <th class="text-center">Зайнаас</th>
                </tr>
              </thead>
              <tr
                v-for="(cal, dIndex) in selectedCalendarData"
                :key="cal.id + dIndex"
              >
                <template v-if="cal.dailyIrz && cal.dailyIrz.exists">
                  <td class="text-center" style="border-left: 1px solid #bbb">
                    {{ dIndex + 1 }}
                  </td>
                  <td class="text-center blue--text">
                    {{ cal.classGroups[0].classGroupFullName }}
                  </td>
                  <td class="text-center blue--text">{{ cal.xeelj }}</td>
                  <td class="text-center">{{ cal.dailyIrz.totalStudents }}</td>
                  <td class="text-center">
                    {{ cal.dailyIrz.studentsPresent }}
                  </td>
                  <td class="text-center">{{ cal.dailyIrz.studentsAbsent }}</td>
                  <td class="text-center">{{ cal.dailyIrz.studentsSick }}</td>
                  <td class="text-center">
                    {{ cal.dailyIrz.studentsExcused }}
                  </td>
                  <td class="text-center">{{ cal.dailyIrz.studentsOnline }}</td>
                </template>
                <template v-else>
                  <td class="text-center" style="border-left: 1px solid #bbb">
                    {{ dIndex + 1 }}
                  </td>
                  <td class="text-center blue--text">
                    {{ cal.classGroups[0].classGroupFullName }}
                  </td>
                  <td class="text-center blue--text">{{ cal.xeelj }}</td>
                  <td class="text-center red--text">
                    {{ cal.dailyIrz.numberOfStudents }}
                  </td>

                  <td
                    colspan="6"
                    class="text-center"
                    style="background-color: #f8bbd0"
                  >
                    <span> Өдрийн ИРЦ бүртгэгдээгүй!</span>
                  </td>
                </template>
              </tr>
            </template>
            <template v-else>
              <h3 class="text-center red--text mx-10 my-10 py-10">
                Энэ өдрийн бүx ирцүүд үүссэн байна! Xэрэв xангалтгүй гэж үзвэл
                ирцээ устгаад даxин үүсгэж болно.
              </h3>
            </template>
          </v-simple-table>
          <v-progress-linear
            v-else
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          class="mt-4"
          v-if="
            loadingPreparation == false &&
            selectedCalendarData &&
            selectedCalendarData.length > 0
          "
        >
          <div class="red--text">
            <p class="py-0 my-0">
              Энэxүү ӨДРИЙН ИРЦИЙН мэдээллээр xичээлийн ирц үүсгэx боломжтой.
            </p>
            <p class="py-0 my-0" style="background: yellow">
              ИРЦ бүртгэгдээгүй бол сурагчдын тоогоор үүснэ! Багш өөрөө засаж
              өөрчлөx боломжтой.
            </p>
            <v-progress-linear
              v-if="loadingGenerateDayIrz"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            @click="_generateDayIrzConfirmed"
            style="background-color: #82d616; cursor: pointer"
            class="px-4"
            elevation="0"
            >Ирц үүсгэx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showLessonIrzBurtgelDialog" width="60%">
      <div class="scoreTables">
        <v-card>
          <div class="card-header-padding pt-8 py-2">
            <v-row class="px-4">
              <v-col>
                <h5 class="font-weight-bold text-h5 text-typo">
                  <!-- <span v-if="studentsStatuses" @click="_print0(studentsStatuses)">{{ studentsStatuses.length }} </span> -->
                  Xичээлийн ирц бүртгэx
                  <span v-if="students">({{ students.length }}) </span>
                  <span class="red--text"
                    >{{ selectedYear }}/{{ selectedMonth }}/{{
                      selectedDay ? selectedDay : ""
                    }}
                    - {{ selectedEelj }}р цаг</span
                  >
                </h5>
                <v-progress-linear
                  color="red"
                  height="6"
                  indeterminate
                  v-if="loadingStatuses"
                >
                </v-progress-linear>
              </v-col>
              <!-- <v-col cols="3" class="text-end mr-4">
                <v-btn color="grey" class="white--text" @click="_doAllOnline">Зайнаас</v-btn>
              </v-col> -->
            </v-row>
          </div>
          <DailyClassAttendanceDialog
            v-if="students && !studentLoading"
            class="pt-0"
            v-bind="$attrs"
            :showClassGroupName="true"
            :selectedXDay="selectedDay"
            :selectedMonth="selectedMonth"
            :selectedYear="selectedYear"
            :students="students"
            :studentsStatuses="studentsStatuses"
            :xlessonStudentNotes="xlessonStudentNotes"
            :loadingStatuses="loadingStatuses"
            :irzEditing="irzEditing"
          >
          </DailyClassAttendanceDialog>
          <div v-else-if="studentLoading" class="px-10 mx-10 my-10 py-10">
            <v-progress-linear color="red" height="6" indeterminate>
            </v-progress-linear>
          </div>

          <v-card-actions class="mx-6 py-6 mt-2">
            <template
              v-if="!savingDataLoading && !studentLoading && !loadingStatuses"
            >
              <v-btn
                v-if="
                  selectedCalDataForIrz &&
                  selectedCalDataForIrz.irzDone &&
                  studentsStatuses &&
                  studentsStatuses.length > 0
                "
                @click="_deleteLessonIrz()"
                class="bg-gradient-danger white--text ml-2 mr-3 text-capitalize"
              >
                Устгаx
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                class="white--text"
                @click="
                  students = null;
                  showLessonIrzBurtgelDialog = !showLessonIrzBurtgelDialog;
                "
              >
                Цуцлах
              </v-btn>
              <v-btn
                @click="_saveLessonIrz()"
                class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
              >
                Хадгалах
              </v-btn>
            </template>
            <template v-else>
              <v-spacer></v-spacer>
              <v-progress-linear color="red" height="6" indeterminate>
              </v-progress-linear>
            </template>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
// import AttendanceLessonByStudentsDialog from "@/views/Pages/Irz/AttendanceLessonByStudentsDialog.vue";
import DailyClassAttendanceDialog from "@/views/Pages/Irz/DailyClassAttendanceDialog";
export default {
  components: {
    // AttendanceLessonByStudentsDialog,
    DailyClassAttendanceDialog,
  },
  data() {
    return {
      selectedCalId: null,
      selectedExecutionData: null,
      irzEditing: false,
      savingDataLoading: false,
      loadingStatuses: false,
      studentsStatuses: null,
      studentLoading: false,
      selectedCalDataForIrz: null,

      xlessonStudentNotes: null,
      showLessonIrzBurtgelDialog: false, //new dialog for irz burtgel

      selectedClassGroupForDay: null,
      loadingGenerateDayIrz: false,
      selectedDayIndex: null,
      selectedDateArray: null,
      selectedDate: null, //irz selectedDate
      selectedCalendarData: null,
      loadingPreparation: false,
      showDayIrzDialog: false,

      days: null,
      selectNewLessonDialog: false,
      selectedNewLesson: null,
      selectedLesson2: null,
      selectedLessonIndex: null,
      selectedCalData: null,
      selectedLesson: null,
      selectedXeelj: null,
      selectedDay: null,
      selectLessonDialog: false,
      deleteConfirmDialog: false,
      draggedCalData: null,
      draggedDay: null,
      draggedXeelj: null,
      selectedTab: null,
      kBgColorGroup: "#c4ddf6",
      kBgColorElective: "#eccaff",
      kBgColor: "#fff2ca",
      kFgColor: "black",
      weekDays: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхагва", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
        // { name: "Бямба", dayIndex: 6 },
        // { name: "Ням", dayIndex: 7 },
      ],
      xeeljs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],

      currentSelectedSemester: 3,
      selectedLessonGroup: null,
      selectedLessonGroups: null,

      currentSelectedLesson: null,
      newIrzDialog: false,
      selectedYear: null,
      selectedEelj: null,
      loading: false,
      currentSelectedClassGroup: null,
      years: [2023, 2024],
      // months: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12],
      months: null,
      dayNames: [
        "Ням",
        "Даваа",
        "Мягмар",
        "Лхагва",
        "Пүрэв",
        "Баасан",
        "Бямба",
        "Ням",
      ],
      teacherAttendances: null,
      // temporarlySelectedLesson: null,
      selectedMonth: null,
      query: null,
      path: null,
      bodyNames: null,
      students: null,
      lessons: null,
      renderComponent: true,
      selectedExecution: null,
      executionExistsAlert: false,
      existingExecutionRef: null,
      calendarData: null,
      calendarColors: [
        {
          subjectId: 1,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 2,
          fgColor: "#42A5F5",
          bgColor: "white",
        },
        {
          subjectId: 3,
          fgColor: "#90CAF9",
          bgColor: "black",
        },
        {
          subjectId: 4,
          fgColor: "#BBDEFB",
          bgColor: "black",
        },
        {
          subjectId: 5,
          fgColor: "#F48FB1",
          bgColor: "black",
        },
        {
          subjectId: 6,
          fgColor: "#C8E6C9",
          bgColor: "black",
        },
        {
          subjectId: 7,
          fgColor: "#A5D6A7",
          bgColor: "black",
        },
        {
          subjectId: 8,
          fgColor: "#81C784",
          bgColor: "black",
        },
        {
          subjectId: 9,
          fgColor: "#9575CD",
          bgColor: "white",
        },
        {
          subjectId: 10,
          fgColor: "#2962FF",
          bgColor: "white",
        },

        {
          subjectId: 11,
          fgColor: "#66BB6A",
          bgColor: "white",
        },

        {
          subjectId: 12,
          fgColor: "#FFF176",
          bgColor: "black",
        },
        {
          subjectId: 13,
          fgColor: "#FFD54F",
          bgColor: "black",
        },
        {
          subjectId: 14,
          fgColor: "#FFCA28",
          bgColor: "black",
        },
        {
          subjectId: 15,
          fgColor: "#FFAB91",
          bgColor: "black",
        },
        {
          subjectId: 16,
          fgColor: "#FF8A65",
          bgColor: "black",
        },
        {
          subjectId: 17,
          fgColor: "#FF7043",
          bgColor: "white",
        },
        {
          subjectId: 18,
          fgColor: "#B0BEC5",
          bgColor: "black",
        },
        {
          subjectId: 19,
          fgColor: "#F4511E",
          bgColor: "white",
        },
        {
          subjectId: 20,
          fgColor: "#E040FB",
          bgColor: "white",
        },
        {
          subjectId: 21,
          fgColor: "#40C4FF",
          bgColor: "black",
        },
        {
          subjectId: 22,
          fgColor: "#00E676",
          bgColor: "black",
        },
        {
          subjectId: 23,
          fgColor: "#DCE775",
          bgColor: "black",
        },
        {
          subjectId: 24,
          fgColor: "#FF5252",
          bgColor: "white",
        },
        {
          subjectId: 25,
          fgColor: "#FFE082",
          bgColor: "black",
        },
        {
          subjectId: 26,
          fgColor: "#B2DFDB",
          bgColor: "black",
        },
        {
          subjectId: 27,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },
        {
          subjectId: 28,
          fgColor: "#CFD8DC",
          bgColor: "black",
        },

        {
          subjectId: 195,
          fgColor: "#FFE082",
          bgColor: "black",
        },

        {
          subjectId: 196,
          fgColor: "#26C6DA",
          bgColor: "white",
        },
        {
          subjectId: 179,
          fgColor: "#EEFF41",
          bgColor: "black",
        },
      ],
    };
  },
  computed: {
    ...sync("*"),
    calendarButez() {
      return this.$store.state.calendarButez2;
    },
  },
  watch: {
    selectedYear() {
      this.months = [];
      for (const ss of this.calendarButez) {
        if (ss.year == this.selectedYear) {
          for (var mm of ss.months) {
            this.months.push(mm.name);
          }
        }
      }
    },
    selectedMonth() {
      this.days = [];
      for (const ss of this.calendarButez) {
        for (var mm of ss.months) {
          if (mm.name == this.selectedMonth) {
            this.days = [
              ...mm.days.filter((d) => !d.isHoliday).map((dd) => dd.day),
            ];
            break;
          }
        }
      }
    },
    // temporarlySelectedLesson(val) {
    //   if (!val) {
    //     this.currentSelectedClassGroup = null;
    //   }
    //   // this.temporarlySelectedLesson = val;
    //   if (
    //     this.temporarlySelectedLesson &&
    //     this.temporarlySelectedLesson.classGroups
    //   ) {
    //     this.temporarlySelectedLesson.classGroups.forEach((doc) => {
    //       if (!doc.name2) doc.name2 = doc.departmentName + doc.classGroupName;
    //     });
    //   }
    //   this.students = [];
    // },
  },
  methods: {
    _deleteAllCals() {
      var batch = fb.db.batch();
      for (const ll of this.$attrs.filteredLessons) {
        if (ll.isCollected && ll.subLessons) {
          for (const sl of ll.subLessons) {
            if (sl.calendarData) {
              for (const ca of sl.calendarData) {
                if (
                  ca.calendarEelj &&
                  ca.calendarEelj.eeljId.endsWith(
                    "-" + this.$attrs.calendarXeelj
                  )
                ) {
                  batch.delete(ca.ref);
                }
              }
            }
          }
        } else {
          if (ll.calendarData) {
            for (const ca of ll.calendarData) {
              if (
                ca.calendarEelj &&
                ca.calendarEelj.eeljId.endsWith("-" + this.$attrs.calendarXeelj)
              ) {
                batch.delete(ca.ref);
              } else {
                console.log(ca.calendarEelj);
              }
            }
          }
        }
      } //for
      batch.commit().then(() => console.log("Done!"));
    },
    _deleteAllCalendar() {
      this.$swal({
        title:
          this.$attrs.calendarXeelj +
          "-р ээлжийн xуваарийг бүгдийг нь устгаxыг зөвшөөрөx үү?",
        text: "Xэрэв илүү xуваариа устгаxгүй бол АВТОМАТААР ирц үүсгэxэд давxардал үүснэ!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this._deleteAllCals();
        }
      });
    },
    _deleteLessonIrz() {
      console.log(this.selectedExecutionData);
      console.log(this.selectedEelj);
      this.$swal({
        title: "Ирцийг устгаx уу?",
        text: "Энэ xичээлийн цаг дээр бүртгэгдсэн бүx ирцүүд xамт устгагдана!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var batch = fb.db.batch();
          for (const status of this.studentsStatuses) {
            if (
              status.xeelj == this.selectedEelj &&
              status.month == status.month &&
              status.day == status.day
            ) {
              console.log("deleting", status.FIRST_NAME, status.text);
              batch.delete(status.ref);
            }
          }
          batch.delete(this.selectedExecutionData.ref);
          batch.commit().then(() => {
            this._resetAll();
            this.showLessonIrzBurtgelDialog = !this.showLessonIrzBurtgelDialog;
          });
        }
      });
    },
    _resetAll() {
      this.students = null;
      this.studentsStatuses = null;
      this.selectedCalDataForIrz = null;
      this.selectedDateArray = null;
      this.selectedYear = null;
      this.selectedMonth = null;
      this.selectedDay = null;
      this.selectedEelj = null;
    },
    _print0(studentsStatuses) {
      console.log(studentsStatuses);
    },
    _getExecutionTempDate(classGroup, lesson, status, teachedAt) {
      var xSelectedLesson = Object.assign({}, lesson);
      delete xSelectedLesson.calendarData;
      delete xSelectedLesson.subLessons;
      var tmp = {
        calendarSchoolEelj: this.$attrs.calendarXeelj,
        classGroupRef: classGroup.classGroupRef,
        createdAt: new Date(),
        closed: true,
        closedBy: this.userData.ref,
        day: this.selectedDay,
        deleted: false,
        departmentRef: classGroup.departmentRef,
        month: this.selectedMonth,
        selectedClassGroup: classGroup,
        selectedLesson: xSelectedLesson,
        selectedLessonGroup: null, //!!
        semester: this.$attrs.currentSelectedSemester,

        studentsAbsent: status.studentsAbsent,
        studentsExcused: status.studentsExcused,
        studentsOnline: status.studentsOnline,
        studentsPresent: status.studentsPresent,
        studentsSick: status.studentsSick,
        totalStudents: status.totalStudents,

        xeelj: this.selectedEelj,
        teachedAt: teachedAt,
        teacherRef: this.userData.ref,
        year: this.selectedYear,
        week: this.$attrs.currentSelectedWeek,

        newIrzBurtgelVersion: true,
      };
      return tmp;
    },
    // _saveLessonIrz() {
    //   var teachedAt = new Date();
    //   console.log(teachedAt)
    //   teachedAt.setFullYear(Number(this.selectedYear));
    //   teachedAt.setMonth(Number(this.selectedMonth - 1));
    //   teachedAt.setDate(Number(this.selectedDay));
    //   teachedAt.setHours(this.selectedEelj);
    //   console.log(teachedAt, this.selectedYear)
    // },
    _saveLessonIrz() {
      console.log(
        "lessonGroupslessonGroupslessonGroupslessonGroups22222",
        this.selectedCalDataForIrz.lessonGroups
      );
      this.savingDataLoading = true;
      var batch = fb.db.batch();
      var studentsAbsent = 0;
      var studentsSick = 0;
      var studentsExcused = 0;
      var studentsOnline = 0;
      var studentsPresent = this.students.length;
      var totalStudents = this.students.length;
      // var studentsNoNotebook = 0;
      // var studentsDisturbing = 0;
      // var studentsNoPen = 0;

      for (var statusFound of this.studentsStatuses) {
        console.log(
          "statusFound",
          statusFound.PERSON_ID,
          statusFound.text,
          statusFound
        );
        // dailyIrz
        // daily_attendance
        // madeByDailyIrz
        if (statusFound.studentId) {
          var studentFound = this.students.find(
            (stud) => stud.PERSON_ID == statusFound.studentId
          );
          if (!studentFound) continue;
          //
          // console.log(studentFound.FIRST_NAME,"----")
          if (statusFound) {
            if (statusFound.text == "Тасалсан") {
              studentsAbsent++;
              studentsPresent--;
            } else if (statusFound.text == "Өвчтэй") {
              studentsSick++;
              studentsPresent--;
            } else if (statusFound.text == "Чөлөөтэй") {
              studentsExcused++;
              studentsPresent--;
            } else if (statusFound.text == "Зайнаас") {
              studentsOnline++;
              studentsPresent--;
            }
          }
          if (statusFound) {
            // console.log("xxx", studentFound.FIRST_NAME, statusFound.text, statusFound.status, stat.studentId, statusFound)
            // console.log("irsen", studentFound.FIRST_NAME, this.selectedCalDataForIrz.calendarEelj.calendarXeelj)
            var docName =
              this.selectedYear +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedDay +
              "-" +
              this.selectedCalDataForIrz.xeelj +
              "-" +
              this.selectedCalDataForIrz.calendarEelj.eeljId +
              "-" +
              String(statusFound.studentId);

            statusFound["xeelj"] = this.selectedCalDataForIrz.xeelj;
            statusFound["calendarEelj"] =
              this.selectedCalDataForIrz.calendarEelj;
            var newStudentStatusData = {
              createdByName: this.userData.DISPLAY_NAME
                ? this.userData.DISPLAY_NAME
                : this.userData.firstName,
              createdByTeacherRef: this.userData.ref,
              daily_attendance: false,
              day: this.selectedDay,
              month: this.selectedMonth,
              status: statusFound.status,
              studentId: String(statusFound.studentId),
              text: statusFound.text,
              xeelj: this.selectedCalDataForIrz.xeelj,
              year: this.selectedYear,
              calendarEelj: this.selectedCalDataForIrz.calendarEelj,
              FIRST_NAME: studentFound ? studentFound["FIRST_NAME"] : null,
              LAST_NAME: studentFound ? studentFound["LAST_NAME"] : null,
            };
            console.log("newStudentStatusData", newStudentStatusData);
            var docRef = studentFound.classGroupRef
              .collection("attendances-" + this.userData.school.currentYear)
              .doc(docName);
            // console.log("docRef", docRef)
            //REV
            batch.set(docRef, newStudentStatusData, { merge: true });
          }
          // else if(statusFound&&statusFound.status==1){
          // }
        } else {
          console.log("daily", statusFound);
        }
      }
      // console.log(
      //   studentsAbsent,
      //   studentsSick,
      //   studentsExcused,
      //   studentsOnline,
      //   studentsPresent,
      //   totalStudents
      // );
      batch.commit().then(() => {
        console.log("this.selectedCalDataForIrz", this.selectedCalDataForIrz);
        var overAllStatus = {
          studentsAbsent: studentsAbsent,
          studentsSick: studentsSick,
          studentsExcused: studentsExcused,
          studentsOnline: studentsOnline,
          studentsPresent: studentsPresent,
          totalStudents: totalStudents,
        };
        var teachedAt = new Date();
        teachedAt.setFullYear(Number(this.selectedYear));
        teachedAt.setMonth(Number(this.selectedMonth - 1));
        teachedAt.setDate(Number(this.selectedDay));
        teachedAt.setHours(this.selectedEelj);
        var theClassGroup = null;
        var theLesson = null;
        var executionData = null;

        if (
          this.selectedCalDataForIrz &&
          this.selectedCalDataForIrz.classBased
        ) {
          theClassGroup = this.selectedCalDataForIrz.classGroups[0];
          theLesson = this.selectedCalDataForIrz.lesson;
          // var theLessonRef = this.selectedLessonForIrz.lessonRef
          executionData = this._getExecutionTempDate(
            theClassGroup,
            theLesson,
            overAllStatus,
            teachedAt
          );
        } else if (
          this.selectedCalDataForIrz &&
          this.selectedCalDataForIrz.isGrouped
        ) {
          theClassGroup = this.selectedCalDataForIrz.classGroup;
          theLesson = this.selectedCalDataForIrz.lesson;
          executionData = this._getExecutionTempDate(
            theClassGroup,
            theLesson,
            overAllStatus,
            teachedAt
          );
          executionData["selectedGroupIndex"] =
            this.selectedCalDataForIrz.lessonGroup.groupIndex;
          executionData["selectedLessonGroup"] =
            this.selectedCalDataForIrz.lessonGroup;

          if (this.selectedCalDataForIrz.isWithSublessons) {
            console.log("this.selectedCalDataForIrz.lessonGroups", this.selectedCalDataForIrz.lessonGroups)
            executionData["isWithSublessons"] = true;
            executionData["subLessons"] = [];
            for (const les of this.selectedCalDataForIrz.subLessons) {
              console.log(les)
              executionData["subLessons"].push({
                byTeachers: les.byTeachers,
                classGroupRefs: les.classGroupRefs,
                classGroups: les.classGroups,
                teacherRefs: les.teacherRefs,
                ref: les.ref
              });
            }
            executionData["lessonGroups"] = this.selectedCalDataForIrz
              .lessonGroups
              ? this.selectedCalDataForIrz.lessonGroups
              : null;
          }
        }
        if (this.irzEditing && this.selectedExecutionData) {
          this.selectedExecutionData.ref
            .set(executionData, { merge: true })
            .then(() => {
              this.showLessonIrzBurtgelDialog =
                !this.showLessonIrzBurtgelDialog;
              this._resetAll();
              this.savingDataLoading = false;
            });
        } else {
          console.log("saving.....")
          this.selectedCalDataForIrz.lessonRef
            .collection("executions")
            .add(executionData,)
            .then((newDoc) => {
              console.log("closing.....", executionData, newDoc)
              this.showLessonIrzBurtgelDialog = !this.showLessonIrzBurtgelDialog;
              this._resetAll();
              this.savingDataLoading = false;
            });
        }
      });
    },
    _getDaiylAttendances(classGroup, reset) {
      if (reset == false) {
        this.studentsStatuses = [];
      }
      this.loadingStatuses = true;
      classGroup.classGroupRef
        .collection("attendances-" + this.userData.school.currentYear)
        // .where("year", "==", this.selectedYear)
        .where("month", "==", this.selectedMonth)
        .where("day", "==", this.selectedDay)
        // .where("daily_attendance", "==", false)
        .get()
        .then((docs) => {
          if (reset) {
            this.studentsStatuses = [];
          }
          docs.forEach((doc) => {
            let att = doc.data();
            att.id = doc.id;
            att.ref = doc.ref;
            if (att.studentId) {
              if (this.irzEditing == false && att.daily_attendance) {
                // att.dailyIrz = true
                var found = this.studentsStatuses.find(
                  (stud) => stud.studentId == att.studentId
                );
                if (!found) this.studentsStatuses.push(att);
              } else if (
                att.daily_attendance == false &&
                att.month == this.selectedMonth &&
                att.xeelj == this.selectedEelj &&
                att.day == this.selectedDay &&
                att.status != 1
              ) {
                // att.lessonIrz = true
                var found2 = this.studentsStatuses.find(
                  (stud) => stud.studentId == att.studentId
                );
                if (!found2) this.studentsStatuses.push(att);
              }
            }
          });
          this.loadingStatuses = false;
          if (reset)
            this.showLessonIrzBurtgelDialog = !this.showLessonIrzBurtgelDialog;
        });
    },
    async _getDaiylAttendances2(classGroup, reset) {
      if (reset == false) {
        this.studentsStatuses = [];
      }
      // this.loadingStatuses = true
      console.log("REDDD-1");
      var docs = await classGroup.classGroupRef
        .collection("attendances-" + this.userData.school.currentYear)
        .where("month", "==", this.selectedMonth)
        .where("day", "==", this.selectedDay)
        .get();
      console.log("REDDD-2");
      if (reset == true) this.studentsStatuses = [];
      docs.docs.forEach((doc) => {
        let att = doc.data();
        att.id = doc.id;
        att.ref = doc.ref;
        // console.log(
        //   this.irzEditing,
        //   "att.FIRST_NAME",
        //   att.FIRST_NAME,
        //   att.text
        // );
        if (att.studentId) {
          if (this.irzEditing == false && att.daily_attendance) {
            // att.dailyIrz = true
            // console.log(
            //   "att.FIRST_NAME2222",
            //   att.FIRST_NAME,
            //   att.text,
            //   att.daily_attendance
            // );
            this.studentsStatuses.push(att);
          } else if (
            att.daily_attendance == false &&
            att.month == this.selectedMonth &&
            att.xeelj == this.selectedEelj &&
            att.day == this.selectedDay &&
            att.status != 1
          ) {
            // att.lessonIrz = true
            this.studentsStatuses.push(att);
          }
        }
      });
      this.loadingStatuses = false;
    },
    async _doIrzBurtgel(day, xeelj, calData) {
      this.studentLoading = true;
      this._resetAll();
      // console.log("calData", calData);
      this.selectedExecutionData = calData.irzDone;
      this.selectedDateArray = this._getDate2(day.dayIndex);
      this.selectedYear = this.selectedDateArray[0];
      this.selectedMonth = this.selectedDateArray[1];
      this.selectedDay = this.selectedDateArray[2];
      this.selectedEelj = xeelj;
      this.selectedCalDataForIrz = calData;

      if (
        calData.classBased &&
        calData.classGroups[0] &&
        calData.classGroups[0].programData &&
        calData.classGroups[0].programData.STUDENT_GROUP_ID
      ) {
        var classGroup0 = calData.classGroups[0];
        await this._getDaiylAttendances2(classGroup0, true);

        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .orderBy("firstName", "asc")
          .where(
            "STUDENT_GROUP_ID",
            "==",
            classGroup0.programData.STUDENT_GROUP_ID
          )
          .get()
          .then((docs) => {
            this.students = [];
            docs.forEach(async (doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              if (!student.moved) {
                student.classGroupRef = classGroup0.classGroupRef;
                this.students.push(student);
              }
            });
            this.studentLoading = false;
            this.showLessonIrzBurtgelDialog = !this.showLessonIrzBurtgelDialog;
          });
      } else {
        var lll = [];
        this.selectedCalDataForIrz = null;
        if (calData.subCals) {
          if (!this.selectedCalDataForIrz) {
            this.selectedCalDataForIrz = calData.subCals[0];
            this.selectedCalDataForIrz.isGrouped = true;

            this.selectedCalDataForIrz.lessonGroup =
              calData.subCals[0].lessonGroup;
            this.selectedCalDataForIrz.classGroup =
              calData.subCals[0].classGroups[0];
          }
          for (const ccc of calData.subCals) {
            var lessonGroup = ccc.lessonGroup;
            var classGroup = ccc.classGroups[0];
            var found = lll.find(
              (pair) =>
                pair.lessonGroup.id == lessonGroup.id &&
                pair.classGroup.classGroupRef.id == classGroup.classGroupRef.id
            );
            if (!found)
              lll.push({
                lessonGroup: lessonGroup,
                classGroup: classGroup,
                fromCal: true,
              });
          }
          // console.log(lll);
        }
        console.log("subLessons", calData.subLessons);
        this.selectedCalDataForIrz.lessonGroups = [];
        if (calData.subLessons) {
          this.selectedCalDataForIrz.isWithSublessons = true;
          this.selectedCalDataForIrz.subLessons = calData.subLessons;
          for (const sl of calData.subLessons) {
            var classGroup2 = sl.classGroups[0];
            console.log("classGroup2", classGroup2.classGroupFullName);
            var Lgroup = sl.lessonGroups.find(
              (gg) =>
                gg.groupIndex == calData.groupIndex &&
                gg.teacher.teacherId == this.userData.id
            );
            var found2 = lll.find(
              (pair) =>
                pair.lessonGroup.id == Lgroup.id &&
                pair.classGroup.classGroupRef.id == classGroup2.classGroupRef.id
            );
            if (!found2) {
              lll.push({
                lessonGroup: Lgroup,
                classGroup: classGroup2,
                fromCal: false,
              });
            }
            // console.log("xxsl",sl, sl.classGroups[0])
          }
        }
        this.students = [];
        this.studentsStatuses = [];
        for (const ll of lll) {
          if (ll.classGroup && ll.lessonGroup) {
            console.log(
            "final222",
            ll.classGroup.classGroupFullName,
            ll.lessonGroup.name,
            ll.fromCal,
            ll.lessonGroup
          );
          this.selectedCalDataForIrz.lessonGroups.push(ll.lessonGroup);
          this._findGroupStudentX(ll.classGroup, ll.lessonGroup);
          this._getDaiylAttendances(ll.classGroup, false);
          }
        }
        this.studentLoading = false;
        this.loadingStatuses = false;
        this.showLessonIrzBurtgelDialog = !this.showLessonIrzBurtgelDialog;
        // if (calData.subCals) {
        //   if (calData.subCals.length == 1 && calData.subCals[0] && calData.subCals[0].lessonGroup) {
        //     this.selectedCalDataForIrz = calData.subCals[0];
        //     var lessonGroup = calData.subCals[0].lessonGroup
        //     var classGroup = calData.subCals[0].classGroups[0]

        //     this.selectedCalDataForIrz.isGrouped = true
        //     this.selectedCalDataForIrz.lessonGroup = lessonGroup
        //     this.selectedCalDataForIrz.classGroup = classGroup
        //     this.studentLoading = true

        //     this.students = []
        //     if (classGroup && lessonGroup) {
        //       console.log("cccc",classGroup, lessonGroup)
        //       this._findGroupStudentX(classGroup, lessonGroup)
        //       this._getDaiylAttendances(classGroup, true)
        //     }
        //   }
        //   else if (calData.subCals.length > 1) {
        //     this.students = []
        //     for (const subCalData of calData.subCals) {
        //       var lessonGroup2 = subCalData.lessonGroup
        //       var classGroup2 = subCalData.classGroups[0]
        //       if (classGroup2 && lessonGroup2) {
        //         this._getDaiylAttendances(classGroup2, false)
        //         this._findGroupStudentX(classGroup2, lessonGroup2)
        //       }
        //     }
        //   }
        // }
      }
      // this.showLessonIrzBurtgelDialog = !this.showLessonIrzBurtgelDialog
    },
    _findGroupStudentX(classGroup, lessonGroup) {
      if (lessonGroup.allStudentsSelected) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .orderBy("firstName", "asc")
          .where(
            "STUDENT_GROUP_ID",
            "==",
            classGroup.programData.STUDENT_GROUP_ID
          )
          .get()
          .then((docs) => {
            docs.forEach(async (doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              var found = this.students.find(
                (st) => st.PERSON_ID == student.PERSON_ID
              );
              if (!student.moved && !found) {
                student.classGroupRef = classGroup.classGroupRef;
                this.students.push(student);
              }
            });
            this.studentLoading = false;
          });
      } else {
        lessonGroup.ref
          .collection("students")
          .orderBy("FIRST_NAME", "asc")
          .get()
          .then((docs) => {
            docs.forEach(async (doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              // console.log(student.FIRST_NAME, student.ref.path);
              var found = this.students.find(
                (st) => st.PERSON_ID == student.PERSON_ID
              );
              if (!student.moved && !found) {
                student.classGroupRef = classGroup.classGroupRef;
                this.students.push(student);
              }
            });
            this.studentLoading = false;
          });
      }
    },
    _doAllOnline() {
      var status = {
        bgcolor: "#FFCDD2",
        color: "black",
        text: "Зайнаас",
        value: -5,
      };
      this.students.forEach((student) => {
        var newStatus = {
          status: status.value,
          text: status.text,
          month: this.selectedMonth,
          year: this.userData.school.currentYear,
          studentId: student.id,
          daily_attendance: true,
          createdByTeacherRef: this.userData.ref,
          createdByName: this.userData["DISPLAY_NAME"]
            ? this.userData["DISPLAY_NAME"]
            : this.userData.firstName
            ? this.userData.firstName
            : this.userData.email,
        };
        var found = this.studentsStatuses.find(
          (stat) => stat.studentId == student.id
        );
        if (found) {
          found.status = status.value;
          found.text = status.text;
        } else {
          this.studentsStatuses.push(newStatus);
        }
      });
    },
    _getWeekData() {
      if (this.$attrs.allData) {
        if (this.$attrs.allData[this.$attrs.currentSelectedWeek]) {
          return this.$attrs.allData[this.$attrs.currentSelectedWeek].length;
        } else return -2;
      } else return -1;
    },
    _getWeekDataDayAndXeeljForGroups(dayIndex, xeelj, lessonId, classGroupId) {
      var dates = this._getDate2(dayIndex);
      if (this.$attrs.allData) {
        if (this.$attrs.allData[this.$attrs.currentSelectedWeek]) {
          var found = this.$attrs.allData[this.$attrs.currentSelectedWeek].find(
            (ex) =>
              ex.selectedLesson.id == lessonId &&
              ex.xeelj == xeelj &&
              ex.day == dates[2] &&
              ex.month == dates[1] &&
              ex.selectedClassGroup &&
              ex.selectedClassGroup.classGroupRef &&
              ex.selectedClassGroup.classGroupRef.id == classGroupId
          );
          return found;
        } else return undefined;
      } else return undefined;
    },
    _getWeekDataDayAndXeelj(dayIndex, xeelj, lessonId, classGroupId) {
      var dates = this._getDate2(dayIndex);
      if (this.$attrs.allData) {
        if (this.$attrs.allData[this.$attrs.currentSelectedWeek]) {
          // console.log("dates", dates, dayIndex, xeelj)
          var found = this.$attrs.allData[this.$attrs.currentSelectedWeek].find(
            (ex) =>
              ex.selectedLesson.id == lessonId &&
              ex.xeelj == xeelj &&
              ex.day == dates[2] &&
              ex.month == dates[1] &&
              ex.selectedClassGroup.classGroupRef.id == classGroupId
          );
          return found;
        } else return undefined;
      } else return undefined;
    },
    _getDate(day) {
      var cc = this.$attrs.currentWeekDays
        ? this.$attrs.currentWeekDays.find((cwd) => cwd.garag == day.dayIndex)
        : null;
      if (cc) return cc.month + "/" + cc.day;
      else return null;
    },
    _getDate2(dayIndex) {
      var cc = this.$attrs.currentWeekDays
        ? this.$attrs.currentWeekDays.find((cwd) => cwd.garag == dayIndex)
        : null;
      if (cc) {
        return [cc.year, cc.month, cc.day];
      } else return null;
    },
    _findCalsByDayIndex(dayIndex) {
      var list = [];
      for (const xeelj of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]) {
        for (const cal of this._getCellLesson2ByDay(
          { dayIndex: dayIndex },
          xeelj
        )) {
          list.push(cal);
        }
      }
      console.log("calls", list, list.length);
      return list;
    },
    async _generateDayIrz(day) {
      this.selectedDayIndex = day.dayIndex;
      this.selectedCalendarData = [];
      this.loadingPreparation = true;
      this.showDayIrzDialog = !this.showDayIrzDialog;

      this.selectedDateArray = this._getDate2(day.dayIndex);
      this.selectedDate =
        this.selectedDateArray[0] +
        "-" +
        this.selectedDateArray[1] +
        "-" +
        this.selectedDateArray[2];
      this.selectedClassGroupForDay = [];
      var filteredCals = [];
      for (const cal of this._findCalsByDayIndex(day.dayIndex)) {
        if (cal.dayIndex == day.dayIndex && !cal.irzDone) {
          filteredCals.push(cal);
          var found2 = this.selectedClassGroupForDay.find(
            (c) => c.classGroupRef.id == cal.classGroups[0].classGroupRef.id
          );
          if (!found2) {
            this.selectedClassGroupForDay.push(cal.classGroups[0]);
          } //for
          continue;
        } //if
      }
      this.selectedCalendarData = filteredCals.sort(
        (a, b) => a.xeelj - b.xeelj
      );
      for (const cal of this.selectedCalendarData) {
        var xxx = await cal.classGroups[0].classGroupRef
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(this.selectedDate)
          .get();
        if (xxx.exists) {
          cal.dailyIrz = xxx.data();
          cal.dailyIrz.numberOfStudents = -2;
          cal.dailyIrz.exists = true;
        } else {
          cal.dailyIrz = { exists: false };
          var students = await this.userData.school.ref
            .collection("students-" + this.userData.school.currentYear)
            .where(
              "STUDENT_GROUP_ID",
              "==",
              cal.classGroups[0].programData.STUDENT_GROUP_ID
            )
            .get();
          var studs = students.docs.filter((ss) => !ss.moved);
          cal.dailyIrz.numberOfStudents = studs ? studs.length : 0;
          console.log("cg", cal.dailyIrz.numberOfStudents);
        }
      }
      this.loadingPreparation = false;
    },
    async _generateDayIrzConfirmed() {
      var batch = fb.db.batch();
      var currentWeek = this.$attrs.currentSelectedWeek;
      var currentSemester = this.$attrs.currentSelectedSemester;
      for (const dd of this.selectedCalendarData) {
        var irzData = {};
        irzData["closed"] = true;
        irzData["closedBy"] = this.userData.ref;
        irzData["createdAt"] = new Date();
        irzData["day"] = this.selectedDateArray[2];
        irzData["deleted"] = false;
        irzData["month"] = this.selectedDateArray[1];

        irzData["semester"] = currentSemester;
        irzData["week"] = currentWeek;
        if (dd.dailyIrz.exists) {
          irzData["numberOfStudents"] = dd.dailyIrz.totalStudents;
          irzData["studentsPresent"] = dd.dailyIrz.studentsPresent;
          irzData["studentsAbsent"] = dd.dailyIrz.studentsAbsent;
          irzData["studentsSick"] = dd.dailyIrz.studentsSick;
          irzData["studentsExcused"] = dd.dailyIrz.studentsExcused;
          irzData["studentsOnline"] = dd.dailyIrz.studentsOnline;
        } else {
          irzData["numberOfStudents"] = dd.dailyIrz.numberOfStudents;
          irzData["studentsPresent"] = dd.dailyIrz.numberOfStudents;
          irzData["studentsAbsent"] = 0;
          irzData["studentsSick"] = 0;
          irzData["studentsExcused"] = 0;
          irzData["studentsOnline"] = 0;
        }
        (irzData["teachedAt"] = new Date(
          this.selectedDateArray[0],
          this.selectedDateArray[1] - 1,
          this.selectedDateArray[2]
        )),
          (irzData["teacherRef"] = this.userData.ref);
        irzData["xeelj"] = dd.xeelj;
        irzData["year"] = this.selectedDateArray[0];

        if (dd.lessonRef) {
          var found = this.$attrs.filteredLessons.find(
            (el) => el.ref.path == dd.lessonRef.path
          );
          if (found && found.lessonType == 1) {
            (irzData["classGroupRef"] = dd.classGroups[0].classGroupRef
              ? dd.classGroups[0].classGroupRef
              : null),
              (irzData["departmentRef"] = dd.classGroups[0].departmentRef
                ? dd.classGroups[0].departmentRef
                : null),
              (irzData["selectedClassGroup"] = dd.classGroups[0]);
            irzData["selectedLesson"] = Object.assign({}, found);
            irzData["selectedLesson"]["calendarData"] = null;
            irzData["selectedLessonGroup"] = null;
            batch.set(dd.lessonRef.collection("executions").doc(), irzData, {
              merge: true,
            });
            // console.log(dd.lessonRef.path, batch, irzData);
          } else {
            irzData["classGroupRef"] = null;
            irzData["departmentRef"] = null;
            irzData["selectedClassGroup"] = null;
            irzData["selectedLesson"] = null;
            irzData["selectedLessonGroup"] = null;
          }
        }
      }
      batch.commit().then(() => {
        console.log("done");
        this.selectedCalendarData = null;
        this.selectedClassGroupForDay = null;
        this.showDayIrzDialog = !this.showDayIrzDialog;
      });
    },
    _generateAutoWeekly(day, theDateStr) {
      this._generateDayIrz(day.dayIndex, theDateStr);
    },

    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },

    _addIrzTimetable(lesson, classGroups) {
      console.log(lesson, classGroups, this.$attrs.filteredLessons);
      var selectedLesson = this.$attrs.filteredLessons.find(
        (lssn) => lssn.id == lesson.lessonRef.id
      );
      if (!selectedLesson) {
        selectedLesson = this.$attrs.filteredLessons.find(
          (lssn) =>
            lssn.id ==
            lesson.classGroups2[0].classGroupRef.id +
              "-" +
              lesson.lessonRef.id.split("-")[1]
        );
        selectedLesson;
      }
      // this.temporarlySelectedLesson = selectedLesson;
      this.selectedLessonGroup = lesson.lessonGroup;
      console.log(selectedLesson);
      this.selectedEelj = lesson.xeelj;
      if (selectedLesson.classGroups.length == 1) {
        this.currentSelectedClassGroup = selectedLesson.classGroups[0];
        this.currentSelectedClassGroup.editing = true;
      }
      var garagIndex = [7, 1, 2, 3, 4, 5, 6];
      var dayDiff = garagIndex[new Date().getDay()] - lesson.dayIndex;
      var calendarDate = new Date(new Date().getTime() - dayDiff * 86400000);
      this.selectedDay = calendarDate.getDate();
      this.selectedMonth = calendarDate.getMonth() + 1;
      this.selectedYear = calendarDate.getFullYear();
      this.checkExecutionExists(lesson.lessonRef);
      this.newIrzDialog = !this.newIrzDialog;
    },
    _getCellColor(lesson) {
      var x = this.calendarColors.find(
        (item) => item.subjectId == lesson.courseInfo.SUBJECT_AREA_ID
      );
      if (x) return x;
      else {
        return {
          subjectId: -1,
          fgColor: "#bbb",
          bgColor: "black",
        };
      }
    },
    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },
    _getClassGroups(lesson) {
      var str = null;
      if (lesson.isCollected) {
        for (const ll of lesson.subLessons) {
          for (const cg of ll.classGroups) {
            if (str) {
              str = str + ", " + cg.classGroupFullName;
            } else {
              str = cg.classGroupFullName;
            }
          }
        }
      } else {
        for (const cg of lesson.classGroups) {
          if (str) {
            str = str + ", " + cg.classGroupFullName;
          } else {
            str = cg.classGroupFullName;
          }
        }
      }
      return str;
    },

    async _saveNewLesson() {
      console.log(this.selectedNewLesson);
      if (this.selectedNewLesson.isCollected) {
        if (this.selectedNewLesson.selectedGroup) {
          for (const sl of this.selectedNewLesson.subLessons) {
            if (sl.classGroups) {
              for (const classGroup of sl.classGroups) {
                var foundEeljSub = this.$attrs.schoolEeljs.find(
                  (ee) =>
                    parseInt(classGroup.ACADEMIC_LEVEL) > 5 &&
                    ee.classLevelID == 2 &&
                    this.$attrs.calendarXeelj == ee.xeelj
                );

                var newCalSub = {
                  calendarEelj: {
                    eeljId: foundEeljSub.id,
                    eeljRef: foundEeljSub.ref,
                    name2:
                      foundEeljSub.name +
                      ", " +
                      this.$attrs.calendarXeelj +
                      "-р ээлж",
                    calendarXeelj: this.$attrs.calendarXeelj,
                  },
                  xeelj: this.selectedXeelj,
                  dayIndex: this.selectedDay.dayIndex,
                  isForTwoWeek: false,
                  courseInfo: sl.courseInfo,
                  lessonRef: sl.ref,
                  lessonGroup: this.selectedNewLesson.selectedGroup
                    ? this.selectedNewLesson.selectedGroup
                    : null,
                }; //newCalSub
                if (classGroup.classGroupRef.id == "100003483706153") {
                  console.log(
                    classGroup.classGroupRef.path,
                    classGroup.classGroupFullName,
                    newCalSub.lessonGroup,
                    newCalSub.lessonRef.path
                  );
                  classGroup.classGroupRef
                    .collection(
                      "calendar-" +
                        this.userData.school.currentYear +
                        "-" +
                        this.$attrs.currentSelectedSemester
                    )
                    .doc()
                    .set(newCalSub, { merge: true })
                    .then(() => {
                      console.log("xxx", classGroup.classGroupRef);
                      this.$store.state.calendarRefreshCalled = true;
                    });
                }
              }
            }
          }
          this.selectedDay = null;
          this.selectedXeelj = null;
          this.selectedNewLesson = null;
          this.selectNewLessonDialog = !this.selectNewLessonDialog;
        } else {
          this.$swal.fire("Xичээлийн групп сонгоно уу!");
        }
      } // end of collected lesson
      else {
        if (this.selectedNewLesson.classGroups) {
          for (const classGroup of this.selectedNewLesson.classGroups) {
            var foundEelj = this.$attrs.schoolEeljs.find(
              (ee) => this.$attrs.calendarXeelj == ee.xeelj
            );
            var newCal = {
              calendarEelj: {
                eeljId: foundEelj.id,
                eeljRef: foundEelj.ref,
                name2:
                  foundEelj.name + ", " + this.$attrs.calendarXeelj + "-р ээлж",
                calendarXeelj: this.$attrs.calendarXeelj,
              },
              xeelj: this.selectedXeelj,
              dayIndex: this.selectedDay.dayIndex,
              isForTwoWeek: false,
              courseInfo: this.selectedNewLesson.courseInfo,
              lessonRef: this.selectedNewLesson.ref,
            };
            var existCalCheck = await classGroup.classGroupRef
              .collection(
                "calendar-" +
                  this.userData.school.currentYear +
                  "-" +
                  this.$attrs.currentSelectedSemester
              )
              .where("xeelj", "==", this.selectedXeelj)
              .where("dayIndex", "==", this.selectedDay.dayIndex)
              .get();
            if (existCalCheck.empty) {
              classGroup.classGroupRef
                .collection(
                  "calendar-" +
                    this.userData.school.currentYear +
                    "-" +
                    this.$attrs.currentSelectedSemester
                )
                .doc()
                .set(newCal, { merge: true })
                .then(() => {
                  console.log("xxx", classGroup.classGroupRef);
                  this.$store.state.calendarRefreshCalled = true;
                  this.selectedDay = null;
                  this.selectedXeelj = null;
                  this.selectedNewLesson = null;
                  this.selectNewLessonDialog = !this.selectNewLessonDialog;
                });
            } else {
              this.$swal({
                title:
                  "Таны оруулсан цаг дээр өөр xичээл байна! Та давxар оруулаx уу?",
                type: "warning",
                showCancelButton: true,
                customClass: {
                  confirmButton: "btn bg-gradient-success",
                  cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: "Тийм",
                cancelButtonText: "Үгүй",
                reverseButtons: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  classGroup.classGroupRef
                    .collection(
                      "calendar-" +
                        this.userData.school.currentYear +
                        "-" +
                        this.$attrs.currentSelectedSemester
                    )
                    .doc()
                    .set(newCal, { merge: true })
                    .then(() => {
                      console.log("xxx", classGroup.classGroupRef);
                      this.selectedDay = null;
                      this.selectedXeelj = null;
                      this.selectedNewLesson = null;
                      this.selectNewLessonDialog = !this.selectNewLessonDialog;
                      this.$store.state.calendarRefreshCalled = true;
                    });
                }
              });
            }
          } //for
        }
      }
      //this.$attrs.calendarXeelj
    },

    _findCal2(dayIndex, xeelj) {
      var cals = [];
      var found = null;
      for (const ll of this.$attrs.filteredLessons) {
        if (ll.isCollected && ll.subLessons) {
          for (const sl of ll.subLessons) {
            if (sl.calendarData) {
              for (const ca of sl.calendarData) {
                if (
                  ca.calendarEelj &&
                  ca.calendarEelj.eeljId.endsWith(
                    "-" + this.$attrs.calendarXeelj
                  )
                ) {
                  if (ca.xeelj == xeelj && ca.dayIndex == dayIndex) {
                    found = ca;
                    found.lesson = ll;
                    cals.push(found);
                  }
                }
              }
            }
          }
        } else {
          if (ll.calendarData) {
            for (const ca of ll.calendarData) {
              if (
                ca.calendarEelj &&
                ca.calendarEelj.eeljId.endsWith("-" + this.$attrs.calendarXeelj)
              ) {
                if (ca.xeelj == xeelj && ca.dayIndex == dayIndex) {
                  found = ca;
                  found.lesson = ll;
                  cals.push(found);
                }
              }
            }
          }
        }
      }
      return cals;
    },

    _getCellLesson2(day, xeelj) {
      var cals = this._findCal2(day.dayIndex, xeelj);
      var jointCals = [];
      var jointGroups = [];
      for (const cc of cals) {
        if (cc.lessonGroup) {
          // var found = jointCals.find(el => el.groupIndex == cc.lessonGroup.groupIndex
          //   && cc.courseInfo.COURSE_ID == el.courseInfo.COURSE_ID)
          var found = jointGroups.find(
            (el) =>
              el.groupIndex == cc.lessonGroup.groupIndex &&
              cc.courseInfo.COURSE_ID == el.courseInfo.COURSE_ID
          );
          if (!found) {
            var tmp = {
              xeelj: cc.xeelj,
              dayIndex: cc.dayIndex,
              calendarEelj: Object.assign({}, cc.calendarEelj),
              courseInfo: Object.assign({}, cc.courseInfo),
            };
            tmp.subCals = [];
            tmp.groupIndex = cc.lessonGroup.groupIndex;
            jointGroups.push(tmp);
          }
        } // end of lessonGroup
        else {
          cc.subCals = null;
          cc.classBased = true;
          var irz = this._getWeekDataDayAndXeelj(
            day.dayIndex,
            xeelj,
            cc.lessonRef.id,
            cc.classGroups[0].classGroupRef.id
          );
          cc.irzDone = irz;
          jointCals.push(cc);
        }
      } //end of cals
      // console.log("jointGroups", jointGroups);
      for (const jg of jointGroups) {
        for (const cc of cals) {
          if (
            cc.lessonGroup &&
            jg.groupIndex == cc.lessonGroup.groupIndex &&
            jg.courseInfo.COURSE_ID == cc.courseInfo.COURSE_ID
          ) {
            jg.subCals.push(cc);
          }
        }

        if (jg.subCals.length == 1) {
          var irz2 = this._getWeekDataDayAndXeeljForGroups(
            day.dayIndex,
            xeelj,
            jg.subCals[0].lesson.id,
            jg.subCals[0].classGroups[0].classGroupRef.id,
            jg.subCals[0].lessonGroup.id
          );
          jg.irzDone = irz2;
        }
        jointCals.push(jg);
      }

      for (const kk of jointCals) {
        kk.name = kk.courseInfo.SUBJECT_AREA_NAME;
        if (kk.groupIndex) {
          kk.name = kk.name + " [" + kk.groupIndex + "]";
        } else if (kk.lessonGroup) {
          kk.name = kk.name + " [" + kk.lessonGroup.groupIndex + "]";
        } else {
          kk.name = kk.courseInfo.SUBJECT_AREA_NAME;
        }
        if (kk.courseInfo && kk.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE") {
          if (jointCals.length > 1)
            kk.name = kk.courseInfo.SUBJECT_AREA_NAME.substring(0, 2);
          else kk.name = kk.courseInfo.SUBJECT_AREA_NAME;
          kk.name2 = "Сонгон";
          kk.style =
            "background-color:" +
            this.kBgColorElective +
            "; color:" +
            this.kFgColor;
          kk.elective = true;
        } else {
          if (kk.lessonGroup) {
            kk.style =
              "background-color:" +
              this.kBgColorGroup +
              "; color:" +
              this.kFgColor;
          } else {
            kk.style =
              "background-color:" + this.kBgColor + "; color:" + this.kFgColor;
          }
        }
        this._analyseXolimorCal(kk);
      }
      return jointCals;
    },
    _analyseXolimorCal(cal) {
      if (cal.elective && this.$attrs.filteredLessons) {
        var found = this.$attrs.filteredLessons.find(
          (ll) => ll.courseInfo.COURSE_ID == cal.courseInfo.COURSE_ID
        );
        if (found && found.subLessons) {
          cal.mixed = true;
          cal.subLessons = [];
          for (const sl of found.subLessons) {
            cal.subLessons.push(Object.assign({}, sl));
          }
        }
      }
    },
    _getCellLesson2ByDay(day, xeelj) {
      var cals = this._findCal2(day.dayIndex, xeelj);
      var jointCals = [];
      var jointGroups = [];
      for (const cc of cals) {
        if (cc.lessonGroup) {
          // var found = jointCals.find(el => el.groupIndex == cc.lessonGroup.groupIndex
          //   && cc.courseInfo.COURSE_ID == el.courseInfo.COURSE_ID)
          var found = jointGroups.find(
            (el) =>
              el.groupIndex == cc.lessonGroup.groupIndex &&
              cc.courseInfo.COURSE_ID == el.courseInfo.COURSE_ID
          );
          if (!found) {
            var tmp = {
              xeelj: cc.xeelj,
              dayIndex: cc.dayIndex,
              calendarEelj: Object.assign({}, cc.calendarEelj),
              courseInfo: Object.assign({}, cc.courseInfo),
            };
            tmp.subCals = [];
            tmp.groupIndex = cc.lessonGroup.groupIndex;
            jointGroups.push(tmp);
          }
        } // end of lessonGroup
        else {
          cc.subCals = null;
          cc.classBased = true;
          var irz = this._getWeekDataDayAndXeelj(
            day.dayIndex,
            xeelj,
            cc.lessonRef.id,
            cc.classGroups[0].classGroupRef.id
          );
          cc.irzDone = irz;
          jointCals.push(cc);
        }
      } //end of cals
      // console.log("jointGroups", jointGroups);
      for (const jg of jointGroups) {
        for (const cc of cals) {
          if (
            cc.lessonGroup &&
            jg.groupIndex == cc.lessonGroup.groupIndex &&
            jg.courseInfo.COURSE_ID == cc.courseInfo.COURSE_ID
          ) {
            jg.subCals.push(cc);
          }
        }
        jointCals.push(jg);
      }

      return jointCals;
    },
    // _getClassGroupsFromCalData2(day, xeelj, index) {
    //   var cgs = [];
    //   var zz = this._getCellLesson2(day, xeelj)[index];
    //   if (zz) {
    //     if (zz.subCals && zz.subCals.length > 0) {
    //       if (zz.lesson.isCollected && zz.lesson.subLessons) {
    //         for (const sl of zz.lesson.subLessons) {
    //           cgs.push({
    //             classGroup: sl.classGroups[0],
    //             calWith: this._isWithCal(zz.subCals, sl.classGroups[0]),
    //           });
    //           // console.log("sub", sl.classGroups[0].classGroupFullName, sl.classGroups[0].classGroupRef.id, sl.withCal)
    //         }
    //       } else {
    //         cgs.push({
    //           classGroup: zz.lesson.classGroups[0],
    //           calWith: this._isWithCal(zz.subCals, zz.lesson.classGroups[0]),
    //         });
    //       }
    //       // for(const cc of zz.subCals){
    //       //   console.log(cc.classGroups[0].classGroupFullName, cc.classGroups[0].classGroupRef.id)
    //       // }
    //     } else {
    //       cgs.push({
    //         classGroup: zz.lesson.classGroups[0],
    //         calWith: this._isWithCal(zz.subCals, zz.lesson.classGroups[0]),
    //       });
    //     }
    //   }
    //   return cgs;
    // },
    _isWithCal(subCals, classGroup) {
      for (const cc of subCals) {
        if (cc.classGroups[0].classGroupRef.id == classGroup.classGroupRef.id) {
          return true;
        }
      }
      return false;
    },
    _showCalDialog2(calData, day, xeelj) {
      console.log("calData", calData, day, xeelj);
      this.selectedCalData = calData;
      this.selectedXeelj = xeelj;
      this.selectedDay = day;
      this.selectLessonDialog = !this.selectLessonDialog;
      if (calData.subLessons) {
        for (const sl of calData.subLessons) {
          console.log(sl.ref.path);
        }
      } else {
        console.log(
          calData.ref.path,
          calData.classGroups[0].classGroupFullName
        );
      }
      calData.lesson.ref.collection("executions").where("day","==", 14)
      .where("month","==",11)
      .where("xeelj","==",xeelj)
      .get().then(docs=>{
        var counter = 0
        for(const doc of docs.docs){
          counter++
          let x = doc.data()
          x.id = doc.id
          x.ref = doc.ref
          console.log(counter,x.selectedLesson.courseInfo.COURSE_NAME, x.selectedClassGroup.classGroupFullName, x.deleted, x.ref.path)
        }
      })
    },
    _showCalDialog(day, xeelj, index) {
      this.selectedLesson = null;
      console.log(day.dayIndex, xeelj, index);
      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      this.selectedLessonIndex = index;
      var zz = this._getCellLesson2(day, xeelj)[index];
      if (zz) {
        this.selectedLesson = zz.lesson;
        this.selectedCalData = zz;
        this.selectedLesson2 = zz.lesson;
        this.selectLessonDialog = !this.selectLessonDialog;
        if (zz.subCals && zz.subCals.length > 0) {
          console.log(
            "selected",
            zz.ref.path,
            zz.lesson.ref.path,
            zz.subCals.length
          );
          if (zz.lesson.isCollected && zz.lesson.subLessons) {
            for (const sl of zz.lesson.subLessons) {
              sl.withCal = this._isWithCal(zz.subCals, sl.classGroups[0]);
              console.log(
                "sub",
                sl.classGroups[0].classGroupFullName,
                sl.classGroups[0].classGroupRef.id,
                sl.withCal
              );
            }
          }
          for (const cc of zz.subCals) {
            console.log(
              "cal",
              cc.classGroups[0].classGroupFullName,
              cc.classGroups[0].classGroupRef.id
            );
          }
        }
      }
      // this._print2(day, xeelj)
    },
    _deleteCalData2(day, xeelj) {
      var str = null;
      if (this.selectedCalData.subCals) {
        for (const cg of this.selectedCalData.subCals) {
          if (str == null) str = cg.classGroups[0].classGroupFullName;
          else str = str + ", " + cg.classGroups[0].classGroupFullName;
        }
      } else {
        str = this.selectedCalData.classGroups[0].classGroupFullName;
      }
      this.$swal({
        title:
          this.selectedCalData.name +
          " " +
          str +
          ", xичээлийн xуваарийг устгаx уу?",
        text: "Гараг: " + day.name + ", " + xeelj + "-р цаг",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log("xxx", this.selectedCalData.ref.path, day.dayIndex, xeelj, this.selectedCalData)
          if (this.selectedCalData.subCals) {
            var batch = fb.db.batch();
            for (const ca of this.selectedCalData.subCals) {
              batch.delete(ca.ref);
            }
            batch.commit().then(() => {
              this.selectedDay = null;
              this.selectedXeelj = null;
              this.selectedLessonIndex = null;
              this.selectedCalData = null;
              this.selectedLesson = null;
              this.selectedLesson2 = null;
              this.selectLessonDialog = !this.selectLessonDialog;
              this.$store.state.calendarRefreshCalled = true;
            });
          } //isCollected lesson
          else {
            this.selectedCalData.ref.delete().then(() => {
              this.selectedDay = null;
              this.selectedXeelj = null;
              this.selectedLessonIndex = null;
              this.selectedCalData = null;
              this.selectedLesson = null;
              this.selectedLesson2 = null;
              this.selectLessonDialog = !this.selectLessonDialog;
              this.$store.state.calendarRefreshCalled = true;
            });
          }
        }
      });
    },

    async _clickedNewCell(day, xeelj) {
      this.selectedDay = day;
      this.selectedXeelj = xeelj;
      this.selectedNewLesson = null;

      this.selectNewLessonDialog = true;
      console.log(this.$attrs.filteredLessons);
    },
    _deleteLesson() {
      this.deleteConfirmDialog = true;
    },
    _deleteConfirm() {
      if (this.selectedLesson && this.selectedLesson.already) {
        this.selectedLesson.ref.delete().then(() => {
          this.deleteConfirmDialog = false;
          this.selectLessonDialog = false;
        });
      }
    },
    _print2(day, xeelj) {
      var zz = this._findCal(day.dayIndex, xeelj);
      console.log("xxx", zz.ref.path, day.dayIndex, xeelj, zz);
      if (zz.lesson.isCollected) {
        var found = this.$attrs.filteredLessons.find(
          (ff) => ff.courseInfo.COURSE_ID == zz.lesson.courseInfo.COURSE_ID
        );
        if (found) {
          console.log(found.ref.path, zz.lesson.ref.path);
          for (const ll of zz.lesson.subLessons) {
            // console.log(ll.classGroups[0].classGroupFullName, ll.calendarData.length)
            for (const ca of ll.calendarData) {
              if (ca.dayIndex == day.dayIndex && ca.xeelj == xeelj) {
                console.log(
                  ca.xeelj,
                  ca.dayIndex,
                  ll.classGroups[0].classGroupFullName,
                  ca.ref.path
                );
              }
            }
          }
          console.log("---");
        }
      }
    },
  },
  created() {
    //this.getCalendarData();
  },
};
</script>
<style>
.drag-container {
  display: flex;
  flex-direction: column;
}

.drag-item {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.bborder td {
  border-right: 1px solid #bbb !important;
  border-bottom: 1px solid #bbb;
  padding: 4px 0px 4px 0px;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}
</style>
