<template>
  <v-card class="px-4 mt-0">
    <p v-if="users2" class="mb-0 mx-2">
      Нийт: <span>{{ users2.length }}</span
      >, Ирсэн: <span class="green--text">{{ numberOfPresent }}</span
      >, Ирээгүй:
      <span class="red--text">{{ users2.length - numberOfPresent }}</span>
    </p>
    <p class="py-0 mx-2">
      <span class="blue--text" @click="_printStatuses()">Чөлөөтэй</span>:
      {{ _getStatuses(-2) }}, <span class="amber--text">Өвчтэй</span>:
      {{ _getStatuses(-3) }}, <span class="red--text">Тасалсан</span>:
      {{ _getStatuses(-4) }}, <span>ONLINE</span>: {{ this._getStatuses(-5) }}
    </p>
    <v-data-table
      style="height: 400px; overflow-y: auto"
      class="px-2 pt-2 pb-2"
      :headers="headerNames"
      :items="users2"
      hide-default-header
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:header="{}">
        <thead>
          <tr style="background-color: #ececec">
            <th class="font-weight-bold black--text text-center">No.</th>
            <th class="font-weight-bold black--text pl-2">Нэр</th>
            <th
              class="font-weight-bold black--text pl-2"
              v-if="$attrs.showClassGroupName"
            >
              Бүлэг
            </th>
            <th class="font-weight-bold black--text pl-2">Төлөв</th>
            <!-- <th class="font-weight-bold black--text pl-2">ТЭМДЭГЛЭГЭЭ</th> -->
          </tr>
        </thead>
      </template>
      <template slot="item" slot-scope="props">
        <tr :id="props.item.id">
          <td class="text-center" @click="_print(props.item)" style="width: 4%">
            {{ props.item.index }}
          </td>
          <td class="px-2">
            {{ props.item["FIRST_NAME"]
            }}<span class="grey--text"
              >, {{ props.item["LAST_NAME"] }} - {{ props.item.PERSON_ID }}
            </span>
            <span v-if="_isExcusedByWhom(props.item)"
              >-
              <span class="red--text">{{ props.item.infoText }}</span>
            </span>
            <span v-else-if="props.item.lessonIrz"
              >-
              <span class="red--text">!!</span>
            </span>
          </td>
          <td v-if="$attrs.showClassGroupName" class="blue--text text-center">
            {{ props.item["STUDENT_GROUP_NAME"] }}
          </td>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <td
                style="width: 15%"
                v-if="_getStatus(props.item)"
                v-bind="attrs"
                v-on="on"
                :style="
                  _getStatus(props.item).text == 'Ирсэн'
                    ? 'background:#B0ED63; color: black !important'
                    : _getStatus(props.item).text == 'Чөлөөтэй' //Чөлөөтэй
                    ? 'background:yellow; color:black!important'
                    : _getStatus(props.item).text == 'Өвчтэй' //Өвчтэй
                    ? 'background:#FFA500; color:white'
                    : _getStatus(props.item).text == 'Тасалсан' //Тасалсан
                    ? 'background:red; color:white'
                    : _getStatus(props.item).text == 'Зайнаас' //Тасалсан
                    ? 'background:grey; color:black'
                    : ''
                "
                class="text-center white--text"
              >
                {{ _getStatus(props.item).text }}
              </td>

              <td
                v-else
                v-bind="attrs"
                style="background-color: #b0ed63"
                class="text-center"
                v-on="on"
              >
                Ирсэн
              </td>
            </template>

            <v-list>
              <v-list-item
                @click="_setStatus(props.item, status)"
                v-for="(status, index) in statuses"
                :key="'attendance' + index"
                style="cursor: pointer"
              >
                <v-list-item-title>{{ status.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- 
          <td @click="selectedStudent=props.item;dialogAddNote=!dialogAddNote"></td> -->
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogAddNote" max-width="800">
      <v-card class="py-4 px-4">
        <v-row>
          <v-col cols="6">
            <v-card-title class="text-h6 px-4 font-weight-bold"
              >Сурагчийн талаар мэдэгдэл xийx</v-card-title
            >
            <v-card-title>
              <v-textarea
                autofocus
                v-model="noteAdded"
                hide-details
                outlined
                label="Тэмдэглэл"
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="forClassTeacher"
                    label="Ангийн багшид"
                  ></v-checkbox
                ></v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="forStudent"
                    label="Сурагчид өөрт"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="forManager"
                    label="Сургалтын менежер"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    v-model="forParents"
                    label="Эцэг эx"
                    class="ma-0 pa-0"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-list
              shaped
              class="mt-10"
              style="height: 300px; overflow-y: auto"
            >
              <v-list-item-group v-model="selectedReasons" multiple>
                <template v-for="(item, i) in $attrs.xlessonStudentNotes">
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-purple--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="deep-purple accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
        <v-card-actions class="py-2">
          <v-spacer></v-spacer>
          <v-btn
            @click="_cancelAddNote"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >

          <v-btn
            @click="_saveAddNote"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
export default {
  components: {},
  data: () => ({
    numberOfExcused: 0,
    numberOfSick: 0,
    numberOfUnExcused: 0,
    numberOfOnline: 0,

    studentsStatuses: null,
    alertText: null,
    showAlert: false,
    selectedEeljTsag: null,
    selectedLessonDayReport: null,
    attends: null,
    selectedStudent: null,
    selectedXXDay: null,
    selectedReasons: null,
    forParents: false,
    forStudent: false,
    forClassTeacher: false,
    forManager: false,
    xlessonStudentNotes: null,
    noteAdded: null,
    dialogAddNote: false,
    statuses: [
      { text: "Тасалсан", value: -4 },
      { text: "Өвчтэй", value: -3 },
      { text: "Чөлөөтэй", value: -2 },
      // { text: "Xоцорсон", value: -1 },
      // { text: "Тодорxойгүй", value: 0 },
      { text: "Ирсэн", value: 1 },
      // { text: "Тэмдэглэгээ xийx", value: 100 },
      { text: "Зайнаас", value: -5, bgcolor: "#FFCDD2", color: "black" },
    ],
    query: null,
    bodyNames: null,
    endOfDay: 30,
    collectionName: "attendances",
    docName: "attendance",

    currentDays: null,
    teachedTopics: null,
    curriculumDialog: null,
    selectedSubtopic: null,
    // selectedHomeworks: null,
    newDaalgavarName: null,
    previousSelectedDaalgavar: null,
    headerNames: [
      {
        text: "No.",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },

      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "firstName",
      },
    ],
  }),
  props: {
    students: {
      type: Array,
      required: true,
    },
    selectedMonth: {
      type: Number,
      required: true,
    },
    selectedXDay: {
      type: Number,
      required: true,
    },

    forClass: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    users2() {
      var list = [];
      if (this.students != null) {
        var counter = 0;
        for (var item of this.students) {
          counter++;
          item.index = counter;
          list.push(item);
        }
      }
      return list;
    },
    numberOfPresent() {
      return (
        this.users2.length -
        (this._getStatuses(-2) +
          this._getStatuses(-3) +
          this._getStatuses(-4) +
          this._getStatuses(-5))
      );
    },
  },
  created() {
    if (this.$attrs.selectedProgram) {
      this.$attrs.selectedProgram.ref
        .collection("attendances-" + this.userData.school.currentYear)
        .where("month", "==", this.selectedMonth)
        .where("day", "==", this.selectedXDay)
        .where("studentNotes", "==", true)
        .onSnapshot((docs) => {
          docs.forEach((doc) => {
            let note = doc.data();
            note.id = doc.id;
            note.ref = doc.ref;
            console.log(note);
            var foundStudent = this.students.find(
              (stud) => stud.id == note.studentId
            );
            if (foundStudent) {
              foundStudent.note = note;
            }
          });
        });
    }
  },
  mounted() {
    console.log("ddd");
  },
  watch: {},

  methods: {
    _printStatuses() {
      console.log(this.$attrs.studentsStatuses);
      for (const stud of this.$attrs.studentsStatuses) {
        console.log(
          stud.studentId,
          stud.text,
          stud.STUDENT_GROUP_NAME,
          stud.daily_attendance,
          stud
        );
      }
    },
    _getStatuses(val) {
      var tmpStatuses = [];
      if (this.$attrs.studentsStatuses) {
        var xx = this.$attrs.studentsStatuses.filter(
          (stat) => stat.status == val
        );
        if (xx) {
          for (const stat of xx) {
            var found = this.students.find(
              (stud) => stud.PERSON_ID == stat.studentId
            );
            if (found) tmpStatuses.push(stat);
          }
        }
        return tmpStatuses.length;
      } else return 0;
    },
    _getSS(student) {
      if (this.$attrs.studentsStatuses) {
        var ss = this.$attrs.studentsStatuses.find(
          (ss) => ss.studentId == student.id
        );
        if (ss) return ss.text;
      } else return "-";
    },
    _getNotes(student) {
      if (student.note) {
        if (student.note && student.note.notes) {
          var noteText = null;
          for (const note of student.note.notes) {
            if (noteText == null) noteText = note.name;
            else noteText = noteText + ", " + note.name;
          }
          return noteText;
        }
      } else return null;
    },
    _print(item) {
      var zz = this._getStatus(item);
      console.log("xxx", item.ref.path, item, zz);
    },

    _getCurrentDateAsString(studentId) {
      if (this.forClass) {
        return (
          this.userData.school.currentYear +
          "-" +
          this.selectedMonth +
          "-" +
          this.selectedXXDay +
          "-" +
          studentId
        );
      } else {
        return (
          this.userData.school.currentYear +
          "-" +
          this.selectedMonth +
          "-" +
          this.selectedXXDay +
          "-" +
          this.selectedEeljTsag +
          "-" +
          studentId
        );
      }
    },
    // _getStatus2(student, day) {
    //   if (student.attendances != null && student.attendances != undefined) {
    //     for (const attend of student.attendances) {
    //       if (attend.day == day && attend.month == this.selectedMonth) {
    //         return attend;
    //       }
    //     }
    //   }
    //   return { status: 1, text: "Ирсэн" };
    // },
    _getStatus(student) {
      // if (student.status) return student.status.text;
      // else return "Ирсэн";
      if (this.$attrs.studentsStatuses) {
        var att = this.$attrs.studentsStatuses.find(
          (ss) => ss.studentId == student.id
        );
        if (att) {
          if (att.lessonIrz) student.lessonIrz = true;
          return att;
        }
      } else return null;
    },
    _isExcusedByWhom(student) {
      // if (student.status) return student.status.text;
      // else return "Ирсэн";
      if (this.$attrs.studentsStatuses) {
        var ss = this.$attrs.studentsStatuses.find(
          (ss) => ss.studentId == student.id
        );
        if (ss && ss.excusedByTeacher) {
          student.infoText = "АУБ чөлөөлсөн";
          return true;
        } else if (ss && ss.excusedByManager) {
          student.infoText = "Менежер чөлөөлсөн";
          return true;
        } else if (ss && ss.excusedByDirector) {
          student.infoText = "Захирал чөлөөлсөн";
          return true;
        }
      } else return false;
    },
    _setStatus(student, status) {
      console.log(student, status);
      var newStatus = {
        PROGRAM_STAGE_ID: student.PROGRAM_STAGE_ID,
        STUDENT_GROUP_ID: student.STUDENT_GROUP_ID,
        STUDENT_GROUP_NAME: student.STUDENT_GROUP_NAME,
        FIRST_NAME: student.FIRST_NAME,
        LAST_NAME: student.LAST_NAME,
        schoolId: this.userData.school.id,
        schoolName: this.userData.school.name,

        status: status.value,
        text: status.text,
        month: this.selectedMonth,
        year: this.$attrs.selectedYear,
        studentId: student.id,

        daily_attendance: true,
        madeByDailyIrz: true,
        dailyIrz: true,

        createdByTeacherRef: this.userData.ref,
        createdByName: this.userData["DISPLAY_NAME"]
          ? this.userData["DISPLAY_NAME"]
          : this.userData.firstName
          ? this.userData.firstName
          : this.userData.email,
      };
      if (this.$attrs.notDaily) {
        newStatus["daily_attendance"] = false;
        newStatus["madeByDailyIrz"] = false;
        newStatus["dailyIrz"] = false;
      }
      console.log(student, newStatus.text, newStatus);
      var found = this.$attrs.studentsStatuses.find(
        (stat) => stat.studentId == student.id
      );
      if (found) {
        found.status = status.value;
        found.text = status.text;
        if (this.$attrs.notDaily) {
          found["daily_attendance"] = false;
          found["madeByDailyIrz"] = false;
          found["dailyIrz"] = false;
        }
      } else {
        this.$attrs.studentsStatuses.push(newStatus);
      }
    },
    _saveAddNote() {
      var note = {
        forClassTeacher: this.forClassTeacher,
        forStudent: this.forStudent,
        forManager: this.forManager,
        forParents: this.forParents,
        notes: this.selectedReasons,
        day: this.selectedXXDay,
        month: this.selectedMonth,
        year: this.userData.school.currentYear,
        descriptionAddedByTeacher: this.noteAdded ? this.noteAdded : null,
        studentId: this.selectedStudent.id,
        studentNotes: true,
        notedBy: this.userData.ref,
        notedByName: this.userData["DISPLAY_NAME"]
          ? this.userData["DISPLAY_NAME"]
          : this.userData["DISPLAY_NAME"]
          ? this.userData["firstName"]
          : null,
      };
      console.log(note);
      this.selectedStudent.note = note;
      this.dialogAddNote = !this.dialogAddNote;
      this.noteAdded = null;
      // var xx = this.students.find(ss => ss.id ==this.selectedStudent.id)
      // xx.note = note
      // console.log(xx)
      // this.selectedStudent = null;
      this.forceRerender();
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
      console.log("OK");
    },
    _cancelAddNote() {
      this.dialogAddNote = false;
      this.noteAdded = null;
      this.selectedStudent = null;
    },
    _addNote(student) {
      this.selectedStudent = student;
      this.selectedReasons = [];
      this.dialogAddNote = true;
      if (student.notes) {
        for (const note of student.notes) {
          this.forClassTeacher = note.forClassTeacher;
          this.forStudent = note.forStudent;
          this.forManager = note.forManager;
          this.forParents = note.forParents;
          this.noteAdded = note.descriptionAddedByTeacher;
          this.selectedReasons = note.notes;
        }
      }
    },
    _getDays() {
      if (this.selectedXXDay && this.selectedXXDay > 0)
        return [this.selectedXXDay];
      else return this.currentDays;
    },
    _generateDayArray(range) {
      return Array.from({ length: range }, (v, k) => k + 1);
    },
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 4px 0px 4px 0px !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
