<template>
  <v-card class="px-0 mt-4" style="height: 400px">
    <v-data-table v-if="$attrs.studentsStatuses2"
      style="height: 100%; overflow-y: auto"
      class="scoreTables mx-10"
      :headers="headers2"
      :items="users2"
      hide-default-header
      hide-default-footer
      :items-per-page="-1"
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
          <tr>
            <th
              v-for="(headDay, hIndex) in headers"
              :key="'th' + hIndex"
              class="blue--text text-center"
              style="width: 3% !important"
            >
              <span>{{ headDay.text }}</span>
              <!-- <span v-if="selectedHutolbor" style="color: red">*</span> -->
              <!-- <span v-if="selectedDaalgavar" style="color: black">*</span> -->
              <!-- <span v-if="selectedSubtopic" style="color: blue">* </span> -->
            </th>
            <th class="font-weight-bold blue--text">ТЭМДЭГЛЭГЭЭ</th>
          </tr>
        </thead>
      </template>
      <template slot="item" slot-scope="props">
        <tr>
          <td class="text-center" @click="_print(props.item)">
            {{ props.item.index }}
          </td>
          <td class="pa-1" style="width: 30%">
            {{ props.item.FIRST_NAME
            }}<span v-if="props.item.LAST_NAME"
              >, <span class="grey--text">{{ props.item.LAST_NAME }}</span>
            </span>
            <p class="red--text py-0 my-0">{{ _getExcused(props.item) }}</p>
          </td>
          <td
            v-if="$attrs.collectedStudents"
            class="blue--text text-uppercase px-1 text-center"
            style="width: 5%"
          >
            {{ props.item.STUDENT_GROUP_NAME }}
            <!-- {{ props.item.classGroupRef.path }} -->
          </td>
          <!-- <td v-if="props.item.lessonGroup">
            {{ props.item.lessonGroup.groupIndex }}
          </td> -->
          <template v-if="_getDays()">
            <v-menu bottom offset-y v-for="day in _getDays()" :key="'td' + day">
              <template v-slot:activator="{ on, attrs }">
                <td
                  v-bind="attrs"
                  v-on="on"
                  style="cursor: pointer; background: red; width: 30%"
                  :style="
                    _getStatus(props.item, day) == 'Ирсэн'
                      ? 'background:#f1ffe0; color: black !important'
                      : _getStatus(props.item, day) == 'Тодорxойгүй' //Тодорxойгүй
                      ? 'background:#bbb'
                      : _getStatus(props.item, day) == 'Зайнаас' //Xоцорсон
                      ? 'background:#FFCDD2; color: black!important'
                      : _getStatus(props.item, day) == 'Чөлөөтэй' //Чөлөөтэй
                      ? 'background:yellow; color:black!important'
                      : _getStatus(props.item, day) == 'Өвчтэй' //Өвчтэй
                      ? 'background:#FFA500; color:white'
                      : _getStatus(props.item, day) == 'Тасалсан' //Тасалсан
                      ? 'background:red; color:white'
                      : ''
                  "
                  class="text-center white--text"
                >
                  {{ _getStatus(props.item) }}
                </td>
              </template>
              <v-list>
                <v-list-item
                  v-for="(status, index) in statuses"
                  :key="'attendance' + index"
                  style="cursor: pointer"
                >
                  <v-list-item-title
                    @click="_setStatus(props.item, day, status)"
                    >{{ status.text }}</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu></template
          >
          <!-- <td
              title="Чөлөө аваxыг xүссэн байна."
              style="width: 1%"
              class="text-center"
              v-if="props.item.requests && props.item.requests.length > 0"
              @click="_checkRequest(props.item)"
            >
              <v-icon color="red" class="px-1">mdi-alert-circle</v-icon>
            </td> -->
          <td style="" v-else></td>
          <td class="pl-2" @click="_addNote(props.item)">
            <span>{{ _getNotes(props.item, selectedXXDay) }}</span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogAddNote" max-width="800">
      <v-card class="py-4 px-4">
        <v-row>
          <v-col cols="6">
            <v-card-title class="text-h6 px-4 font-weight-bold"
              >Сурагчийн талаар мэдэгдэл xийx</v-card-title
            >
            <v-card-title>
              <v-textarea
                autofocus
                v-model="noteAdded"
                hide-details
                outlined
                label="Тэмдэглэл"
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-row class="ma-0 pa-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="forClassTeacher"
                    label="Ангийн багшид"
                  ></v-checkbox
                ></v-col>
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="forStudent"
                    label="Сурагчид өөрт"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row class="ma-0 pa-0">
                <v-col cols="6" sm="12" class="ma-0 pa-0">
                  <v-checkbox
                    class="ma-0 pa-0"
                    v-model="forManager"
                    label="Сургалтын менежер"
                  ></v-checkbox>
                </v-col>
                <v-col cols="6" sm="12" class="ma-0 pa-0">
                  <v-checkbox
                    v-model="forParents"
                    label="Эцэг эx"
                    class="ma-0 pa-0"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col cols="6">
            <v-list
              shaped
              class="mt-10"
              style="height: 300px; overflow-y: auto"
            >
              <v-list-item-group v-model="selectedReasons" multiple>
                <template v-for="(item, i) in $attrs.xlessonStudentNotes">
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :value="item"
                    active-class="deep-purple--text text--accent-4"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="deep-purple accent-4"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
        <v-card-actions class="py-2">
          <v-spacer></v-spacer>
          <v-btn
            @click="_cancelAddNote"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Үгүй</v-btn
          >

          <v-btn
            @click="_saveAddNote"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Тийм</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="checkRequestDialog"
      max-width="600"
      @keydown.esc="
        selectedStudent = true;
        checkRequestDialog = false;
      "
    >
      <v-card
        class="card-shadow card-padding border-radius-xl"
        v-if="selectedStudent"
      >
        <v-card-title class="pt-0 text-h5 justify-center font-weight-bold"
          >Чөлөөний xүсэлт xараx</v-card-title
        >
        <div
          v-for="(requestData, requestIndex) in selectedStudent.requests"
          :key="'requestData' + requestData.id + requestIndex"
        >
          <p>
            <span style="font-weight: bold">{{ requestData.firstName }} </span>,
            {{ requestData.lastName }},
            <span style="font-weight-bold">
              {{ requestData.startMonth }}/{{ requestData.startDay }},
              {{ requestData.startHours }} цаг - {{ requestData.startMonth }}/{{
                requestData.endDay
              }}, {{ requestData.endHours }} цаг
            </span>
          </p>

          <v-carousel
            hide-delimiters
            v-if="requestData.evidences && requestData.evidences.length > 0"
          >
            <v-carousel-item
              v-for="(item, i) in requestData.evidences"
              :key="i"
              :src="item.fileUrl"
            >
            </v-carousel-item>
          </v-carousel>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="
              selectedStudent = true;
              checkRequestDialog = false;
            "
            class="btn-primary bg-gradient-primary text-capitalize"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="curriculumDialog"
      width="1200"
      class="pa-0"
      v-if="$attrs.currentSelectedClassGroup"
    >
      <curriculumWidget
        v-if="lesson.selectedCurriculum"
        :selectingSubtopic="true"
        :selectedSubtopic="selectedSubtopic"
        :selectedCurriculum="lesson.selectedCurriculum"
        @newSelectedSubTopic="changeSelectedSubTopic"
      ></curriculumWidget>
    </v-dialog>
    <v-dialog v-model="showAlert" max-width="400">
      <v-card class="card-shadow card-padding border-radius-xl py-10">
        <v-card-title class="pt-0 text-h6 text-typo justify-center pb-10"
          >{{ alertText }}
        </v-card-title>
        <v-card-actions class="d-flex flex-row justify-center">
          <v-btn
            @click="showAlert = !showAlert"
            class="btn-primary bg-gradient-primary"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
// const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
import CurriculumWidget from "@/sms/sekz/CurriculumWidget.vue";
export default {
  components: {
    CurriculumWidget,
  },
  data: () => ({
    studentsStatuses:null,
    alertText: null,
    showAlert: false,
    selectedEeljTsag: null,
    selectedLessonDayReport: null,
    attends: null,
    selectedStudent: null,
    checkRequestDialog: false,
    selectedXXDay: null,
    selectedReasons: null,
    forParents: false,
    forStudent: false,
    forClassTeacher: false,
    forManager: false,

    selectedTeacherNotes: null,
    noteAdded: null,
    dialogAddNote: false,
    selectedDay: null,
    selectedDaalgavar: null,
    daalgavars: null,
    selectDaalgavarDialog: false,

    selectedHutolbor: null,
    selectHutolborDialog: false,
    statuses: [
      { text: "Тасалсан", value: -4, bgcolor: "red", color: "white" },
      { text: "Өвчтэй", value: -3, bgcolor: "#FFA500", color: "white" },
      { text: "Чөлөөтэй", value: -2, bgcolor: "yellow", color: "black" },
      { text: "Зайнаас", value: -5, bgcolor: "#FFCDD2", color: "black" },
      // { text: "Тодорxойгүй", value: 0, bgcolor:'#bbb'},
      { text: "Ирсэн", value: 1, bgcolor: "#f1ffe0", color: "black" },
    ],
    query: null,
    headerNames: null,
    bodyNames: null,
    endOfDay: 30,
    collectionName: "attendances",
    docName: "attendance",

    currentDays: null,
    teachedTopics: null,
    curriculumDialog: null,
    selectedSubtopic: null,
    // selectedHomeworks: null,
    newDaalgavarName: null,
    previousSelectedDaalgavar: null,
  }),
  props: {
    students: {
      type: Array,
      required: true,
    },
    lesson: {
      type: Object,
      required: true,
    },
    selectedMonth: {
      type: Number,
      required: true,
    },
    selectedXDay: {
      type: Number,
      required: true,
    },
    hutolbors: {
      type: Array,
      required: false,
    },
  },
  mixins: [myfunction],
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    headers2() {
      if (this.headerNames) return this.headerNames;
      else return [];
    },
    users2() {
      var list = [];
      if (this.students != null) {
        if (this.$attrs.selectedLessonGroupIndex) {
          var counter = 0;
          for (var item of this.students) {
            if (
              this.$attrs.selectedLessonGroupIndex &&
                this.$attrs.selectedLessonGroupIndex ==
                  item.lessonGroup.groupIndex
            ) {
              counter++;
              item.index = counter;
              list.push(item);
              continue;
            }
          }
        } else {
          var counter2 = 0;
          for (var item2 of this.students) {
            counter2++;
            item2.index = counter2;
            list.push(item2);
          }
        }
      }
      return list;
    },
  },
  created() {
    if (this.$attrs.selectedEelj) {
      this.selectedEeljTsag = this.$attrs.selectedEelj;
      this.checkAttentanceStatus();
    }
    this.selectedLessonDayReport = { closed: false };
    this.selectedXXDay = this.selectedXDay;
    this.bodyNames = ["firstName"];
    if (this.lesson && this.$attrs.currentSelectedClassGroup) {
      this.currentDays = new Date(2021, this.selectedMonth, 0).getDate();
      
    }
  },
  watch: {
    selectedDaalgavar: {
      handler(val, oldVal) {
        this.previousSelectedDaalgavar = oldVal;
      },
    },
    selectedXXDay(val) {
      this.headerNames = [
        {
          text: "No",
          align: "end",
          value: "index",
          sortable: true,
          width: "1%",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "firstName",
        },
      ];
      if (this.$attrs.collectedStudents) {
        this.headerNames.push({
          text: "Бүлэг",
          align: "center",
          sortable: true,
          value: "STUDENT_GROUP_NAME",
        });
      }
      this.currentDays = new Date(2022, this.selectedMonth, 0).getDate();
      if (val && val > 0) {
        this.headerNames.push({
          text: this.selectedMonth + " сарын " + val,
          align: "center",
          value: val,
          sortable: true,
          dday: true,
        });
      } else {
        for (var i = 0; i < this.currentDays; i++) {
          this.headerNames.push({
            text: i + 1,
            align: "center",
            value: i + 1,
            sortable: true,
            dday: true,
          });
        }
      }
      if (this.$attrs.currentSelectedClassGroup) this.checkAttentanceStatus();
    },
    selectedEeljTsag() {
      if (this.$attrs.currentSelectedClassGroup) this.checkAttentanceStatus();
    },
    selectedMonth() {
      if (this.$attrs.currentSelectedClassGroup) this.checkAttentanceStatus();
    },
  },

  methods: {
    _print(student) {
      console.log(student);
    },
    checkAttentanceStatus() {
      console.log("aab",this.selectedMonth,this.selectedXXDay, this.selectedEeljTsag, this.$attrs.currentSelectedClassGroup)
      if (this.$attrs.collectedStudents) {
        // console.log(this.lesson.ref.path);
      } else if(this.selectedEeljTsag) {
        // if (this.$attrs.currentSelectedClassGroup.classGroupRef) {
        //   this.$attrs.currentSelectedClassGroup.classGroupRef.
        //   collection("attendances-" + this.userData.school.currentYear)
        //   .where("month", "==", this.selectedMonth)
        //   .where("day", "==", this.selectedXXDay)
        //   .get()
        //   .then((docs) => {
        //     this.studentsStatuses = []
        //     this.$attrs.studentsStatuses2 = []
        //     docs.forEach((doc) => {
        //       let stat = doc.data();
        //       stat.id = doc.id;
        //       stat.ref = doc.ref;
        //       if (stat.studentId) {
        //         // if (this.irzEditing == false && att.daily_attendance) {
        //           //by manager, director, class Teacher, jijuur
        //         if (stat.daily_attendance) {
        //           this.studentsStatuses.push(stat)
        //           this.$attrs.studentsStatuses2.push(stat)
        //         }
        //         //hicheel deer choloolson
        //         else if (stat.daily_attendance == false
        //           && stat.month == this.selectedMonth
        //           && stat.xeelj == this.selectedEelj
        //           && stat.day == this.selectedXXDay
        //           && stat.status != 1
        //         ) {
        //           this.studentsStatuses.push(stat)
        //           this.$attrs.studentsStatuses2.push(stat)
        //         }
        //       }
        //     });
        //   });  
        // }        
      }
    },
    changeSelectedSubTopic(val) {
      console.log(val);
      if (this.selectedSubtopic && this.selectedSubtopic.ref) {
        if (this.selectedSubtopic.ref.path == val.ref.path) {
          this.lesson.ref
            .collection("teachedTopics")
            .doc(
              this.$attrs.currentSelectedClassGroup.departmentRef.id +
                "-" +
                this.selectedSubtopic.ref.parent.parent.id +
                "-" +
                this.selectedSubtopic.ref.id
            )
            .delete()
            .then(() => {
              this.selectedSubtopic = null;
            });
        } else {
          this.lesson.ref
            .collection("teachedTopics")
            .doc(
              this.$attrs.currentSelectedClassGroup.departmentRef.id +
                "-" +
                this.selectedSubtopic.ref.parent.parent.id +
                "-" +
                this.selectedSubtopic.ref.id
            )
            .delete()
            .then(() => {
              this.lesson.ref
                .collection("teachedTopics")
                .doc(
                  this.$attrs.currentSelectedClassGroup.departmentRef.id +
                    "-" +
                    val.ref.parent.parent.id +
                    "-" +
                    val.ref.id
                )
                .set({ createdAt: new Date() })
                .then(() => {
                  this.lesson.ref
                    .collection("teachedTopics")
                    .doc(
                      this.$attrs.currentSelectedClassGroup.departmentRef.id +
                        "-" +
                        val.ref.parent.parent.id +
                        "-" +
                        val.ref.id
                    )
                    .collection("classGroups")
                    .doc(
                      this.$attrs.currentSelectedClassGroup.classGroupRef.id +
                        "-" +
                        this.lesson.year +
                        "-" +
                        this.selectedMonth +
                        "-" +
                        this.selectedXXDay
                    )
                    .set({
                      teachedAt: new Date(),
                      departmentRef:
                        this.$attrs.currentSelectedClassGroup.departmentRef
                          .path,
                      classGroupRef:
                        this.$attrs.currentSelectedClassGroup.classGroupRef
                          .path,
                      departmentName:
                        this.$attrs.currentSelectedClassGroup.departmentName,
                      classGroupName:
                        this.$attrs.currentSelectedClassGroup.classGroupName,
                    });
                });
            });
        }
      } else {
        this.lesson.ref
          .collection("teachedTopics")
          .doc(
            this.$attrs.currentSelectedClassGroup.departmentRef.id +
              "-" +
              val.ref.parent.parent.id +
              "-" +
              val.ref.id
          )
          .set({ createdAt: new Date() })
          .then(() => {
            this.lesson.ref
              .collection("teachedTopics")
              .doc(
                this.$attrs.currentSelectedClassGroup.departmentRef.id +
                  "-" +
                  val.ref.parent.parent.id +
                  "-" +
                  val.ref.id
              )
              .collection("classGroups")
              .doc(
                this.$attrs.currentSelectedClassGroup.classGroupRef.id +
                  "-" +
                  this.lesson.year +
                  "-" +
                  this.selectedMonth +
                  "-" +
                  this.selectedXXDay
              )
              .set({
                teachedAt: new Date(),
                departmentRef:
                  this.$attrs.currentSelectedClassGroup.departmentRef.path,
                classGroupRef:
                  this.$attrs.currentSelectedClassGroup.classGroupRef.path,
                departmentName:
                  this.$attrs.currentSelectedClassGroup.departmentName,
                classGroupName:
                  this.$attrs.currentSelectedClassGroup.classGroupName,
              });
          });
      }
    },

    _checkRequest(student) {
      this.checkRequestDialog = true;
      this.selectedStudent = student;
    },
    _getRequest(student) {
      var xx = student.requests.reduce(
        (pre, currentValue) => (pre + ", " + currentValue.requestType, "")
      );
      console.log(xx);
      return xx;
    },
    _getCurrentDateAsString(studentId) {
      return (
        this.userData.school.currentYear +
        "-" +
        this.selectedMonth +
        "-" +
        this.selectedXXDay +
        "-" +
        this.selectedEeljTsag +
        "-" +
        studentId
      );
    },
    _getStatus0(student, day) {
      if (this.selectedEeljTsag) {
        if (student.attendances != null && student.attendances != undefined) {
          for (const attend of student.attendances) {
            if (
              attend.day == day &&
              attend.month == this.selectedMonth &&
              attend.xeelj == this.selectedEeljTsag
            ) {
              return attend.text;
            }
          }
        }
      } else {
        if (student.attendances != null && student.attendances != undefined) {
          for (const attend of student.attendances) {
            if (attend.day == day && attend.month == this.selectedMonth) {
              return attend.text;
            }
          }
        }
      }

      return "Ирсэн";
    },
    _getStatus(student) {
      if (this.$attrs.studentsStatuses2) {
        // var stat = this.studentsStatuses.find(ss => ss.studentId == student.id)
        var stat = this.$attrs.studentsStatuses2.find(ss => ss.studentId == student.id)
        if (stat) {
          if (stat.daily_attendance==true&&stat.excusedByTeacher)  {
            student.infoText = "АУБ чөлөөлсөн"
          }
          else if (stat.daily_attendance==true&&stat.excusedByManager) {
            student.infoText =  "Менежер чөлөөлсөн"
          }
          else if (stat.daily_attendance==true&&stat.excusedByDirector) {
            student.infoText =  "Захирал чөлөөлсөн"
          } 
          else if(stat.lessonIrz) student.lessonIrz = true
          return stat.text 
        }
      }
      return "Ирсэн";
    },
    _getExcused(student) {
      if (this.$attrs.studentsStatuses2) {
        var stat = this.$attrs.studentsStatuses2.find(ss => ss.studentId == student.id)
        if (stat) {
          if (stat.excusedByTeacher)  {
            return "АУБ чөлөөлсөн"
          }
          else if (stat.excusedByManager) {
            return "Менежер чөлөөлсөн"
          }
          else if (stat.excusedByDirector) {
            return "Захирал чөлөөлсөн"
          } 
        }
      }
      return null;
    },
    _getNotes(student, day) {
      if (
        student.studentsNotes != null &&
        student.studentsNotes != undefined &&
        this.selectedEeljTsag
      ) {
        for (const note of student.studentsNotes) {
          if (
            note.day == day &&
            note.month == this.selectedMonth &&
            note.xeelj == this.selectedEeljTsag
          ) {
            //return note.notes.reduce( (pre,currentValue) => pre.name +", " + currentValue.name) ;
            var output = "";
            var counter = 0;
            for (var xx of note.notes) {
              output = counter == 0 ? xx.name : output + ", " + xx.name;
              counter++;
            }
            if (note.descriptionAddedByTeacher)
              output = output + " " + note.descriptionAddedByTeacher;
            return output;
          }
        }
      }
      return "-";
    },
    _setStatus0(student, day, status) {
      // console.log(student);
      if (this.selectedEeljTsag) {
        if (status.value == 1) {
          student.classGroupRef
            .collection("attendances-" + this.userData.school.currentYear)
            .doc(
              this.userData.school.currentYear +
                "-" +
                this.selectedMonth +
                "-" +
                day +
                "-" +
                this.selectedEeljTsag +
                "-" +
                student.id
            )
            .delete();
        } else {
          //irsen eees busad
          var dd = student.classGroupRef
            .collection("attendances-" + this.userData.school.currentYear)
            .doc(
              this.userData.school.currentYear +
                "-" +
                this.selectedMonth +
                "-" +
                day +
                "-" +
                this.selectedEeljTsag +
                "-" +
                student.id
            );
          console.log(dd);
          //TODO20231102
          dd.set(
            {
              status: status.value,
              text: status.text,
              day: day,
              month: this.selectedMonth,
              year: this.userData.school.currentYear,
              xeelj: this.selectedEeljTsag,
              studentId: student.id,
              daily_attendance: false,
              createdByTeacherRef: this.userData.ref,
              createdByName: this.userData["DISPLAY_NAME"]
                ? this.userData["DISPLAY_NAME"]
                : this.userData.firstName,
            },
            { merge: true }
          ).then(() => {});
        }
      } else {
        this.alertText = "Xичээлийн цагаа сонгоно уу!";
        this.showAlert = true;
      }
    },
    _setStatus(student, day, status) {
      console.log(this.$attrs.studentsStatuses2)
      if (this.selectedEeljTsag) {
        var newStatus = {
          PROGRAM_STAGE_ID:student.PROGRAM_STAGE_ID,
          STUDENT_GROUP_ID:student.STUDENT_GROUP_ID,
          STUDENT_GROUP_NAME:student.STUDENT_GROUP_NAME,
          FIRST_NAME:student.FIRST_NAME,
          LAST_NAME:student.LAST_NAME,
          schoolId: this.userData.school.id,
          schoolName: this.userData.school.name,
    
          status: status.value,
          text: status.text,

          xeelj: this.selectedEeljTsag,
          day: this.$attrs.selectedDay,
          month: this.selectedMonth,
          year: this.$attrs.selectedYear,
          studentId: student.id,
          
          daily_attendance: false,
          madeByDailyIrz:false,
          dailyIrz: false,

          createdByTeacherRef: this.userData.ref,
          createdByName: this.userData["DISPLAY_NAME"]
            ? this.userData["DISPLAY_NAME"]
            : this.userData.firstName
              ? this.userData.firstName
              : this.userData.email,
        };
        console.log(newStatus)
        var found = this.$attrs.studentsStatuses2.find(stat => stat.studentId == student.id)
        if (found) {
          found.status = status.value
          found.text = status.text
        } else {
          this.$attrs.studentsStatuses2.push(newStatus)
        }
      } else {
        this.alertText = "Xичээлийн цагаа сонгоно уу!";
        this.showAlert = true;
      }
    },
    _saveAddNote() {
      if (this.selectedEeljTsag) {
        var note = {
          forClassTeacher: this.forClassTeacher,
          forStudent: this.forStudent,
          forManager: this.forManager,
          forParents: this.forParents,
          notes: this.selectedReasons,
          day: this.selectedXXDay,
          month: this.selectedMonth,
          year: this.userData.school.currentYear,
          descriptionAddedByTeacher: this.noteAdded ? this.noteAdded : null,
          xeelj: this.selectedEeljTsag,
          studentId: this.selectedStudent.id,
          studentNotes: true,
        };

        this.selectedStudent.classGroupRef
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(
            "studentsNotes-" +
              this.userData.school.currentYear +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedXXDay +
              "-" +
              this.selectedEeljTsag +
              "-" +
              this.selectedStudent.id
          )
          .set(note, { merge: true })
          .then(() => {
            this.dialogAddNote = false;
            this.noteAdded = null;
            this.selectedStudent = null;
          });
      } else {
        alert("Xичээлийн цагаа сонгоно уу!");
      }
    },

    _cancelAddNote() {
      this.dialogAddNote = false;
      this.noteAdded = null;
      this.selectedStudent = null;
    },
    _addNote(student) {
      this.selectedStudent = student;
      this.selectedReasons = [];
      this.dialogAddNote = true;
      //TODO
      // console.log(
      //   student.studentsNotes.find(
      //     (note) =>
      //       note.ref.parent.parent.parent.parent.path == student.ref.path
      //   )
      // );
      for (const note of student.studentsNotes) {
        if (
          note.day == this.selectedXXDay &&
          note.month == this.selectedMonth &&
          note.xeelj == this.selectedEeljTsag
        ) {
          this.forClassTeacher = note.forClassTeacher;
          this.forStudent = note.forStudent;
          this.forManager = note.forManager;
          this.forParents = note.forParents;
          this.noteAdded = note.descriptionAddedByTeacher;
          this.selectedReasons = note.notes;
          console.log(this.forParents);
        }
      }
    },
    _getDays() {
      if (this.selectedXXDay && this.selectedXXDay > 0)
        return [this.selectedXXDay];
      else return this.currentDays;
    },
    _generateDayArray(range) {
      return Array.from({ length: range }, (v, k) => k + 1);
    },
    _getPrefix(item) {
      var name = null;
      if (item.prefixName) {
        if (item.lastName && item.lastName.length >= item.prefixName)
          name =
            item.firstName + ". " + item.lastName.substring(0, item.prefixName);
        else name = item.firstName;
      } else {
        if (item.lastName) name = item.firstName + ". " + item.lastName[0];
        else name = item.firstName;
      }
      return name;
    },
  
    isTeached() {
      var xx = false;
      this.teachedTopics.forEach((tt) => {
        if (tt.teachedDay == this.selectedXXDay) {
          xx = true;
          this.selectedHutolbor = tt.topic;
          // console.log(this.selectedHutolbor)
        }
      });
      return xx;
    },
    
  },
};
</script>
<style>
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}

/* tbody {
    display: inline-block !important;
    flex-direction: column;
    width: 100%!important;
} */
</style>
