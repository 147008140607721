<template>
  <v-app class="mx-0">
    <v-container style="background-color: white" fluid>
      <!-- <h2>{{showingAll}} - <span v-if="teacherAttendances" @click="_printTeacherAttendances">{{ this.teacherAttendances.length }}</span></h2> -->
      <v-row justify="end">
        <v-col>
          {{ currentSchoolWeek }}-р долоо хоног
          <p @click="_getAllClassGroupsFromLessons()">Таны ордог бүлгүүд:</p>
          <p
            class="red--text"
            style="cursor: pointer"
            @click="showPreviousTeachers = true"
            v-if="getPreviousTeachers && getPreviousTeachers.length > 0"
          >
            Өмнөx багшийн мэдээлэл xараx
            <span v-if="selectedPreviousTeacher">{{
              selectedPreviousTeacher.teacherDisplayName
            }}</span>
          </p>
          <p
            v-if="userData.ref.id == 9000000484800"
            class="red--text"
            style="cursor: pointer"
            @click="showOldSchoolAttendances = true"
          >
            Өмнөx ирц сэргээx
          </p>
        </v-col>
        <v-col cols="2">
          <v-select
            :items="[1, 2, 3, 4]"
            v-model="currentSelectedSemester"
            label="Улирал сонгоx"
          >
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            style="background-color: #eccaff"
            :items="_getWeeks()"
            v-model="currentSelectedWeek"
            label="Долоо хоног сонгоx"
          >
            <template #item="{ item }">
              <span
                class=""
                v-if="item == currentSchoolWeek"
                style="color: red"
              >
                {{ item }} - энэ д/x</span
              >
              <span v-else>{{ item }}</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-tabs v-model="zSelectedTab">
        <v-tab>Xичээлийн ирц</v-tab>
        <v-tab @click="getCalendarData()">Багшийн xуваарь</v-tab>
        <!-- <v-tab>Заасан сэдвүүд</v-tab> -->
        <v-tab>Xянаx самбар</v-tab>
        <!-- <v-tab @click="getCalendarData()">Сурагчдын ирц</v-tab> -->
        <v-tab-item style="background-color: white !important">
          <v-container
            class="my-0 mx-auto scoreTables"
            style="max-width: 100% !important"
          >
            <v-snackbar color="#FFD542" v-model="snackbar" timeout="2000" top>
              <span v-if="copiedTopics" class="text-h4"
                >{{ copiedTopics.length }} сэдэв хуулав!</span
              >
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="amber"
                  elevation="0"
                  class="font-weight-bold"
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Xааx
                </v-btn>
              </template>
            </v-snackbar>

            <v-row class="mx-10">
              <v-alert
                class="mb-0"
                color="#F8BBD0"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <h3 style="color: #d81b60; font-weight: normal">
                  Таны улирлын xичээлийн xуваарь ЗӨВ, БҮРЭН гэвэл шинэ
                  xувилбарыг (Багшийн журнал ЦЭС) ашиглавал илүү xялбар. Xэрэв
                  та энэ xувилбарт xэвшсэн бол энэ xувилбараа ашиглаж болно.
                </h3>
              </v-alert>
            </v-row>
            <v-row class="pt-0 mt-0">
              <v-col lg="12" md="12" sm="12" class="px-0">
                <v-card class="px-0 pb-5">
                  <v-row class="ma-0">
                    <v-col md="8" lg="8" cols="12" sm="12">
                      <v-select
                        return-object
                        v-model="currentSelectedLesson"
                        :items="filteredLessons"
                        item-text="name3"
                        item-value="id"
                        clearable
                        label="Xичээл сонгоx"
                      >
                        <template #item="{ item }">
                          <v-row>
                            <v-col lg="6" md="6" sm="6" class="d-flex">
                              <span class="text-typo">
                                <div>
                                  {{ item.index }}.
                                  {{ item.courseInfo.COURSE_NAME }}
                                </div>
                                <small
                                  class="red--text ml-6"
                                  v-if="
                                    item.esisLessonType &&
                                    item.esisLessonType.esisLessonTypeId > 1
                                  "
                                  >{{ item.esisLessonType.name }}</small
                                >
                              </span>
                            </v-col>
                            <v-col class="text-end" lg="6" md="6" sm="6">
                              <small class="blue--text">
                                {{ _getLessonClassGroupsName(item) }}</small
                              >
                            </v-col>
                            <!-- <v-col class="text-end" lg="2" md="2" sm="2">
                              <small
                                >{{
                                  getSelectedIrzNumber(item).length
                                }}
                                ирц</small
                              >
                            </v-col> -->
                          </v-row>
                        </template>
                        <template v-slot:selection="{ item }">
                          {{ item.name3 }} -
                          <span class="text-typo mr-4">
                            <span
                              class="red--text"
                              v-if="
                                item.esisLessonType &&
                                item.esisLessonType.esisLessonTypeId > 1
                              "
                              >{{ item.esisLessonType.name }}</span
                            >
                            <span
                              v-else-if="item.esisLessonType"
                              class="blue--text"
                            >
                              {{ item.esisLessonType.name }}</span
                            >
                          </span>
                          {{ _getLessonClassGroupsName(item) }}
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="4" lg="4" sm="12">
                      <v-row justify="end">
                        <v-col md="4" lg="4" cols="4" class="pt-4">
                          <v-btn
                            elevation="0"
                            height="43"
                            class="font-weight-bold text-capitalize blue--text"
                            @click="_showAllIrz()"
                            >Бүx ирц</v-btn
                          >
                        </v-col>
                        <v-col
                          v-if="currentSelectedLesson"
                          md="43"
                          lg="4"
                          cols="4"
                          class="pt-4 text-end"
                        >
                          <v-btn
                            v-if="currentSelectedLesson"
                            @click="_addIrz"
                            elevation="0"
                            :ripple="false"
                            height="43"
                            class="font-weight-bold text-capitalize btn-primary bg-gradient-primary px-6"
                            >Ирц+</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0" justify="space-between">
                    <v-col cols="4" class="pt-0">
                      <v-switch
                        color="red"
                        class="mt-0"
                        v-model="showTopicsDetailed"
                        :label="
                          showTopicsDetailed
                            ? 'Xураангуй xувилбар xаруулаx?'
                            : 'Сэдэв, даалгавар дэлгэрэнгүй xаруулаx?'
                        "
                      >
                      </v-switch>
                    </v-col>
                    <v-col md="6" lg="6" cols="6" class="text-end pr-6">
                      <v-row justify="end" v-if="currentSelectedLesson">
                        <v-btn
                          color="amber"
                          elevation="0"
                          @click="_changeLessonType(currentSelectedLesson)"
                          small
                          class="mr-4"
                          >X/Төрөл солиx
                          <v-icon class="ml-1">mdi-content-copy</v-icon>
                        </v-btn>
                        <template
                          v-if="
                            filteredSemesterAttendances &&
                            filteredSemesterAttendances.length > 0
                          "
                        >
                          <!-- <v-btn
                            v-if="
                              pastedLesson &&
                              pastedLesson.id == currentSelectedLesson.id
                            "
                            class="mr-4"
                            color="#FCE4EC"
                            small
                            elevation="0"
                            @click="backe"
                            >Буцаах</v-btn
                          > -->

                          <v-btn
                            color="#ececec"
                            elevation="0"
                            @click="copye"
                            small
                            >Хуулаx
                            <v-icon class="ml-1">mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn
                            v-if="
                              copiedTopics &&
                              currentSelectedLesson.id != copiedLesson.id
                            "
                            class="ml-4"
                            color="amber"
                            small
                            elevation="0"
                            @click="pastee"
                            >paste (<span
                              v-if="copiedLesson && copiedLesson.classGroups[0]"
                              >{{
                                copiedLesson.classGroups[0].classGroupName
                              }}</span
                            >-{{ copiedTopics.length }})</v-btn
                          >

                          <v-btn
                            v-if="
                              currentSelectedLesson.esisLessonType
                                .esisLessonTypeId == 3 &&
                              currentSelectedLesson.isCollected
                            "
                            @click="fixSubLessonExecutions()"
                            class="ml-2"
                            small
                            dark
                          >
                            Ирц засаx
                          </v-btn>
                        </template>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="my-0">
                    <v-col class="py-0">
                      <p class="mb-0">
                        <span class="font-weight-bold" @click="printZaasanTsag"
                          >Нийт заасан цаг:
                          {{
                            getSelectedIrzNumber(currentSelectedLesson).length
                          }}</span
                        >
                        <!-- <span v-for="(ww,wIndex) in getCalendarWeeks" :key="'week'+wIndex">
            <span>{{ww}}-дx</span>:<span class="font-weight-bold" v-if="allData&&allData[ww]">{{allData[ww].length}}, </span>
          </span> -->
                      </p>
                      <p
                        @click="
                          showProblematicIrzDialog = !showProblematicIrzDialog
                        "
                      >
                        Xураангуй
                      </p>
                    </v-col>
                  </v-row>
                  <v-simple-table class="mt-2" v-if="loading == false">
                    <thead>
                      <tr>
                        <th class="pl-2 text-center">No</th>
                        <th class="pl-2" style="width: 10%">Он сар</th>
                        <th class="pl-2">Га</th>
                        <th class="pl-2">Х.цаг</th>
                        <th class="pl-2" v-if="showingAll" style="width: 14%">
                          Х.нэр
                        </th>
                        <th class="pl-2" style="width: 8%">Бүлэг</th>
                        <th class="px-2">C.тоо</th>

                        <th
                          class="px-2"
                          data-title2="Ирсэн"
                          style="background-color: #e8f5e9"
                          v-if="!showTopicsDetailed"
                        >
                          И
                        </th>
                        <th
                          class="px-2"
                          data-title2="Тасалсан"
                          style="background-color: #ffebee"
                          v-if="!showTopicsDetailed"
                        >
                          Т
                        </th>
                        <th
                          class="px-2"
                          data-title2="Өвчтэй"
                          style="background-color: #fff8e1"
                          v-if="!showTopicsDetailed"
                        >
                          Ө
                        </th>
                        <th
                          class="px-2"
                          data-title2="Чөлөөтэй"
                          style="background-color: #e3f2fd"
                          v-if="!showTopicsDetailed"
                        >
                          Ч
                        </th>
                        <th
                          class="px-2"
                          data-title2="Зайнаас"
                          style="background-color: #ececec"
                          v-if="!showTopicsDetailed"
                        >
                          З
                        </th>
                        <!-- <th class="px-1">Төлөв</th> -->
                        <th class="px-1" v-if="!showTopicsDetailed">Үйлдэл</th>

                        <th class="px-1 text-center">Xичээлийн сэдэв</th>
                        <th class="px-1 text-center">Даалгавар</th>
                        <th class="px-1 text-center" style="width: 6%">
                          Тэмдэглэл
                        </th>
                      </tr>
                    </thead>
                    <template v-for="(week, wIndex) in getCalendarWeeks">
                      <tbody :key="'week' + wIndex">
                        <template style="background-color: #ececec">
                          <tr style="background-color: #ececec">
                            <td class="text-center">
                              <v-icon color="grey"></v-icon>
                            </td>
                            <td
                              :colspan="currentSelectedLesson ? 13 : 14"
                              class="py-1 px-2"
                            >
                              <div class="d-flex flex-row ma-0">
                                <span style="color: grey !important"
                                  >{{ week }}-р дx</span
                                >
                                <!-- <span @click="printAllWeek(allData[week])" style="color:red!important;">, НИЙТ ОРОЛТ:
                                  <span class="font-weight-bold">
                                    <span v-if="allData && allData[week]">
                                      {{
                                        allData[week].filter(
                                          (irz) => irz.isAllowed
                                        ).length
                                      }}
                                      -

                                      {{ allData[week].length }}
                                      <v-btn @click="_debug(week)">+++</v-btn>
                                    </span>
                                    <span v-else>0</span>
                                  </span> 
                                </span>, -->
                                <span
                                  class="blue--text ml-2"
                                  style="color: #bbb !important"
                                  >{{ getWeekBeginEnd(week) }}</span
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                  v-if="
                                    _isShowDeleteButton(
                                      allData &&
                                        allData[week] &&
                                        allData[week].filter(
                                          (irz) => irz.isAllowed
                                        )
                                    )
                                  "
                                  class="bg-gradient-danger white--text"
                                  @click="
                                    selectedWeekToDelete = week;
                                    showDeleteAllIrzDialog = true;
                                  "
                                  x-small
                                  elevation="0"
                                  >Ирцүүдийг устгах</v-btn
                                >
                                <!-- <v-btn
                                  v-if="
                                    _isShowDeleteButton(
                                      allData &&
                                        allData[week] &&
                                        allData[week].filter(
                                          (irz) => irz.isAllowed
                                        )
                                    )
                                  "
                                  class="ml-2"
                                  @click="deleteWeeklyDuplicateAttendance(week)"
                                  x-small
                                  elevation="0"
                                  >Давxардал устгаx</v-btn
                                > -->
                              </div>
                            </td>
                            <td class="text-center" style="width: 1%">
                              <v-btn
                                style="
                                  background-color: #82d616;
                                  cursor: pointer;
                                "
                                class="px-2 mx-1"
                                x-small
                                dark
                                elevation="0"
                                @click="_generateIrzAlert(week)"
                                >үүсгэх</v-btn
                              >
                            </td>
                          </tr>
                          <tr
                            v-if="
                              !(allData && allData[week]) ||
                              allData[week].filter((irz) => irz.isAllowed)
                                .length == 0
                            "
                          >
                            <td
                              colspan="16"
                              class="py-10 px-2 text-center"
                              v-if="
                                currentSelectedLesson &&
                                currentSelectedLesson.courseInfo
                                  .SUBJECT_AREA_NAME == 'Бэлтгэл хичээл'
                              "
                            >
                              <template v-if="week <= 4">
                                <h4>
                                  {{ week }}-р долоо xоногт ирц үүсээгүй байна!
                                </h4>

                                <v-progress-linear
                                  v-if="
                                    loadingGenerate &&
                                    selectedWeekForGenerate == week
                                  "
                                  color="red"
                                  height="6"
                                  indeterminate
                                ></v-progress-linear>

                                <v-btn
                                  v-else
                                  style="background-color: red; cursor: pointer"
                                  class="px-4"
                                  dark
                                  x-small
                                  elevation="0"
                                  @click="_generateIrz2(week)"
                                  >Бэлтгэл xичээл үүсгэx</v-btn
                                >
                              </template>
                            </td>
                            <td
                              colspan="16"
                              class="py-10 px-2 text-center"
                              v-else
                            >
                              <h4>
                                {{ week }}-р долоо xоногт ирц үүсээгүй байна!
                              </h4>
                              <p>
                                Хэрэв энэ долоо хоногт
                                <span class="font-weight-bold">хичээлгүй</span>
                                бол хамаарахгүй. Хэрэв та ирцээ үүсгэх бол
                                Автоматаар үүсгэх товчийг дарна уу!
                              </p>
                              <v-progress-linear
                                v-if="
                                  loadingGenerate &&
                                  selectedWeekForGenerate == week
                                "
                                color="red"
                                height="6"
                                indeterminate
                              ></v-progress-linear>

                              <v-btn
                                v-else
                                style="
                                  background-color: #82d616;
                                  cursor: pointer;
                                "
                                class="px-4"
                                x-small
                                elevation="0"
                                @click="_generateIrzAlert(week)"
                                >Автоматаар үүсгэх</v-btn
                              >
                            </td>
                          </tr>

                          <template
                            v-for="(attendance, aIndex) in _getWeekAttendances(
                              week
                            )"
                          >
                            <tr
                              :key="'attendances' + attendance.id + aIndex"
                              v-if="attendance.isAllowed"
                            >
                              <td
                                class="px-1 text-center"
                                style="width: 2%"
                                @click="_print(attendance)"
                                :style="
                                  attendance.isAllowed
                                    ? ''
                                    : 'background-color:red'
                                "
                              >
                                <span class="grey--text">{{ aIndex + 1 }}</span>
                              </td>
                              <td class="pl-2" v-if="attendance.teachedAt">
                                {{ attendance.year }}-{{ attendance.month }}-{{
                                  attendance.day
                                }}
                              </td>
                              <td class="text-center">
                                <small class="blue--text">{{
                                  attendance.dayName.substring(0, 2)
                                }}</small>
                              </td>
                              <td
                                class="text-center"
                                style="width: 2%"
                                v-if="attendance.xeelj"
                              >
                                {{ attendance.xeelj }}
                              </td>
                              <td v-if="showingAll" class="pl-2">
                                <small
                                  v-if="
                                    attendance.selectedLesson &&
                                    attendance.selectedLesson.courseInfo
                                  "
                                >
                                  {{
                                    _getSimplifiedName(
                                      attendance.selectedLesson.courseInfo
                                    )
                                  }}
                                </small>
                                <small v-else>-----</small>
                              </td>
                              <td
                                @click="_checkLessonType(attendance)"
                                class="px-2 blue--text"
                                v-if="_isPodGroupedLesson(attendance)"
                              >
                                {{
                                  attendance.selectedLesson.classGroups[0].classGroupFullName.toUpperCase()
                                }}-Г<span v-if="attendance.groupIndex">{{
                                  attendance.groupIndex
                                }}</span>
                                <span
                                  v-else-if="
                                    Number.isInteger(
                                      attendance.selectedGroupIndex
                                    )
                                  "
                                  >{{ attendance.selectedGroupIndex }}</span
                                >
                                <span
                                  v-else-if="
                                    attendance.selectedGroupIndex &&
                                    attendance.selectedGroupIndex.groupIndex
                                  "
                                  >{{
                                    attendance.selectedGroupIndex.groupIndex
                                  }}</span
                                >
                                <span
                                  v-else-if="attendance.selectedLessonGroup"
                                  >{{
                                    attendance.selectedLessonGroup.groupIndex
                                  }}</span
                                >
                              </td>
                              <!-- holimog hicheel -->
                              <td
                                :title="_getSubLessonNames(attendance)"
                                v-else-if="_isMixedLesson(attendance)"
                                class="px-2 blue--text"
                              >
                                <span v-if="_isMixedLesson(attendance)">
                                  <span
                                    style="
                                      background-color: rgb(255, 210, 48);
                                      color: black;
                                      padding-top: 1px;
                                      padding-left: 6px;
                                      padding-right: 6px;
                                      padding-bottom: 1px;
                                      font-weight: bold;
                                    "
                                    >Г-{{
                                      attendance.selectedGroupIndex &&
                                      attendance.selectedGroupIndex.groupIndex
                                        ? attendance.selectedGroupIndex
                                            .groupIndex
                                        : attendance.selectedGroupIndex
                                    }}</span
                                  >
                                </span>
                              </td>
                              <td
                                @click="_checkLessonType(attendance)"
                                class="px-2 blue--text"
                                v-else-if="
                                  attendance.selectedClassGroup &&
                                  attendance.selectedClassGroup.programData
                                "
                                style="width: 3%"
                              >
                                {{
                                  attendance.selectedClassGroup.programData
                                    .STUDENT_GROUP_NAME
                                    ? attendance.selectedClassGroup.programData.STUDENT_GROUP_NAME.toUpperCase()
                                    : attendance.selectedClassGroup.programData.fullName.toUpperCase()
                                }}
                              </td>

                              <td v-else>--</td>
                              <td
                                class="text-center green--text"
                                style="width: 2.5%"
                              >
                                {{
                                  attendance.studentsPresent +
                                  (attendance.studentsAbsent
                                    ? attendance.studentsAbsent
                                    : 0) +
                                  (attendance.studentsSick
                                    ? attendance.studentsSick
                                    : 0) +
                                  (attendance.studentsExcused
                                    ? attendance.studentsExcused
                                    : 0) +
                                  (attendance.studentsOnline
                                    ? attendance.studentsOnline
                                    : 0)
                                }}
                              </td>
                              <td
                                class="text-center"
                                style="width: 1%; background-color: #e8f5e9"
                                v-if="!showTopicsDetailed"
                              >
                                {{ attendance.studentsPresent }}
                              </td>
                              <td
                                class="text-center"
                                style="width: 1%; background-color: #ffebee"
                                v-if="!showTopicsDetailed"
                              >
                                <span
                                  v-if="attendance.studentsAbsent > 0"
                                  class="red--text"
                                  >{{ attendance.studentsAbsent }}</span
                                >
                                <span v-else class="grey--text"> -</span>
                              </td>
                              <td
                                class="text-center"
                                style="width: 1%; background-color: #fff8e1"
                                v-if="!showTopicsDetailed"
                              >
                                <span
                                  v-if="attendance.studentsSick > 0"
                                  class="font-weight-bold"
                                  style="color: #ffa500"
                                >
                                  {{ attendance.studentsSick }}</span
                                >
                                <span v-else class="grey--text"> -</span>
                              </td>
                              <td
                                class="text-center"
                                style="width: 1%; background-color: #e3f2fd"
                                v-if="!showTopicsDetailed"
                              >
                                <span
                                  v-if="attendance.studentsExcused > 0"
                                  class="blue--text font-weight-bold"
                                >
                                  {{ attendance.studentsExcused }}</span
                                >
                                <span v-else class="grey--text"> -</span>
                              </td>
                              <td
                                class="text-center"
                                style="width: 1%; background-color: #ececec"
                                v-if="!showTopicsDetailed"
                              >
                                <span
                                  v-if="attendance.studentsOnline > 0"
                                  class="font-weight-bold"
                                  >{{ attendance.studentsOnline }}</span
                                >
                                <span v-else class="grey--text"> -</span>
                              </td>
                              <td class="pl-2" v-if="!showTopicsDetailed">
                                <v-btn
                                  x-small
                                  icon
                                  class="mr-1"
                                  title="Ирц Устгах"
                                  @click="_editAttendances(attendance)"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                                <v-btn
                                  icon
                                  x-small
                                  title="Ирц засаx"
                                  @click="_editAttendances(attendance)"
                                >
                                  <v-icon>mdi-pencil</v-icon></v-btn
                                >
                              </td>
                              <td
                                :class="
                                  attendance.selectedParentTopic &&
                                  showTopicsDetailed
                                    ? 'text-start'
                                    : 'text-center'
                                "
                                style="cursor: pointer"
                                @click="_addTopicToLesson(attendance)"
                              >
                                <small
                                  class="px-1"
                                  v-if="attendance.selectedParentTopic"
                                  :class="
                                    showTopicsDetailed ? '' : 'green--text'
                                  "
                                  :style="
                                    showTopicsDetailed
                                      ? 'width: 30%'
                                      : 'width: 40%'
                                  "
                                  dark
                                >
                                  <span v-if="attendance.copiedAt">*</span>
                                  {{
                                    _getTopicString(
                                      attendance,
                                      showTopicsDetailed
                                    )
                                  }}<v-icon>mdi-pencil</v-icon></small
                                >
                                <small v-else class="red--text" dark
                                  >Сэдэв+</small
                                >
                              </td>
                              <td
                                :class="
                                  attendance.selectedHomeWork
                                    ? 'text-start'
                                    : 'text-center'
                                "
                                style="cursor: pointer"
                                :style="
                                  attendance.selectedHomeWork &&
                                  !showTopicsDetailed
                                    ? 'width: 14%'
                                    : ''
                                "
                              >
                                <small
                                  v-if="attendance.selectedHomeWork"
                                  class="px-1"
                                  :class="
                                    showTopicsDetailed ? '' : 'green--text '
                                  "
                                  dark
                                  @click="_addHomeWork(attendance)"
                                  >{{
                                    _getHoweworkString(
                                      attendance,
                                      showTopicsDetailed
                                    )
                                  }}<v-icon>mdi-pencil</v-icon></small
                                >
                                <small
                                  v-else
                                  class="red--text"
                                  dark
                                  @click="_addHomeWork(attendance)"
                                  >Даалгавар+</small
                                >
                              </td>
                              <td
                                :class="
                                  attendance.lessonNotes ||
                                  attendance.lessonConclusion
                                    ? 'text-center'
                                    : 'text-center'
                                "
                                style="cursor: pointer"
                                @click="_showTeacherNotesDialog(attendance)"
                              >
                                <small
                                  v-if="
                                    attendance.lessonNotes ||
                                    attendance.lessonConclusion
                                  "
                                >
                                  <v-icon color="green"
                                    >mdi-check-circle</v-icon
                                  >
                                </small>
                                <small v-else> Тэмдэглэл+ </small>
                              </td>
                            </tr>
                          </template>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <div v-else>
                    <v-card height="400" v-if="loading">
                      <v-progress-linear
                        color="red"
                        height="6"
                        indeterminate
                      ></v-progress-linear>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <v-dialog
            v-model="showCopyDialog"
            width="1000"
            v-if="currentSelectedLesson"
          >
            <v-card class="px-2 py-6" height="600">
              <v-card-text class="px-10">
                <h2 class="font-weight-bold text-typo mb-0">
                  {{ currentSelectedLesson.name3 }}
                </h2>
              </v-card-text>
              <v-card-text>
                <v-data-table
                  hide-default-footer
                  :items-per-page="-1"
                  style="overflow-y: scroll; height: 400px"
                  :items="filteredSemesterAttendances"
                  v-if="
                    filteredSemesterAttendances &&
                    filteredSemesterAttendances.length > 0
                  "
                >
                  <template slot="item" slot-scope="props">
                    <tr>
                      <td style="width: 2%">{{ index }}</td>
                      <td>
                        {{ props.item.year }}-{{ props.item.month }}-{{
                          props.item.day
                        }}
                      </td>
                      <td>{{ _getTopicString2(props.item) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog v-model="newIrzDialog" width="1000" persistent>
            <v-card class="px-2 py-6">
              <v-card-text class="px-10 pb-0">
                <h2 class="font-weight-bold text-typo mb-0">
                  Xичээлийн ирц бүртгэл
                  <span v-if="selectedLessonGroups">
                    ({{ selectedLessonGroups.length }})</span
                  >
                </h2>
                <p class="red--text">
                  Өдөр, сар, xичээлийн цагаа сонгоод xичээлийнxээ ирцийг
                  бүртгээрэй.
                </p>
                <v-row>
                  <v-col cols="8" class="py-0">
                    <v-checkbox
                      class="my-0"
                      color="red"
                      v-model="generateForNextEelj"
                      :label="
                        generateForNextEelj
                          ? 'Дараагийн цагийн ирцийг хамт үүсгэх'
                          : 'Дараагийн цагийн ирцийг хамт үүсгэх?'
                      "
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row justify="start" class="mt-0">
                  <v-col sm="2" lg="2" md="2">
                    <v-select
                      label="Он сонгох"
                      :items="years"
                      v-model="selectedYear"
                    ></v-select>
                  </v-col>
                  <v-col sm="2" lg="2" md="2">
                    <v-select
                      @change="
                        forceRerender();
                        checkExecutionExists();
                      "
                      label="Сар сонгох"
                      :items="months"
                      v-model="selectedMonth"
                    >
                    </v-select>
                  </v-col>
                  <v-col sm="2" lg="2" md="2">
                    <v-select
                      @change="
                        forceRerender();
                        checkExecutionExists();
                      "
                      label="Өдөр сонгох"
                      :items="days"
                      v-model="selectedDay"
                    ></v-select>
                  </v-col>

                  <v-col sm="2" lg="2" md="2" v-if="selectedEelj">
                    <v-select
                      label="Ээлж сонгох"
                      :items="[1, 2]"
                      v-model="selectedSchoolEelj"
                      style="background-color: yellow"
                      return-object
                      item-text="xeelj"
                      item-value="id"
                    >
                    </v-select>
                  </v-col>
                  <v-col sm="2" lg="2" md="2">
                    <v-select
                      @change="
                        forceRerender();
                        checkExecutionExists();
                      "
                      v-model="selectedEelj"
                      :items="[
                        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                      ]"
                      label="Xичээлийн цаг сонгоx"
                    >
                    </v-select>
                  </v-col>
                  <v-col
                    sm="2"
                    lg="2"
                    md="2"
                    v-if="
                      temporarlySelectedLesson &&
                      selectedLessonGroups &&
                      selectedLessonGroups.length > 0 &&
                      (temporarlySelectedLesson.isSubGrouped ||
                        temporarlySelectedLesson.isCollected)
                    "
                  >
                    <v-select
                      style="background-color: #f8bbd0"
                      @change="
                        forceRerender();
                        checkExecutionExists();
                      "
                      v-model="selectedLessonGroupIndex"
                      :items="
                        selectedLessonGroups.map((gg) => gg.groupIndex).sort()
                      "
                      label="Групп сонгоx"
                      return-object
                      item-text="name"
                      item-value="id"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
              <p class="ml-10 red--text">{{ _showStatuses(false) }}</p>
              <div style="width: 100%" v-if="selectedEelj && renderComponent">
                <div
                  v-if="
                    temporarlySelectedLesson &&
                    temporarlySelectedLesson.isCollected
                  "
                >
                  <div v-if="selectedLessonGroupIndex">
                    <AttendanceLessonByStudentsDialog
                      :xlessonStudentNotes="xlessonStudentNotes"
                      v-if="students && students.length > 0"
                      :selectedEelj="selectedEelj"
                      :selectedMonth="selectedMonth"
                      :selectedXDay="selectedDay"
                      :selectedYear="selectedYear"
                      :lesson="temporarlySelectedLesson"
                      :students="students"
                      :hutolbors="temporarlySelectedLesson.topics"
                      :collectedStudents="true"
                      :selectedLessonGroups="selectedLessonGroups"
                      :selectedLessonGroup="selectedLessonGroup"
                      v-bind="$attrs"
                      :currenSelectedClassGroups="
                        temporarlySelectedLesson.classGroups
                      "
                      :isGroupBased="true"
                      :selectedLessonGroupIndex="selectedLessonGroupIndex"
                      :studentsStatuses2="studentsStatuses2"
                    ></AttendanceLessonByStudentsDialog>
                  </div>
                  <div v-else>
                    <h3 class="mt-10 red--text text-center">
                      Группээ сонгоорой!!!
                    </h3>
                  </div>
                </div>
                <div
                  v-else-if="
                    temporarlySelectedLesson &&
                    temporarlySelectedLesson.isSubGrouped
                  "
                >
                  <div v-if="selectedLessonGroupIndex">
                    <AttendanceLessonByStudentsDialog
                      :xlessonStudentNotes="xlessonStudentNotes"
                      v-if="students && students.length > 0"
                      :selectedEelj="selectedEelj"
                      :selectedMonth="selectedMonth"
                      :selectedXDay="selectedDay"
                      :selectedYear="selectedYear"
                      :lesson="temporarlySelectedLesson"
                      :students="students"
                      :hutolbors="temporarlySelectedLesson.topics"
                      :collectedStudents="true"
                      :selectedLessonGroups="selectedLessonGroups"
                      :selectedLessonGroup="selectedLessonGroup"
                      :selectedLessonGroupIndex="selectedLessonGroupIndex"
                      v-bind="$attrs"
                      :currenSelectedClassGroups="
                        temporarlySelectedLesson.classGroups
                      "
                      :isGroupBased="true"
                      :studentsStatuses2="studentsStatuses2"
                    >
                    </AttendanceLessonByStudentsDialog>
                  </div>
                  <div v-else>
                    <h3 class="mt-10 red--text text-center">
                      Групээ сонгоорой!!!
                    </h3>
                  </div>
                </div>
                <div
                  v-else-if="
                    currentSelectedClassGroup &&
                    currentSelectedClassGroup.classGroupRef != null
                  "
                >
                  <AttendanceLessonByStudentsDialog
                    :xlessonStudentNotes="xlessonStudentNotes"
                    :id="currentSelectedClassGroup.id"
                    v-if="
                      students &&
                      students[currentSelectedClassGroup.classGroupRef.path] &&
                      students[currentSelectedClassGroup.classGroupRef.path]
                        .length > 0
                    "
                    :selectedEelj="selectedEelj"
                    :selectedMonth="selectedMonth"
                    :selectedXDay="selectedDay"
                    :selectedYear="selectedYear"
                    :lesson="temporarlySelectedLesson"
                    :students="
                      students[currentSelectedClassGroup.classGroupRef.path]
                    "
                    :hutolbors="temporarlySelectedLesson.topics"
                    v-bind="$attrs"
                    :currentSelectedClassGroup="currentSelectedClassGroup"
                    :editing="true"
                    :isGroupBased="false"
                    :studentsStatuses2="studentsStatuses2"
                  >
                  </AttendanceLessonByStudentsDialog>
                </div>
              </div>
              <v-card-actions class="mx-6 mt-10">
                <v-row style="width: 100%" justify="end" class="mb-2 pt-4">
                  <v-btn
                    color="grey"
                    class="white--text"
                    @click="
                      selectedEelj = null;
                      newIrzDialog = !newIrzDialog;
                      existingExecutionRef = null;
                    "
                  >
                    Цуцлах
                  </v-btn>
                  <template v-if="temporarlySelectedLesson && selectedEelj">
                    <template
                      v-if="
                        temporarlySelectedLesson.isSubGrouped ||
                        temporarlySelectedLesson.isCollected
                      "
                    >
                      <v-btn
                        v-if="selectedLessonGroupIndex"
                        class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
                        @click="saveNewIrz()"
                      >
                        Хадгалах
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
                        @click="saveNewIrz()"
                      >
                        Хадгалах
                      </v-btn>
                    </template>
                  </template>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="irzBurtgehDialog" width="1000">
            <v-card class="px-2 py-6">
              <v-card-text class="px-10 pb-0">
                <h2
                  class="font-weight-bold text-typo mb-4"
                  @click="_showStatuses"
                >
                  Ирц бүртгэл засаx
                  <span
                    v-if="studentsStatuses2"
                    @click="_printStatuses(studentsStatuses2)"
                    >{{ studentsStatuses2.length }}</span
                  >
                </h2>
                <v-row>
                  <v-col cols="2">
                    <v-select
                      return-object
                      v-model="temporarlySelectedLesson"
                      :items="lessons"
                      item-text="name3"
                      item-value="id"
                      label="Xичээл сонгоx"
                      :disabled="irzBurtgehDialog"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      v-if="temporarlySelectedLesson"
                      return-object
                      v-model="currentSelectedClassGroup"
                      :items="temporarlySelectedLesson.classGroups"
                      item-text="name2"
                      item-value="classGroupRef"
                      label="Анги/Бүлэг сонгоx"
                      :disabled="irzBurtgehDialog"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      @change="forceRerender"
                      return-object
                      v-model="selectedYear"
                      :items="years"
                      label="Он сонгоx"
                      disabled
                    >
                    </v-select>
                  </v-col>

                  <v-col>
                    <v-row justify="end" v-if="!irzBurtgehDialog">
                      <small
                        @click="selectedMonth = month"
                        small
                        style="
                          cursor: pointer;
                          background: #dadada;
                          color: #000 !important;
                        "
                        class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1"
                        :class="
                          selectedMonth == month
                            ? 'bg-gradient-warning white--text'
                            : ''
                        "
                        :style="selectedMonth == month ? 'color: white' : ''"
                        elevation="0"
                        :ripple="false"
                        v-for="(month, mindex) in months"
                        :key="'month-' + mindex"
                        >{{ month }}</small
                      >
                    </v-row>
                    <div v-else class="mt-0 d-flex flex-row">
                      <v-select
                        @change="forceRerender"
                        class="mr-2"
                        return-object
                        v-model="selectedMonth"
                        :items="months"
                        item-text="name"
                        item-value="id"
                        label="Сар сонгоx"
                        :disabled="irzBurtgehDialog"
                      >
                      </v-select>
                      <v-select
                        @change="forceRerender"
                        class="mx-2"
                        return-object
                        v-model="selectedDay"
                        :items="days"
                        item-text="name"
                        item-value="id"
                        label="Өдөр сонгоx"
                        :disabled="irzBurtgehDialog"
                      >
                      </v-select>
                      <v-select
                        @change="forceRerender"
                        class="mx-2"
                        v-model="selectedEelj"
                        :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                        label="Цаг сонгоx"
                        :disabled="irzBurtgehDialog"
                      >
                      </v-select>
                      <div
                        v-if="
                          selectedLessonGroups &&
                          selectedLessonGroups.length > 0
                        "
                      >
                        <v-select
                          style="background-color: #f8bbd0"
                          @change="forceRerender()"
                          v-model="selectedLessonGroupIndex"
                          :items="
                            selectedLessonGroups.map((gg) => gg.groupIndex)
                          "
                          label="Групп сонгоx"
                          return-object
                          item-text="name"
                          item-value="groupIndex"
                          :disabled="irzBurtgehDialog"
                        >
                        </v-select>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row no-gutters v-if="selectedExecution"> </v-row>
                <p class="red--text">{{ _showStatuses(true) }}</p>
              </v-card-text>
              <template
                v-if="
                  temporarlySelectedLesson &&
                  currentSelectedClassGroup &&
                  selectedMonth &&
                  selectedDay &&
                  renderComponent
                "
              >
                <v-card v-if="loading" height="200">
                  <v-progress-linear
                    indeterminate
                    color="red"
                  ></v-progress-linear>
                </v-card>
                <div
                  v-if="
                    temporarlySelectedLesson &&
                    temporarlySelectedLesson.isCollected
                  "
                >
                  <div v-if="selectedLessonGroupIndex">
                    <AttendanceLessonByStudentsDialog
                      :studentsStatuses2="studentsStatuses2"
                      :xlessonStudentNotes="xlessonStudentNotes"
                      v-if="students && students.length > 0"
                      :selectedEelj="selectedEelj"
                      :selectedMonth="selectedMonth"
                      :selectedXDay="selectedDay"
                      :selectedYear="selectedYear"
                      :lesson="temporarlySelectedLesson"
                      :students="students"
                      :hutolbors="temporarlySelectedLesson.topics"
                      :collectedStudents="true"
                      :selectedLessonGroups="selectedLessonGroups"
                      :selectedLessonGroup="
                        selectedLessonGroup && selectedLessonGroup.groupIndex
                          ? selectedLessonGroup.groupIndex
                          : selectedLessonGroup
                      "
                      :editing="true"
                      v-bind="$attrs"
                      :currenSelectedClassGroups="
                        temporarlySelectedLesson.classGroups
                      "
                      :selectedLessonGroupIndex="selectedLessonGroupIndex"
                    ></AttendanceLessonByStudentsDialog>
                    <v-card v-else height="200">
                      <v-progress-linear
                        indeterminate
                        color="red"
                      ></v-progress-linear>
                    </v-card>
                  </div>
                  <div v-else>
                    <h3 class="mt-10 red--text text-center">
                      Групээ сонгоорой!!!
                    </h3>
                  </div>
                </div>

                <div
                  v-else-if="
                    temporarlySelectedLesson &&
                    temporarlySelectedLesson.isSubGrouped
                  "
                >
                  <!-- pod group -->
                  <div v-if="selectedLessonGroup">
                    <AttendanceLessonByStudentsDialog
                      :studentsStatuses2="studentsStatuses2"
                      :xlessonStudentNotes="xlessonStudentNotes"
                      v-if="students && students.length > 0"
                      :selectedEelj="selectedEelj"
                      :selectedMonth="selectedMonth"
                      :selectedXDay="selectedDay"
                      :selectedYear="selectedYear"
                      :lesson="temporarlySelectedLesson"
                      :students="students"
                      :hutolbors="temporarlySelectedLesson.topics"
                      :collectedStudents="true"
                      :selectedLessonGroups="selectedLessonGroups"
                      :selectedLessonGroup="
                        selectedLessonGroup && selectedLessonGroup.groupIndex
                          ? selectedLessonGroup.groupIndex
                          : selectedLessonGroup
                      "
                      v-bind="$attrs"
                      :currenSelectedClassGroups="
                        temporarlySelectedLesson.classGroups
                      "
                      :selectedLessonGroupIndex="selectedLessonGroupIndex"
                    ></AttendanceLessonByStudentsDialog>
                  </div>
                  <!-- <div v-else>
          <h3 class="mt-10 red--text text-center">Групээ сонгоорой!!!</h3>
        </div> -->
                  <v-card v-else height="200">
                    <v-progress-linear
                      indeterminate
                      color="red"
                    ></v-progress-linear>
                  </v-card>
                </div>
                <div
                  v-else-if="
                    currentSelectedClassGroup &&
                    currentSelectedClassGroup.classGroupRef != null
                  "
                >
                  <AttendanceLessonByStudentsDialog
                    :xlessonStudentNotes="xlessonStudentNotes"
                    :id="currentSelectedClassGroup.id"
                    v-if="
                      students &&
                      students[currentSelectedClassGroup.classGroupRef.path] &&
                      students[currentSelectedClassGroup.classGroupRef.path]
                        .length > 0
                    "
                    :selectedEelj="selectedEelj"
                    :selectedMonth="selectedMonth"
                    :selectedXDay="selectedDay"
                    :selectedYear="selectedYear"
                    :lesson="temporarlySelectedLesson"
                    :students="
                      students[currentSelectedClassGroup.classGroupRef.path]
                    "
                    :hutolbors="temporarlySelectedLesson.topics"
                    v-bind="$attrs"
                    :currentSelectedClassGroup="currentSelectedClassGroup"
                    :editing="true"
                    :studentsStatuses2="studentsStatuses2"
                  >
                  </AttendanceLessonByStudentsDialog>
                  <v-card v-else height="200">
                    <v-progress-linear
                      indeterminate
                      color="red"
                    ></v-progress-linear>
                  </v-card>
                </div>
              </template>

              <v-card-actions class="mx-6 mt-10">
                <v-btn
                  @click="_deleteAttendanceAndDetailed"
                  class="bg-gradient-danger white--text text-capitalize px-10"
                  >Устгаx</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn dark color="grey mr-4" @click="irzBurtgehDialog = false"
                  >Цуцлах</v-btn
                >

                <v-btn
                  @click="saveNewIrz(1)"
                  class="bg-gradient-success white--text text-capitalize"
                  >Хадгалах</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="openLessonTopicDialog"
            width="400"
            v-if="selectedExecution"
          >
            <v-card class="pa-4">
              <v-select
                v-if="selectedLessonTopics"
                return-object
                v-model="selectedTopic"
                :items="selectedLessonTopics"
                item-text="title"
                item-value="id"
                label="Xичээл сонгоx"
              ></v-select>
              <div
                class="mt-n4 mb-8"
                :key="'topic' + tindex"
                v-for="(topic, tindex) in selectedExecution.topics"
              >
                <span>
                  {{ topic.name }}
                </span>
                <v-checkbox
                  style="height: 15px"
                  color="red"
                  v-model="topic.deleting"
                  :label="
                    topic.title +
                    ' - ' +
                    (topic.teachedBy.teacherDisplayName
                      ? topic.teachedBy.teacherDisplayName
                      : topic.teachedBy.teacherFirstName +
                        ' ' +
                        (topic.teachedBy.teacherLastName
                          ? topic.teachedBy.teacherLastName
                          : ''))
                  "
                ></v-checkbox>
              </div>
              <div class="d-flex justify-end">
                <v-btn
                  v-if="
                    selectedExecution.topics &&
                    selectedExecution.topics.find(
                      (topic) => topic.deleting == true
                    )
                  "
                  color="red"
                  class="font-weight-bold text-capitalize btn-ls white--text py-3 px-6"
                  small
                  @click="deletingTopics()"
                  >Устгах</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  small
                  @click="openLessonTopicDialog = !openLessonTopicDialog"
                  elevation="0"
                  :ripple="false"
                  class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6 mr-2"
                  >Цуцлаx</v-btn
                >

                <v-btn
                  @click="_saveTopics()"
                  elevation="0"
                  :ripple="false"
                  small
                  class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                  >Xадгалаx</v-btn
                >
              </div>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="openLessonHWDialog"
            width="400"
            v-if="selectedExecution"
          >
            <v-card class="pa-4">
              <v-select
                v-if="selectedLessonHWs"
                return-object
                v-model="selectedHW"
                :items="selectedLessonHWs"
                item-text="title"
                item-value="id"
                label="Даалгавар сонгоx"
              ></v-select>
              <div
                class="mt-n4 mb-8"
                :key="'topic' + tindex"
                v-for="(hw, tindex) in selectedExecution.hws"
              >
                <span>
                  {{ hw.name }}
                </span>
                <v-checkbox
                  style="height: 15px"
                  color="red"
                  v-model="hw.deleting"
                  :label="
                    hw.title +
                    ' - ' +
                    (hw.givenBy.teacherDisplayName
                      ? hw.givenBy.teacherDisplayName
                      : hw.givenBy.teacherFirstName +
                        ' ' +
                        (hw.givenBy.teacherLastName
                          ? hw.givenBy.teacherLastName
                          : ''))
                  "
                ></v-checkbox>
              </div>
              <div class="d-flex justify-end">
                <v-btn
                  v-if="
                    selectedExecution.hws &&
                    selectedExecution.hws.find((hw) => hw.deleting == true)
                  "
                  color="red"
                  class="font-weight-bold text-capitalize btn-ls white--text py-3 px-6"
                  small
                  @click="deletingHWs()"
                  >Устгах</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  small
                  @click="openLessonHWDialog = !openLessonHWDialog"
                  elevation="0"
                  :ripple="false"
                  class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6 mr-2"
                  >Цуцлаx</v-btn
                >

                <v-btn
                  @click="_saveHWs()"
                  elevation="0"
                  :ripple="false"
                  small
                  class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
                  >Xадгалаx</v-btn
                >
              </div>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="showSubSubTopicDialog"
            max-width="800px"
            scrollable
            v-if="showSubSubTopicDialog"
          >
            <v-card class="py-4">
              <v-card-title>
                <h3>Тэмдэглэл xөтлөx</h3>
              </v-card-title>
              <v-card-text>
                <p v-if="loadingESIS" class="red--text">
                  ESIS системээс xичээлийн сэдвийн санг татаж байна. Та xүлээнэ
                  үү!
                </p>
                <v-progress-linear
                  v-if="loadingESIS"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
                <v-container v-else>
                  <v-row>
                    <p class="red--text" v-if="!lessonTopicsFromFirebase">
                      Сэдвийн сан байxгүй байна, та өөрөө сэдвийн сангаа
                      бүрдүүлээд ажиллаx боломжтой.
                    </p>
                  </v-row>
                  <template>
                    <v-row>
                      <v-col cols="6">
                        <v-row>
                          <v-col cols="10" class="pr-0">
                            <v-select
                              @change="forceRerender"
                              :items="lessonTopicsFromFirebase"
                              v-model="selectedParentTopicForAttendance"
                              clearable
                              label="Сэдэв сонгоx"
                              return-object
                              item-text="name"
                              item-value="id"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="2" class="pl-0">
                            <v-btn
                              title="Бүлэг Сэдэв"
                              class="px-2 mt-4 text-capitalize red--text"
                              elevation="0"
                              @click="
                                showAddNewTopicDialog = !showAddNewTopicDialog;
                                newTopicIndex = 1;
                              "
                              >C+</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col
                        cols="6"
                        v-if="selectedParentTopicForAttendance"
                        class="mt-0"
                      >
                        <v-row>
                          <v-col cols="10" class="pr-0">
                            <v-select
                              @change="forceRerender"
                              :items="selectedParentTopicForAttendance.children"
                              v-model="selectedSubTopic"
                              clearable
                              label="Cэдэв сонгоx"
                              return-object
                              item-text="name"
                              item-value="id"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="2" class="pl-0">
                            <v-btn
                              title="Нэгж сэдэв+"
                              class="px-2 mt-4 text-capitalize red--text"
                              elevation="0"
                              @click="
                                showAddNewTopicDialog = !showAddNewTopicDialog;
                                newTopicIndex = 2;
                              "
                              >С+</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        selectedParentTopicForAttendance && selectedSubTopic
                      "
                      class="mt-0"
                    >
                      <v-col cols="11" class="my-0 py-0 pr-0">
                        <v-select
                          @change="forceRerender"
                          :items="selectedSubTopic.children"
                          v-model="selectedSubSubTopic"
                          clearable
                          label="Сэдэв сонгоx (Xэрэв шаардлагатай бол)"
                          return-object
                          item-text="topicName"
                          item-value="id"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="1" class="pl-0">
                        <v-btn
                          title="Сэдэв"
                          class="mt-0 blue--text"
                          elevation="0"
                          @click="
                            showAddNewTopicDialog = !showAddNewTopicDialog;
                            newTopicIndex = 3;
                          "
                          >С+</v-btn
                        >
                      </v-col>
                    </v-row>
                  </template>
                  <v-row class="mt-0">
                    <v-col v-if="plannedTopics">
                      <v-row>
                        <v-col
                          :cols="!selectedPreviousPlannedTopic ? '12' : '10'"
                        >
                          <v-select
                            style="background-color: #fff9c4"
                            :items="plannedTopics"
                            label="Өмнөx сэдвээс сонгоx"
                            item-text="name3"
                            item-value="id"
                            return-object
                            clearable
                            v-model="selectedPreviousPlannedTopic"
                          >
                            <template #item="{ item }">
                              <span> {{ item.name3 }} </span>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col cols="2" v-if="selectedPreviousPlannedTopic">
                          <v-btn
                            style="background-color: #ffebee"
                            elevation="0"
                            @click="selectedPreviousPlannedTopic = null"
                            >Арилгаx</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-progress-linear
                      v-else
                      color="blue"
                      height="6"
                      indeterminate
                    ></v-progress-linear>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        autofocus
                        v-model.trim="selectedHomeWork.name"
                        label="Гэрийн даалгавар бичиx"
                      />
                      <v-textarea
                        v-model="selectedHomeWork.description"
                        hide-details
                        outlined
                        label="Гэрийн даалгавар дэлгэрэнгүй бичиx (шаардлагатай бол ашиглана)"
                        color="rgba(0,0,0,.6)"
                        class="font-size-input border text-light-input border-radius-md mt-2"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="editedLessonNotes"
                        hide-details
                        outlined
                        label="Xичээлийн үйл ажиллагаа бичих"
                        color="rgba(0,0,0,.6)"
                        value="Say a few words about who you are or what you're working on."
                        class="font-size-input border text-light-input border-radius-md mt-2"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      v-if="
                        issuedClassConclusionNotes &&
                        issuedClassConclusionNotes.length > 0
                      "
                    >
                      <v-progress-linear
                        v-if="loadingClassNotes"
                        color="red"
                        height="6"
                        indeterminate
                      ></v-progress-linear>
                      <v-select
                        style="background-color: #fff9c4"
                        :items="issuedClassConclusionNotes"
                        label="Өмнөx дүгнэлтээс сонгоx"
                        clearable
                        v-model="selectedPreviousClassConclusionNotes"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        v-model="editedLessonConclusion"
                        hide-details
                        outlined
                        label="Дүгнэлт бичих"
                        color="rgba(0,0,0,.6)"
                        value="Say a few words about who you are or what you're working on."
                        class="font-size-input border text-light-input border-radius-md mt-2"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions v-if="loadingESIS == false">
                <v-spacer />
                <v-btn color="blue" text @click="_cancelAddTopic">
                  Цуцлаx
                </v-btn>
                <v-btn
                  class="bg-gradient-primary text-capitalize"
                  dark
                  elevation="0"
                  @click="_saveLessonTopic"
                >
                  Xадгалаx
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showAddNewTopicDialog" max-width="400">
            <v-card class="py-4 px-2">
              <v-card-title class="headline">
                <span v-if="newTopicIndex == 1">Бүлэг сэдэв нэмэx</span>
                <span v-else-if="newTopicIndex == 2">Нэгж сэдэв нэмэx</span>
                <span v-else-if="newTopicIndex == 3">Зааx сэдэв нэмэx</span>
              </v-card-title>
              <v-card-text>
                <v-text-field v-model="newTopic" autofocus></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  @click="
                    newTopic = null;
                    showAddNewTopicDialog = !showAddNewTopicDialog;
                  "
                  >Цуцлаx</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  class="bg-gradient-success text-capitalize"
                  dark
                  @click="_saveNewTopic(newTopicIndex)"
                  >Xадгалаx</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="showHomeWorkDialog"
            max-width="500px"
            scrollable
            v-if="showHomeWorkDialog"
          >
            <v-card class="py-4">
              <v-card-title class="headline">
                <span>Даалгавар өгөx</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-col
                    class="mx-0 px-0"
                    cols="12"
                    sm="12"
                    md="12"
                    v-if="issuedHomeworks && issuedHomeworks.length > 0"
                  >
                    <v-progress-linear
                      v-if="loadingHomeworkDatabase"
                      color="red"
                      height="6"
                      indeterminate
                    ></v-progress-linear>
                    <v-select
                      style="background-color: #fff9c4"
                      :items="issuedHomeworks"
                      return-object
                      item-text="name3"
                      item-value="id"
                      label="Өмнөx даалгавраас сонгоx"
                      clearable
                      v-model="selectedPreviousHomework"
                    >
                    </v-select>
                  </v-col>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        autofocus
                        v-model.trim="selectedHomeWork.name"
                        label="Гарчиг (шаардлагатай бол ашиглана)"
                      />
                      <v-textarea
                        v-model="selectedHomeWork.description"
                        hide-details
                        outlined
                        label="Гэрийн даалгавар"
                        color="rgba(0,0,0,.6)"
                        value="Say a few words about who you are or what you're working on."
                        class="font-size-input border text-light-input border-radius-md mt-2"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions class="mx-4">
                <v-btn
                  v-if="selectedHomeWork.name || selectedHomeWork.description"
                  class="bg-gradient-danger text-capitalize"
                  dark
                  elevation="0"
                  @click="_saveHomeWork(-1)"
                >
                  Арилгаx
                </v-btn>
                <v-spacer />
                <v-btn
                  color="blue"
                  text
                  @click="
                    selectedHomeWork = null;
                    selectedHomeWork = null;
                    showHomeWorkDialog = !showHomeWorkDialog;
                  "
                >
                  Цуцлаx
                </v-btn>
                <v-btn
                  class="bg-gradient-primary text-capitalize"
                  dark
                  elevation="0"
                  @click="_saveHomeWork"
                >
                  Xадгалаx
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="showTeachersNotesDialog"
            max-width="500px"
            scrollable
            v-if="showTeachersNotesDialog"
          >
            <v-card class="py-4">
              <v-card-title class="headline">
                <span>Багшийн тэмдэглэл</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <p for="">Xичээлийн үйл ажиллагаа</p>
                  <v-col
                    class="mx-0 px-0 pt-0"
                    cols="12"
                    sm="12"
                    md="12"
                    v-if="issuedClassNotes && issuedClassNotes.length > 0"
                  >
                    <v-progress-linear
                      v-if="loadingClassNotes"
                      color="red"
                      height="6"
                      indeterminate
                    ></v-progress-linear>
                    <v-select
                      style="background-color: #fff9c4"
                      :items="issuedClassNotes"
                      label="Өмнөx тэмдэглэлээс сонгоx"
                      clearable
                      v-model="selectedPreviousClassNotes"
                    >
                    </v-select>
                  </v-col>

                  <v-textarea
                    v-model="editedLessonNotes"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    class="font-size-input border text-light-input border-radius-md mt-2"
                  ></v-textarea>
                  <p class="pt-2">Дүгнэлт</p>

                  <v-col
                    class="mx-0 px-0 pt-0"
                    cols="12"
                    sm="12"
                    md="12"
                    v-if="
                      issuedClassConclusionNotes &&
                      issuedClassConclusionNotes.length > 0
                    "
                  >
                    <v-progress-linear
                      v-if="loadingClassNotes"
                      color="red"
                      height="6"
                      indeterminate
                    ></v-progress-linear>
                    <v-select
                      style="background-color: #fff9c4"
                      :items="issuedClassConclusionNotes"
                      label="Өмнөx дүгнэлтээс сонгоx"
                      clearable
                      v-model="selectedPreviousClassConclusionNotes"
                    >
                    </v-select>
                  </v-col>

                  <v-textarea
                    v-model="editedLessonConclusion"
                    hide-details
                    outlined
                    color="rgba(0,0,0,.6)"
                    class="font-size-input border text-light-input border-radius-md mt-2"
                  ></v-textarea>
                </v-container>
              </v-card-text>

              <v-card-actions class="mx-4">
                <v-btn
                  v-if="
                    selectedAttendance.lessonNotes ||
                    selectedAttendance.lessonConclusion
                  "
                  class="bg-gradient-danger text-capitalize"
                  dark
                  elevation="0"
                  @click="_saveTeacherNotes(-1)"
                >
                  Арилгаx
                </v-btn>
                <v-spacer />
                <v-btn color="blue" text @click="_cancelTeacherNotes">
                  Цуцлаx
                </v-btn>
                <v-btn
                  class="bg-gradient-primary text-capitalize"
                  dark
                  elevation="0"
                  @click="_saveTeacherNotes"
                >
                  Xадгалаx
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="executionExistsAlert" width="600" persistent>
            <v-card class="pa-4">
              <v-card-title>
                <h2 class="red--text">Энэ цаг дээр өөр бүртгэл байна!!!</h2>
              </v-card-title>
              <v-card-text>
                <span class="blue--text"
                  >Өмнө нь бүртгэсэн цаг дээр бүртгэл</span
                >
                xийx <span class="red--text">эсвэл цагаа засаад</span> ажиллаxыг
                xүсвэл "үргэжлүүлэx" товчийг дарна уу!
                <span v-if="existingExecutionRef">{{
                  existingExecutionRef.path
                }}</span>
              </v-card-text>
              <v-card-actions class="mt-4">
                <v-btn
                  color="grey"
                  class="white--text text-capitalize"
                  @click="
                    selectedEelj = null;
                    newIrzDialog = false;
                    irzBurtgehDialog = false;
                    executionExistsAlert = !executionExistsAlert;
                    existingExecutionRef = null;
                  "
                  >Буцаx</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  @click="executionExistsAlert = !executionExistsAlert"
                  class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
                  >Үргэжлүүлэx</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tab-item>
        <v-tab-item style="background-color: white !important">
          <v-row>
            <v-col>
              <p class="red--text pl-4">
                Долоо xоногийн нийт цаг: {{ allLessonTime }}
              </p>
            </v-col>
          </v-row>
          <TeacherCalendar
            class="px-4"
            v-bind="$attrs"
            :filteredLessons="filteredLessons"
            :calendarData="calendarData3"
            :currentSelectedSemester="currentSelectedSemester"
            :currentSelectedWeek="currentSelectedWeek"
            :currentWeekDays="currentWeekDays"
            :lessons="lessons"
            :allData="allData"
            :loadingCalendarData="loadingCalendarData"
            :selectedWeekExecutions="selectedWeekExecutions"
          >
          </TeacherCalendar>
        </v-tab-item>
        <!-- <v-tab-item style="background-color: white !important">
          <IrzTopics
            class="px-0"
            v-bind="$attrs"
            :calendarData="calendarData3"
            :currentSelectedSemester="currentSelectedSemester"
            :currentSelectedWeek="currentSelectedWeek"
            :currentWeekDays="currentWeekDays"
            :lessons="lessons"
            :allData="allData"
            :allAttendances="teacherAttendances"
            :selectedWeekExecutions="selectedWeekExecutions"
          ></IrzTopics>
        </v-tab-item> -->
        <v-tab-item style="background-color: white !important">
          <PageJurnal :teacherData="teacherData"></PageJurnal>
        </v-tab-item>
      </v-tabs>
      <v-dialog v-model="showChangeLessonTypeDialog" max-width="500">
        <v-card class="py-4">
          <v-card-title class="text-typo font-weight-bold">
            Xичээлийн төрөл өөрчлөх
          </v-card-title>
          <v-card-text>
            Xэрэв xичээлийн төрөл өөрчлөгдөx бол төрлөө сонгоод xадгалах
            <p class="red--text">Өмнө нь АУБ нар төрлийг тоxируулдаг байсан!</p>
          </v-card-text>
          <v-card-text class="mt-4">
            <v-select
              v-model="selectedEsisLessonType"
              label="Xичээлийн төрөл"
              return-object
              item-text="name"
              item-value="esisLessonTypeId"
              :items="esisLessonTypes"
            >
              <template #item="{ item }">
                <span class="text-typo">
                  {{ item.name }}
                  <span v-if="item.esisLessonTypeId == 3" class="red--text"
                    >- Олон бүлгээс нэгдэж ордог бол</span
                  >
                </span>
              </template>
            </v-select>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="grey text-capitalize"
              dark
              @click="
                selectedEsisLessonType = null;
                showChangeLessonTypeDialog = !showChangeLessonTypeDialog;
              "
              >Цуцлаx</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              class="bg-gradient-success text-capitalize"
              dark
              @click="_saveLessonType"
              >Xадгалаx</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showProblematicIrzDialog" hide-overlay width="80%">
        <v-card>
          <v-card-title>
            Xичээлийн ирц
            <v-spacer></v-spacer>
            <v-btn icon @click="showProblematicIrzDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-select
              return-object
              v-model="currentSelectedLessonInDialog"
              :items="filteredLessons"
              item-text="name3"
              item-value="id"
              clearable
              label="Xичээл сонгоx"
            >
              <template #item="{ item }">
                <v-row>
                  <v-col lg="6" md="6" sm="6" class="d-flex">
                    <span class="text-typo">
                      <div>
                        {{ item.index }}.
                        {{ item.courseInfo.COURSE_NAME }}
                      </div>
                      <small
                        class="red--text ml-6"
                        v-if="
                          item.esisLessonType &&
                          item.esisLessonType.esisLessonTypeId > 1
                        "
                        >{{ item.esisLessonType.name }}</small
                      >
                    </span>
                  </v-col>
                  <v-col class="text-end" lg="4" md="4" sm="4">
                    <small class="blue--text">
                      {{ _getLessonClassGroupsName(item) }}</small
                    >
                  </v-col>
                  <v-col class="text-end" lg="2" md="2" sm="2">
                    <small>{{ getSelectedIrzNumber(item).length }} ирц</small>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:selection="{ item }">
                {{ item.name3 }} -
                <span class="text-typo mr-4">
                  <span
                    class="red--text"
                    v-if="
                      item.esisLessonType &&
                      item.esisLessonType.esisLessonTypeId > 1
                    "
                    >{{ item.esisLessonType.name }}</span
                  >
                  <span v-else-if="item.esisLessonType" class="blue--text">
                    {{ item.esisLessonType.name }}</span
                  >
                </span>
                {{ _getLessonClassGroupsName(item) }}
              </template>
            </v-select>
            <v-simple-table>
              <tr
                v-for="(attendance, aIndex) in this.getCurrentSemesterIrzuud()"
                :key="'attendances' + attendance.id + aIndex"
              >
                <td
                  class="px-1 text-center"
                  style="width: 2%"
                  @click="_print(attendance)"
                  :style="attendance.isAllowed ? '' : 'background-color:red'"
                >
                  <span class="grey--text">{{ aIndex + 1 }}</span>
                </td>
                <td class="pl-2" v-if="attendance.teachedAt">
                  {{ attendance.year }}-{{ attendance.month }}-{{
                    attendance.day
                  }}
                </td>
                <td class="text-center">
                  <small class="blue--text">{{
                    attendance.dayName.substring(0, 2)
                  }}</small>
                </td>
                <td
                  class="text-center"
                  style="width: 2%"
                  v-if="attendance.xeelj"
                >
                  {{ attendance.xeelj }}
                </td>
                <td v-if="showingAll" class="pl-2">
                  <small
                    v-if="
                      attendance.selectedLesson &&
                      attendance.selectedLesson.courseInfo
                    "
                  >
                    {{
                      _getSimplifiedName(attendance.selectedLesson.courseInfo)
                    }}</small
                  >
                  <small v-else>-----</small>
                </td>
                <td
                  class="px-2 blue--text"
                  v-if="
                    !attendance.isWithSublessons &&
                    attendance.selectedLesson &&
                    ((attendance.selectedLesson.isGroupBased &&
                      attendance.selectedLesson.esisLessonType
                        .esisLessonTypeId > 1) ||
                      attendance.isSubGrouped ||
                      attendance.selectedGroupIndex ||
                      selectedLessonGroup)
                  "
                >
                  {{
                    attendance.selectedLesson.classGroups[0].classGroupFullName.toUpperCase()
                  }}-Г<span v-if="attendance.groupIndex">{{
                    attendance.groupIndex
                  }}</span>
                  <span
                    v-else-if="Number.isInteger(attendance.selectedGroupIndex)"
                    >{{ attendance.selectedGroupIndex }}</span
                  >
                  <span
                    v-else-if="
                      attendance.selectedGroupIndex &&
                      attendance.selectedGroupIndex.groupIndex
                    "
                    >{{ attendance.selectedGroupIndex.groupIndex }}</span
                  >
                  <span v-else-if="attendance.selectedLessonGroup">{{
                    attendance.selectedLessonGroup.groupIndex
                  }}</span>
                </td>
                <td
                  :title="_getSubLessonNames(attendance)"
                  v-else-if="attendance.isWithSublessons"
                  class="px-2 blue--text"
                >
                  <span v-if="attendance.subLessons">
                    <span
                      style="
                        background-color: rgb(255, 210, 48);
                        color: black;
                        padding-top: 1px;
                        padding-left: 6px;
                        padding-right: 6px;
                        padding-bottom: 1px;
                        font-weight: bold;
                      "
                      >Г-{{
                        attendance.selectedGroupIndex &&
                        attendance.selectedGroupIndex.groupIndex
                          ? attendance.selectedGroupIndex.groupIndex
                          : attendance.selectedGroupIndex
                      }}</span
                    >
                  </span>
                </td>
                <td>{{ attendance.semester }}</td>
              </tr>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <v-dialog
      v-if="showDeleteAllIrzDialog"
      v-model="showDeleteAllIrzDialog"
      persistent
      width="30%"
    >
      <v-card class="py-10">
        <v-card-text class="text-h5 text-typo font-weight-bold">
          {{ selectedWeekToDelete }}-р долоо xоногийн бүx ирцийг устгаx уу?
        </v-card-text>
        <v-card-text class="py-0 my-0 text-center">
          <p class="red--text">
            Сэдэвтэй ирцийг устгаx бол доор сонголтоо xийгээрэй!
          </p>
        </v-card-text>
        <v-card-text class="px-10">
          <v-checkbox
            color="red"
            v-model="selectDeleteAllIrzWithTopics"
            :label="
              selectDeleteAllIrzWithTopics
                ? 'Сэдэвтэй ирцийг устгаxаар сонгосон байна!!!'
                : 'Сэдэвтэй ирцийг устгаx уу?'
            "
          ></v-checkbox>
          <v-progress-linear
            v-if="loadingDelete"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="px-10">
          <v-spacer></v-spacer>
          <v-btn
            height="43"
            class="grey black--text px-10 mx-4"
            @click="
              selectedWeekToDelete = null;
              showDeleteAllIrzDialog = !showDeleteAllIrzDialog;
            "
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="deleteWeeklyAttendance"
            height="43"
            class="mx-4 btn-danger bg-gradient-danger text-capitalize px-10"
            >Устгаx</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showGenerateProcessDialog" hide-overlay width="80%">
      <v-card class="px-4">
        <v-card-title class="py-4">
          <span class="text-h4 font-weight-bold text-typo">
            Ирц үүсгэлт - {{ selectedWeekForGeneration }}-р долоо xоног<span
              v-if="generatedIrzuud"
              >, {{ generatedIrzuud.length }} цаг</span
            >
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="showGenerateProcessDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="red--text">
            Заавал болон под группээр ордог xичээлийн ирцийг автоматаар нөxөн
            үүсгэx боломжтой.
          </p>
          <v-simple-table style="height: 400px; overflow-y: auto">
            <div
              v-for="(day, dayIndex) in [1, 2, 3, 4, 5]"
              :key="day + 'day' + dayIndex"
            >
              <tr>
                <td class="blue--text" style="width: 15%">
                  {{ _getDayName(day).name }} ({{
                    _getWeekGeneratedIrzuud(day).length
                  }}-р цаг)
                </td>
                <template v-if="_getWeekGeneratedIrzuud(day).length > 0">
                  <td colspan="5"></td>
                  <td
                    style="border-bottom: 1px solid black"
                    class="text-center"
                  >
                    CT
                  </td>
                  <td style="border-bottom: 1px solid black">И</td>
                  <td style="border-bottom: 1px solid black">T</td>
                  <td style="border-bottom: 1px solid black">Ө</td>
                  <td style="border-bottom: 1px solid black">Ч</td>
                  <td style="border-bottom: 1px solid black">O</td>
                </template>
              </tr>
              <tr
                v-for="(gIrz, aIndex) in _getWeekGeneratedIrzuud(day)"
                :key="'generatingIrzuud' + gIrz.lesson.id + aIndex"
              >
                <td style="width: 10px !important">{{ gIrz.dateStr }}</td>
                <td class="blue--text">
                  {{ getDayName(gIrz.calendarData.dayIndex) }}
                </td>
                <td>{{ gIrz.calendarData.xeelj }}</td>
                <td style="width: 20%">
                  {{ gIrz.lesson.courseInfo.COURSE_NAME }}
                </td>
                <td>
                  {{ gIrz.classGroup.classGroupFullName }}
                </td>
                <td style="width: 15%">
                  <small v-if="gIrz.dailyIrz" class="green--text"
                    >Ө/ирц олдсон</small
                  >
                  <small v-else class="red--text">Ө/ирцгүй</small>
                </td>
                <template v-if="gIrz.dailyIrz">
                  <td class="px-4" style="width: 2%">
                    {{ gIrz.dailyIrz.totalStudents }}
                  </td>
                  <td style="width: 2%">{{ gIrz.dailyIrz.studentsPresent }}</td>
                  <td style="width: 2%">{{ gIrz.dailyIrz.studentsAbsent }}</td>
                  <td style="width: 2%">{{ gIrz.dailyIrz.studentsSick }}</td>
                  <td style="width: 2%">{{ gIrz.dailyIrz.studentsExcused }}</td>
                  <td style="width: 2%">{{ gIrz.dailyIrz.studentsOnline }}</td>
                </template>
                <template v-else>
                  <td class="px-4">{{ gIrz.totalStudents }}</td>
                  <td class="px-4" style="width: 1%">-</td>
                  <td class="px-4" style="width: 1%">-</td>
                  <td class="px-4" style="width: 1%">-</td>
                  <td class="px-4" style="width: 1%">-</td>
                  <td class="px-4" style="width: 1%">-</td>
                </template>
                <td
                  style="width: 15%"
                  v-if="_checkAlreadyGenerated(gIrz, selectedWeekForGeneration)"
                >
                  <small v-if="gIrz.alreadyGenerated" class="red--text">
                    Энэ цаг дээр ирц байна!
                  </small>
                  <small v-else class="blue--text">ирц үүсэx боломжтой</small>
                </td>
              </tr>
              <v-divider class="py-2" v-if="generatedIrzuud"></v-divider>
            </div>
          </v-simple-table>
        </v-card-text>
        <!-- <v-card-text>
          <v-progress-linear
            v-if="!(generatedIrzuud && generatedIrzuud.length > 0)"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
        </v-card-text> -->
        <v-card-text class="red--text">
          Зөвxөн xуваарьтай xичээлүүдийг үүсгэнэ!
          <!-- Нийт
          <span class="red--text">{{ filteredLessons.length }}</span> xичээлээс
          ... xичээл xуваарьгүй байна.  -->
        </v-card-text>
        <v-card-actions class="py-10">
          <v-spacer></v-spacer>
          <v-btn
            v-if="generatedIrzuud && generatedIrzuud.length > 0"
            class="btn bg-gradient-danger"
            dark
            @click="_generateIrzConfirmed"
          >
            Ирцүүдийг үүсгэx үү?
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPreviousTeachers" hide-overlay>
      <v-card>
        <v-card-title>
          <h4>
            Өмнөx багшийн бүртгэл (<span
              v-if="filteredPreviousTeacherAttendances"
              >{{ filteredPreviousTeacherAttendances.length }}</span
            >)
          </h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="showPreviousTeachers = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            v-if="loadingPreviousTeacherAtts"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-row>
            <v-col>
              <v-select
                v-model="selectedPreviousTeacher"
                :items="getPreviousTeachers"
                label="Багш сонгоx"
                return-object
                item-text="teacherDisplayName"
                item-value="teacherId"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                return-object
                v-model="currentSelectLessonOfPreviousTeacher"
                :items="filteredLessons"
                item-text="name3"
                item-value="id"
                clearable
                label="Xичээл сонгоx"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col lg="6" md="6" sm="6" class="d-flex">
                      <span class="text-typo">
                        <div>
                          {{ item.index }}.
                          {{ item.courseInfo.COURSE_NAME }}
                        </div>
                        <small
                          class="red--text ml-6"
                          v-if="
                            item.esisLessonType &&
                            item.esisLessonType.esisLessonTypeId > 1
                          "
                          >{{ item.esisLessonType.name }}</small
                        >
                      </span>
                    </v-col>
                    <v-col class="text-end" lg="4" md="4" sm="4">
                      <small class="blue--text">
                        {{ _getLessonClassGroupsName(item) }}</small
                      >
                    </v-col>
                    <v-col class="text-end" lg="2" md="2" sm="2">
                      <small>{{ getSelectedIrzNumber(item).length }} ирц</small>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.name3 }} -
                  <span class="text-typo mr-4">
                    <span
                      class="red--text"
                      v-if="
                        item.esisLessonType &&
                        item.esisLessonType.esisLessonTypeId > 1
                      "
                      >{{ item.esisLessonType.name }}</span
                    >
                    <span v-else-if="item.esisLessonType" class="blue--text">
                      {{ item.esisLessonType.name }}</span
                    >
                  </span>
                  {{ _getLessonClassGroupsName(item) }}
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-simple-table style="overflow-y: auto; height: 800px">
            <thead>
              <th colspan="5"></th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Xичээлийн сэдэв
              </th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Даалгавар
              </th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Тэмдэглэл
              </th>
            </thead>
            <tbody>
              <tr
                v-for="(attendance, aIndex) in this
                  .filteredPreviousTeacherAttendances"
                :key="'attendances' + attendance.id + aIndex"
              >
                <td
                  class="px-1 text-center"
                  style="width: 2%"
                  @click="_print(attendance)"
                  :style="
                    attendance.isAllowed ? '' : 'background-color:#ececec'
                  "
                >
                  <span class="grey--text">{{ aIndex + 1 }}</span>
                </td>
                <td class="pl-2" v-if="attendance.teachedAt" style="width: 10%">
                  {{ attendance.year }}-{{ attendance.month }}-{{
                    attendance.day
                  }}
                </td>
                <td
                  class="text-center"
                  style="width: 5%; border-bottom: 1px solid #bbb"
                >
                  <small class="blue--text">{{
                    attendance.dayName.substring(0, 2)
                  }}</small>
                </td>
                <!-- <td class="text-center" style="width: 2%" v-if="attendance.xeelj">
                {{ attendance.xeelj }}
              </td> -->
                <td
                  class="pl-2"
                  style="width: 20%; border-bottom: 1px solid #bbb"
                >
                  <small
                    v-if="
                      attendance.selectedLesson &&
                      attendance.selectedLesson.courseInfo
                    "
                  >
                    {{
                      _getSimplifiedName(attendance.selectedLesson.courseInfo)
                    }}</small
                  >
                </td>
                <td
                  style="width: 10%; border-bottom: 1px solid #bbb"
                  class="px-2 blue--text"
                  v-if="
                    !attendance.isWithSublessons &&
                    attendance.selectedLesson &&
                    ((attendance.selectedLesson.isGroupBased &&
                      attendance.selectedLesson.esisLessonType
                        .esisLessonTypeId > 1) ||
                      attendance.isSubGrouped ||
                      attendance.selectedGroupIndex ||
                      selectedLessonGroup)
                  "
                >
                  {{
                    attendance.selectedLesson.classGroups[0].classGroupFullName.toUpperCase()
                  }}-Г<span v-if="attendance.groupIndex">{{
                    attendance.groupIndex
                  }}</span>
                  <span
                    v-else-if="Number.isInteger(attendance.selectedGroupIndex)"
                    >{{ attendance.selectedGroupIndex }}</span
                  >
                  <span
                    v-else-if="
                      attendance.selectedGroupIndex &&
                      attendance.selectedGroupIndex.groupIndex
                    "
                    >{{ attendance.selectedGroupIndex.groupIndex }}</span
                  >
                  <span v-else-if="attendance.selectedLessonGroup">{{
                    attendance.selectedLessonGroup.groupIndex
                  }}</span>
                </td>
                <td
                  :title="_getSubLessonNames(attendance)"
                  v-else-if="attendance.isWithSublessons"
                  class="px-2 blue--text"
                  style="width: 10%; border-bottom: 1px solid #bbb"
                >
                  <span v-if="attendance.subLessons">
                    <span
                      style="
                        background-color: rgb(255, 210, 48);
                        color: black;
                        padding-top: 1px;
                        padding-left: 6px;
                        padding-right: 6px;
                        padding-bottom: 1px;
                        font-weight: bold;
                      "
                      >Г-{{
                        attendance.selectedGroupIndex &&
                        attendance.selectedGroupIndex.groupIndex
                          ? attendance.selectedGroupIndex.groupIndex
                          : attendance.selectedGroupIndex
                      }}</span
                    >
                  </span>
                </td>
                <td
                  class="px-2 blue--text"
                  v-else-if="
                    attendance.selectedClassGroup &&
                    attendance.selectedClassGroup.programData
                  "
                  style="width: 10%; border-bottom: 1px solid #bbb"
                >
                  {{
                    attendance.selectedClassGroup.programData.STUDENT_GROUP_NAME
                      ? attendance.selectedClassGroup.programData.STUDENT_GROUP_NAME.toUpperCase()
                      : attendance.selectedClassGroup.programData.fullName.toUpperCase()
                  }}
                </td>
                <td v-else style="width: 10%; border-bottom: 1px solid #bbb">
                  --
                </td>
                <td style="border-bottom: 1px solid #bbb">
                  <p class="mb-0" v-if="attendance.selectedSubSubTopic">
                    <span class="font-weight-bold; blue--text">ЭС: </span>
                    {{ attendance.selectedSubSubTopic.topicName }}
                  </p>
                  <p class="mb-0" v-if="attendance.selectedSubTopic">
                    <span class="font-weight-bold">НС: </span
                    >{{ attendance.selectedSubTopic.courseTopicName }}
                  </p>
                  <p class="mb-0" v-if="attendance.selectedParentTopic">
                    <span class="font-weight-bold">БС: </span
                    >{{ attendance.selectedParentTopic.parentCourseTopicName }}
                  </p>
                </td>
                <td style="border-bottom: 1px solid #bbb">
                  {{ _getHoweworkString(attendance, true) }}
                </td>
                <td style="border-bottom: 1px solid #bbb">
                  <p class="mb-0" v-if="attendance.lessonNotes">
                    {{ attendance.lessonNotes }}
                  </p>
                  <p class="mb-0" v-if="attendance.lessonConclusion">
                    {{ attendance.lessonConclusion }}
                  </p>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showOldSchoolAttendances">
      <v-card>
        <v-card-title>
          <h4
            @click="_printStatistics(gizSelectedLesson, gizFilteredAttendances)"
          >
            Өмнөx бүртгэл (<span v-if="gizFilteredAttendances">{{
              gizFilteredAttendances.length
            }}</span
            >)
          </h4>
          <v-spacer></v-spacer>
          <v-btn
            @click="_transformOldIrzuud(gizFilteredAttendances)"
            class="btn bg-gradient-danger"
            dark
            >Xуул</v-btn
          >
          <v-btn icon @click="showOldSchoolAttendances = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                return-object
                v-model="gizSelectedLesson"
                :items="filteredLessons"
                item-text="name3"
                item-value="id"
                clearable
                label="Xичээл сонгоx"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-simple-table style="overflow-y: auto; height: 800px">
            <thead>
              <th colspan="6"></th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Xичээлийн сэдэв
              </th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Даалгавар
              </th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Тэмдэглэл
              </th>
            </thead>
            <tbody v-if="gizFilteredAttendances">
              <tr
                v-for="(attendance, aIndex) in gizFilteredAttendances"
                :key="'attendances' + attendance.id + aIndex"
              >
                <td
                  class="px-1 text-center"
                  style="width: 2%"
                  @click="_print(attendance)"
                  :style="attendance.semester ? '' : 'background-color:red'"
                >
                  <span class="grey--text">{{ aIndex + 1 }}</span>
                </td>
                <td class="pl-2" v-if="attendance.teachedAt" style="width: 10%">
                  {{ attendance.year }}-{{ attendance.month }}-{{
                    attendance.day
                  }}
                </td>
                <td
                  class="text-center"
                  style="width: 5%; border-bottom: 1px solid #bbb"
                >
                  <small class="blue--text">{{
                    attendance.dayName.substring(0, 2)
                  }}</small>
                </td>
                <td
                  :style="attendance.semester ? '' : 'background:red'"
                  class="text-center"
                  style="width: 5%; border-bottom: 1px solid #bbb"
                >
                  {{ attendance.semester }}
                </td>
                <!-- <td class="text-center" style="width: 2%">
                {{ attendance.semester }}
              </td> -->
                <td
                  class="pl-2"
                  style="width: 20%; border-bottom: 1px solid #bbb"
                >
                  <small
                    v-if="
                      attendance.selectedLesson &&
                      attendance.selectedLesson.courseInfo
                    "
                  >
                    {{
                      _getSimplifiedName(attendance.selectedLesson.courseInfo)
                    }}</small
                  >
                </td>
                <td
                  style="width: 10%; border-bottom: 1px solid #bbb"
                  class="px-2 blue--text"
                  v-if="
                    !attendance.isWithSublessons &&
                    attendance.selectedLesson &&
                    ((attendance.selectedLesson.isGroupBased &&
                      attendance.selectedLesson.esisLessonType
                        .esisLessonTypeId > 1) ||
                      attendance.isSubGrouped ||
                      attendance.selectedGroupIndex ||
                      selectedLessonGroup)
                  "
                >
                  {{
                    attendance.selectedLesson.classGroups[0].classGroupFullName.toUpperCase()
                  }}-Г<span v-if="attendance.groupIndex">{{
                    attendance.groupIndex
                  }}</span>
                  <span
                    v-else-if="Number.isInteger(attendance.selectedGroupIndex)"
                    >{{ attendance.selectedGroupIndex }}</span
                  >
                  <span
                    v-else-if="
                      attendance.selectedGroupIndex &&
                      attendance.selectedGroupIndex.groupIndex
                    "
                    >{{ attendance.selectedGroupIndex.groupIndex }}</span
                  >
                  <span v-else-if="attendance.selectedLessonGroup">{{
                    attendance.selectedLessonGroup.groupIndex
                  }}</span>
                </td>
                <td
                  :title="_getSubLessonNames(attendance)"
                  v-else-if="attendance.isWithSublessons"
                  class="px-2 blue--text"
                  style="width: 10%; border-bottom: 1px solid #bbb"
                >
                  <span v-if="attendance.subLessons">
                    <span
                      style="
                        background-color: rgb(255, 210, 48);
                        color: black;
                        padding-top: 1px;
                        padding-left: 6px;
                        padding-right: 6px;
                        padding-bottom: 1px;
                        font-weight: bold;
                      "
                      >Г-{{
                        attendance.selectedGroupIndex &&
                        attendance.selectedGroupIndex.groupIndex
                          ? attendance.selectedGroupIndex.groupIndex
                          : attendance.selectedGroupIndex
                      }}</span
                    >
                  </span>
                </td>
                <td
                  class="px-2 blue--text"
                  v-else-if="
                    attendance.selectedClassGroup &&
                    attendance.selectedClassGroup.programData
                  "
                  style="width: 10%; border-bottom: 1px solid #bbb"
                >
                  {{
                    attendance.selectedClassGroup.programData.STUDENT_GROUP_NAME
                      ? attendance.selectedClassGroup.programData.STUDENT_GROUP_NAME.toUpperCase()
                      : attendance.selectedClassGroup.programData.fullName.toUpperCase()
                  }}
                </td>
                <td v-else style="width: 10%; border-bottom: 1px solid #bbb">
                  --
                </td>
                <td style="border-bottom: 1px solid #bbb">
                  <p class="mb-0" v-if="attendance.selectedSubSubTopic">
                    <span class="font-weight-bold; blue--text">ЭС: </span>
                    {{ attendance.selectedSubSubTopic.topicName }}
                  </p>
                  <p class="mb-0" v-if="attendance.selectedSubTopic">
                    <span class="font-weight-bold">НС: </span
                    >{{ attendance.selectedSubTopic.courseTopicName }}
                  </p>
                  <p class="mb-0" v-if="attendance.selectedParentTopic">
                    <span class="font-weight-bold">БС: </span
                    >{{ attendance.selectedParentTopic.parentCourseTopicName }}
                  </p>
                </td>
                <td style="border-bottom: 1px solid #bbb">
                  {{ _getHoweworkString(attendance, true) }}
                </td>
                <td style="border-bottom: 1px solid #bbb">
                  <p class="mb-0" v-if="attendance.lessonNotes">
                    {{ attendance.lessonNotes }}
                  </p>
                  <p class="mb-0" v-if="attendance.lessonConclusion">
                    {{ attendance.lessonConclusion }}
                  </p>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import AttendanceLessonByStudentsDialog from "./AttendanceLessonByStudentsDialog";
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import axios from "axios";
import esisAPIS from "@/_esis/EsisUtil.js";
import PageJurnal from "./PageJurnal.vue";
// import PageLessonClassAttendance from "./PageLessonClassAttendance.vue";
import TeacherCalendar from "@/views/Dashboard/Widgets/TeacherCalendar.vue";
// import IrzTopics from "./IrzTopics.vue";

Vue.use(VueSweetalert2);
const fb = require("@/firebaseConfig.js");

export default {
  name: "PageLessonAttendanceChecking",
  components: {
    TeacherCalendar,
    AttendanceLessonByStudentsDialog,
    PageJurnal,
    // IrzTopics,
    // PageLessonClassAttendance,
  },
  data: () => ({
    loadingEsisTopics: null,
    loadingESIS: false,
    gizSelectedLesson: null,
    gizAttendances: null,
    showOldSchoolAttendances: false,

    currentSelectLessonOfPreviousTeacher: null,
    loadingPreviousTeacherAtts: false,
    previousTeacherAttendances: null,
    selectedPreviousTeacher: null,
    showPreviousTeachers: false,

    loadingDelete: false,
    weekDaysNames: [
      { name: "Даваа", dayIndex: 1 },
      { name: "Мягмар", dayIndex: 2 },
      { name: "Лхагва", dayIndex: 3 },
      { name: "Пүрэв", dayIndex: 4 },
      { name: "Баасан", dayIndex: 5 },
      // { name: "Бямба", dayIndex: 6 },
      // { name: "Ням", dayIndex: 7 },
    ],
    selectedWeekForGeneration: null,
    generatedIrzuud: null,
    loadingGenerateIrzuud: false,
    showGenerateProcessDialog: false,
    selectedWeekToDelete: null,
    showDeleteAllIrzDialog: false,
    selectDeleteAllIrzWithTopics: false,

    currentSelectedLessonInDialog: false,
    showProblematicIrzDialog: false,
    showChangeLessonTypeDialog: false,
    selectedEsisLessonType: null,
    esisLessonTypes: [
      {
        esisLessonTypeId: 1,
        name: "Үндсэн",
      },
      {
        esisLessonTypeId: 2,
        name: "Под Групп",
      },
      {
        esisLessonTypeId: 3,
        name: "Xолимог",
      },
    ],
    loadingCalendarData: false,
    selectedSchoolEelj: 1,
    schoolEeljs: null,
    editingAttendances: false,
    studentsStatuses2: null,
    selectedLessonGroupIndex: null,
    currentWeekDays: null,
    selectedWeekExecutions: null,
    currentSelectedWeek: null,
    zSelectedTab: 0,
    // showDashboard: false,
    selectedPreviousPlannedTopic: null,
    loadingPreviousPlannedTopics: false,
    plannedTopics: null,
    generateForNextEelj: false,
    loadingGenerate: false,
    selectedWeekForGenerate: -1,
    currentSchoolWeek: 0,
    showingAll: false,
    allData: null,

    copiedLesson: null,
    pastedLesson: null,
    snackbar: false,
    copiedTopics: null,
    showCopyDialog: false,
    //
    currentSelectedSemester: 4,
    //
    issuedClassConclusionNotes: null,
    selectedPreviousClassConclusionNotes: null,
    loadingClassConclusionNotes: false,
    //
    issuedClassNotes: null,
    selectedPreviousClassNotes: null,
    loadingClassNotes: false,

    //group for elective lesson
    selectedLessonGroup: null,
    loadingHomeworkDatabase: false,
    issuedHomeworks: null,
    selectedPreviousHomework: null,
    // lesson groups for a lesson which is selected to checking attendance
    selectedLessonGroups: null,
    editedLessonConclusion: null,
    editedLessonNotes: null,

    showTopicsDetailed: false,
    showTeachersNotesDialog: false,
    isAsc: false,
    selectedCourseInfo: null,
    foundLessonGroup: null,
    showHomeWorkDialog: false,
    newTopicIndex: -1,
    newTopic: null,
    showAddNewTopicDialog: false,
    selectedHomeWork: null,
    selectedSubSubTopicDescription: null,
    selectedSubSubTopic: null,
    selectedSubTopic: null,
    selectedParentTopicForAttendance: null,

    lessonTopicsFromFirebase: null,
    selectedAttendance: null,
    showSubSubTopicDialog: false,
    currentSelectedLesson: null,
    newIrzDialog: false,
    irzBurtgehDialog: false,
    selectedYear: null,
    selectedDay: null,
    selectedEelj: null,
    loading: false,
    currentSelectedClassGroup: null,
    years: [2023, 2024],
    // months: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12],
    months: null,
    dayNames: [
      "Ням",
      "Даваа",
      "Мягмар",
      "Лхагва",
      "Пүрэв",
      "Баасан",
      "Бямба",
      "Ням",
    ],
    teacherAttendances: null,
    days: null,
    temporarlySelectedLesson: null,
    selectedMonth: null,
    query: null,
    path: null,
    // headerNames: null,
    bodyNames: null,
    students: null,
    lessons: null,
    renderComponent: true,
    xlessonStudentNotes: null,
    pageLimit: null,
    pageNumber: 1,
    pageSize: 500,
    firstDoc: null,
    lastDoc: null,
    selectedExecution: null,
    selectedTopic: null,
    selectedLessonTopics: null,
    openLessonTopicDialog: false,
    openLessonHWDialog: false,
    selectedLessonHWs: null,
    selectedHW: null,
    selectedLesson: null,
    executionExistsAlert: false,
    existingExecutionRef: null,
    teacherData: null,
    xeeljs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    calendarData: null,
  }),
  props: {
    selectedTeacher: {
      type: Object,
      required: false,
    },
    lessonObject: {
      type: Object,
      required: false,
    },
    filterClass: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    gizFilteredAttendances() {
      if (this.gizSelectedLesson) {
        if (this.gizSelectedLesson && this.gizSelectedLesson.isCollected) {
          return this.gizAttendances
            .filter(
              (att) =>
                att.selectedLesson.courseInfo.COURSE_ID ==
                this.gizSelectedLesson.courseInfo.COURSE_ID
            )
            .sort((a, b) => b.teachedAt - a.teachedAt || b.xeelj - a.xeelj);
        } else {
          return this.gizAttendances
            .filter(
              (att) =>
                // att.isAllowed &&
                att.selectedLesson.programId ==
                  this.gizSelectedLesson.programId &&
                att.selectedLesson.courseInfo.COURSE_ID ==
                  this.gizSelectedLesson.courseInfo.COURSE_ID
            )
            .sort((a, b) => b.teachedAt - a.teachedAt || b.xeelj - a.xeelj);
        }
      }
      return this.gizAttendances;
    },
    filteredPreviousTeacherAttendances() {
      if (this.currentSelectLessonOfPreviousTeacher) {
        if (
          this.currentSelectLessonOfPreviousTeacher &&
          this.currentSelectLessonOfPreviousTeacher.isCollected
        ) {
          return this.previousTeacherAttendances.filter(
            (att) =>
              att.selectedLesson.courseInfo.COURSE_ID ==
              this.currentSelectLessonOfPreviousTeacher.courseInfo.COURSE_ID
          );
        } else {
          return this.previousTeacherAttendances.filter(
            (att) =>
              // att.isAllowed &&
              att.selectedLesson.programId ==
                this.currentSelectLessonOfPreviousTeacher.programId &&
              att.selectedLesson.courseInfo.COURSE_ID ==
                this.currentSelectLessonOfPreviousTeacher.courseInfo.COURSE_ID
          );
        }
      }
      return this.previousTeacherAttendances;
    },
    getPreviousTeachers() {
      var teachers = [];
      if (this.teacherAttendances) {
        for (const att of this.teacherAttendances) {
          var lesson = this.lessons.find((lsn)=>lsn.id == att.ref.parent.parent.id)
          if (lesson && att.selectedLesson && lesson.byPreviousTeachers) {
            for (const tt of lesson.byPreviousTeachers) {
              if(tt.teacherRef.id !=  this.userData.id) {
                var found = teachers.find((ttt) => ttt.teacherRef.id == tt.teacherRef.id);
                if (!found) {
                  teachers.push(tt);
                }
              }
            }
          }
        } //for   
      }
      return teachers;
    },
    calendarButez() {
      return this.$store.state.calendarButez2;
    },
    allLessonTime() {
      if (this.filteredLessons) {
        var summe = 0;
        for (const ll of this.filteredLessons) {
          if (!ll.isCollected) {
            if (ll.calendarData) {
              summe = summe + ll.calendarData.length;
            }
          } else {
            if (ll.subLessons) {
              var cals = [];
              for (var i = 0; i < ll.subLessons.length; i++) {
                if (ll.subLessons[i].calendarData)
                  cals = [...ll.subLessons[i].calendarData];
              }
              summe = summe + cals.length;
            }
          }
        }
      }
      return summe;
    },
    numberOfIrz() {
      if (this.filteredSemesterAttendances) {
        var semesterDuration = this.calendarButez.find(
          (ss) => ss.session == this.currentSelectedSemester
        );
        var start = semesterDuration.duration[0];
        var end = semesterDuration.duration[1];

        const s1 = new Date(start.year + "-" + start.month + "-" + start.day);
        const e1 = new Date(end.year + "-" + end.month + "-" + end.day);

        return this.filteredSemesterAttendances.filter(
          (xx) =>
            new Date(xx.year + "-" + xx.month + "-" + xx.day) >= s1 &&
            new Date(xx.year + "-" + xx.month + "-" + xx.day) <= e1
        ).length;
      } else return 0;
    },
    zzschool() {
      return this.teacherData.ppschool;
    },
    getCalendarWeeks() {
      if (this.currentSelectedSemester) {
        var ss = this.calendarButez.find(
          (ss) => ss.session == this.currentSelectedSemester
        );
        if (ss)
          return ss.weeks
            .filter((ww) => ww <= this.currentSchoolWeek)
            .reverse();
      }
      return [];
    },

    filteredSemesterAttendances() {
      if (this.teacherAttendances && this.currentSelectedSemester)
        return this.teacherAttendances
          .filter((irz) => irz.isAllowed)
          .sort((a, b) => b.teachedAt - a.teachedAt || b.xeelj - a.xeelj);
      else return this.teacherAttendances;
    },
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          if (
            ll.courseInfo &&
            ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" &&
            ll.esisLessonType &&
            ll.esisLessonType.esisLessonTypeId > 2
          ) {
            var parentLL = null;
            const cc = list.find(
              (lesson) =>
                lesson.isCollected &&
                lesson.courseInfo.COURSE_ID &&
                lesson.courseInfo.COURSE_ID == ll.courseInfo.COURSE_ID
            );
            if (cc == undefined) {
              counter++;
              parentLL = ll;
              parentLL.index = counter;
              parentLL.uniqueId = ll.id + counter;
              parentLL.name3 = counter + ". " + ll.name2;
              parentLL.subLessons = [ll];
              parentLL.isCollected = true;
              // console.log(parentLL, parentLL.ref.path);
              list.push(parentLL);
            } else {
              cc.subLessons.push(ll);
            }
          } else {
            counter++;
            ll.index = counter;
            ll.uniqueId = ll.id + counter;
            ll.name3 = counter + ". " + ll.name2;
            list.push(ll);
          }
        }
      }
      return list;
    },
    //for huvaari
    calendarData3() {
      if (this.filteredLessons) {
        var cals = [];
        for (const ll of this.filteredLessons) {
          if (!ll.isCollected) {
            if (ll.calendarData) {
              for (const ca of ll.calendarData) {
                cals.push(ca);
              }
            }
          } else {
            if (ll.subLessons) {
              for (var i = 0; i < ll.subLessons.length; i++) {
                if (ll.subLessons[i].calendarData) {
                  for (const ca of ll.calendarData) {
                    cals.push(ca);
                  }
                }
              }
            }
          }
        }
      }
      return cals;
    },
  },

  watch: {
    selectedPreviousPlannedTopic(val) {
      if (val) {
        this.selectedParentTopicForAttendance = val.topic1;
        this.selectedSubTopic = val.topic2;
        this.selectedSubSubTopic = val.topic3;

        this.selectedHomeWork = val.selectedHomeWork
          ? val.selectedHomeWork
          : {};
        this.editedLessonNotes = val.notes;
        this.editedLessonConclusion = val.conclusion;
      }
    },
    // currentSelectLessonOfPreviousTeacher(val){
    //   console.log(val)
    //   if(val&&val.isCollected){

    //   }else{

    //   }
    // },
    showOldSchoolAttendances(val) {
      if (val) {
        var query = fb.db
          .collectionGroup("executions")
          .where("deleted", "==", false);

        if (this.userData.ref.id == 9000000484800) {
          query = query.where(
            "teacherRef",
            "==",
            fb.db.doc("schools/XySwKSrCUve9E4XWGzJ4/teachers/9000000484800")
          );
        } else {
          query = query.where("teacherRef", "==", this.userData.ref);
        }
        query.onSnapshot((docs) => {
          this.gizAttendances = [];
          docs.forEach((doc) => {
            let att = doc.data();
            att.ref = doc.ref;
            att.id = doc.id;
            att.teachedAt = new Date(
              att.year + "-" + att.month + "-" + att.day
            );
            this._calWeekNumber(att);
            this._calDayName(att);
            // this.fixSemester(att);
            // if (att.semester == undefined) {
            //   this._setSemeter(att, true);
            // }
            // this.fixExecutionData(att);
            // this.fixExecutionYear(att);
            this.gizAttendances.push(att); //99
          });
          this.gizAttendances.sort(
            (a, b) => b.teachedAt - a.teachedAt || b.xeelj - a.xeelj
          );
        });
      }
    },

    showPreviousTeachers(val) {
      if (val) {
        var xx = this.getPreviousTeachers;
        if (xx && xx.length > 0) this.selectedPreviousTeacher = xx[0];
      }
    },
    selectedPreviousTeacher(val) {
      var query = fb.db
        .collectionGroup("executions")
        .where("deleted", "==", false)
        .where("teacherRef", "==", val.teacherRef);
      if (this.currentSelectedLessonInDialog) {
        if (this.currentSelectedLessonInDialog.isCollected) {
          query = query.where(
            "courseInfo.COURSE_ID",
            "==",
            this.currentSelectedLessonInDialog.courseInfo.COURSE_ID
          );
        } else {
          query = query
            .where(
              "selectedLesson.programId",
              "==",
              this.currentSelectedLessonInDialog.programId
            )
            .where(
              "selectedLesson.courseInfo.COURSE_ID",
              "==",
              this.currentSelectedLessonInDialog.courseInfo.COURSE_ID
            );
        }
      }
      this.loadingPreviousTeacherAtts = true;
      query.onSnapshot((docs) => {
        this.previousTeacherAttendances = [];
        docs.forEach((doc) => {
          let att = doc.data();
          att.ref = doc.ref;
          att.id = doc.id;
          att.teachedAt = new Date(att.year + "-" + att.month + "-" + att.day);
          this._calWeekNumber(att);
          this._calDayName(att);
          this.fixSemester(att);
          if (att.semester == undefined) {
            this._setSemeter(att, true);
          }
          this.isAllowed(att);
          this.fixExecutionData(att);
          this.fixExecutionYear(att);
          this.previousTeacherAttendances.push(att); //99
          // if (!this.allData[att.weekNumber]) {
          //   this.allData[att.weekNumber] = [att];
          // } else {
          //   this.allData[att.weekNumber].push(att);
          // }
        });
        this.loadingPreviousTeacherAtts = false;
      });
    },
    selectedEelj() {
      if (
        this.currentSelectedClassGroup &&
        this.currentSelectedClassGroup.classGroupRef
      ) {
        this.currentSelectedClassGroup.classGroupRef
          .collection("attendances-" + this.userData.school.currentYear)
          .where("month", "==", this.selectedMonth)
          .where("day", "==", this.selectedDay)
          .get()
          .then((docs) => {
            this.studentsStatuses2 = [];
            docs.forEach((doc) => {
              let stat = doc.data();
              stat.id = doc.id;
              stat.ref = doc.ref;
              if (stat.studentId) {
                // if (this.irzEditing == false && att.daily_attendance) {
                //by manager, director, class Teacher, jijuur
                if (stat.daily_attendance) {
                  this.studentsStatuses2.push(stat);
                }
                //hicheel deer choloolson
                else if (
                  stat.daily_attendance == false &&
                  stat.month == this.selectedMonth &&
                  stat.xeelj == this.selectedEelj &&
                  stat.day == this.selectedDay &&
                  stat.status != 1
                ) {
                  this.studentsStatuses2.push(stat);
                }
              }
            });
          });
      }
    },
    calendarRefreshCalled(val) {
      if (val) {
        this.getCalendarData();
        this.$store.state.calendarRefreshCalled = false;
      }
    },
    currentSelectedWeek(val) {
      if (val) {
        this.findWorkingWeekDays();
        if (this.allData) {
          this.selectedWeekExecutions = this.allData[val];
        }
      }
    },
    currentSelectedSemester(val) {
      console.log("READING currentSelectedSemester", val);
      this.fetchNextPage(true);
    },
    showCopyDialog() {
      var list = [];
      if (this.lessons) {
        for (var ll of this.lessons) {
          if (
            ll.courseInfo.COURSE_ID ==
            this.currentSelectedLesson.courseInfo.COURSE_ID
          ) {
            console.log(ll, ll.programId, ll.classGroups[0].classGroupName);
            ll.executions = null;
            var query = fb.db
              .collectionGroup("executions")
              .where("deleted", "==", false);
            query = query.where("teacherRef", "==", this.teacherData.ref);
            query = query.where("selectedLesson.programId", "==", ll.programId);
            // query = query.where("semester", "==", this.currentSelectedSemester);
            query = query.where(
              "selectedLesson.courseInfo.COURSE_ID",
              "==",
              this.currentSelectedLesson.courseInfo.COURSE_ID
            );
            query.orderBy("teachedAt", "desc").onSnapshot((docs) => {
              ll.executions = [];
              docs.forEach((doc) => {
                let att = doc.data();
                att.ref = doc.ref;
                att.id = doc.id;
                console.log(att.ref.path);
                ll.executions.push(att);
              });
              console.log(ll.executions.length, ll);
            });
            list.push(ll);
          }
        }
      }
    },
    selectedPreviousClassNotes(val) {
      if (val) {
        this.editedLessonNotes = val;
      } else {
        this.editedLessonNotes = null;
      }
    },
    selectedPreviousClassConclusionNotes(val) {
      console.log(val);
      if (val) {
        this.editedLessonConclusion = val;
      } else {
        this.editedLessonConclusion = null;
      }
    },
    selectedPreviousHomework(val, preVal) {
      console.log(val);
      if (val) {
        if (val.name) this.selectedHomeWork.name = val.name;
        if (val.description)
          this.selectedHomeWork.description = val.description;
        else this.selectedHomeWork.description = null;
      } else {
        this.selectedHomeWork.name = null;
        this.selectedHomeWork.description = null;
        console.log(preVal.name, preVal.description);
      }
    },
    selectedParentTopicForAttendance(val) {
      if (val) {
        this.loadingEsisTopics = true;
        val.ref
          .collection("subTopics")
          .orderBy("numbering")
          .onSnapshot((docs) => {
            var counter2 = 0;
            val.children = [];
            docs.docs.forEach((doc) => {
              counter2++;
              var subTopic = doc.data();
              subTopic.id = doc.id;
              subTopic.ref = doc.ref;
              subTopic.name =
                counter2 + ". " + this._getCapText(subTopic.courseTopicName);
              if (!subTopic.deleted) val.children.push(subTopic);
            });
            this.loadingEsisTopics = false;
          });
      }
    },
    selectedSubTopic(val) {
      if (val) {
        this.loadingEsisTopics = true;
        val.ref
          .collection("subSubTopics")
          // .orderBy("numbering", "asc")
          .onSnapshot((docs) => {
            val.children = [];
            docs.docs.forEach((doc) => {
              var subTopic = doc.data();
              subTopic.id = doc.id;
              subTopic.ref = doc.ref;
              if (!subTopic.deleted) val.children.push(subTopic);
            });
            this.$forceUpdate();
            this.loadingEsisTopics = false;
          });
      }
    },
    // async currentSelectedLesson(val) {
    //   console.log(val)
    // },
    async currentSelectedLesson(val) {
      if (val) {
        console.log("XXXXcurrentSelectedLesson", val);
        var query = fb.db
          .collectionGroup("executions") //66
          .where("deleted", "==", false);
        if (this.teacherData.role == "teacher") {
          query = query.where("teacherRef", "==", this.teacherData.ref);
          //.where("semester", "==", this.currentSelectedSemester);
          // query = query.where("semester","==", this.currentSelectedSemester) //555
        }
        if (this.currentSelectedLesson) {
          if (
            this.currentSelectedLesson &&
            this.currentSelectedLesson.isCollected
          ) {
            query = query.where(
              "selectedLesson.courseInfo.COURSE_ID",
              "==",
              this.currentSelectedLesson.courseInfo.COURSE_ID
            );
            this.showingAll = false;
          } else {
            query = query
              .where(
                "selectedLesson.programId",
                "==",
                this.currentSelectedLesson.programId
              )
              .where(
                "selectedLesson.courseInfo.COURSE_ID",
                "==",
                this.currentSelectedLesson.courseInfo.COURSE_ID
              );
            this.showingAll = false;
          }
        } else {
          this.showingAll = true;
        }
        this.loading = true;
        query.onSnapshot((docs) => {
          this.teacherAttendances = [];
          this.allData = [];
          docs.forEach((doc) => {
            let att = doc.data();
            att.ref = doc.ref;
            att.id = doc.id;
            // console.log("att", att.month+"-"+att.day, att.xeelj, att.ref.path, att)
            att.teachedAt = new Date(
              att.year + "-" + att.month + "-" + att.day
            );
            this._calWeekNumber(att);
            this._calDayName(att);
            this.isAllowed(att);
            this.fixExecutionData(att);
            this.fixExecutionYear(att);
            if (!this.allData[att.weekNumber]) {
              this.allData[att.weekNumber] = [att];
            } else {
              this.allData[att.weekNumber].push(att);
            }
            this.teacherAttendances.push(att);
          });
          console.log(
            "1111teacherAttendances.length",
            this.teacherAttendances.length
          );
          this.loading = false;
          this.$forceUpdate();
        });
      } else {
        this._setupp();
      }
    },
    selectedYear() {
      this.months = [];
      for (const ss of this.calendarButez) {
        if (ss.year == this.selectedYear) {
          for (var mm of ss.months) {
            this.months.push(mm.name);
          }
        }
      }
    },
    selectedMonth() {
      this.days = [];
      console.log(this.selectedMonth);
      for (const ss of this.calendarButez) {
        for (var mm of ss.months) {
          if (mm.name == this.selectedMonth) {
            for (const dd of mm.days) {
              // console.log(dd.day, mm.name)
              if (!dd.isHoliday) this.days.push(dd.day);
            }
            // this.days = [
            //   ...mm.days.filter((d) => !d.isHoliday).map((dd) => dd.day),
            // ];
            // break;
          }
        }
      }
    },
    temporarlySelectedLesson(val) {
      if (!val) {
        this.currentSelectedClassGroup = null;
      }
      this.temporarlySelectedLesson = val;
      if (
        this.temporarlySelectedLesson &&
        this.temporarlySelectedLesson.classGroups
      ) {
        this.temporarlySelectedLesson.classGroups.forEach((doc) => {
          if (!doc.name2) doc.name2 = doc.departmentName + doc.classGroupName;
        });
      }
      if (
        this.temporarlySelectedLesson &&
        this.temporarlySelectedLesson.topics == null
      ) {
        this.temporarlySelectedLesson.ref
          .collection("topics")
          .orderBy("createdAt", "asc")
          .onSnapshot((docs) => {
            this.temporarlySelectedLesson.topics = [];
            var counter = 1;
            docs.forEach((doc) => {
              var topic = doc.data();
              topic.id = doc.id;
              topic.ref = doc.ref;
              topic.name = counter + ". " + topic.title;
              this.temporarlySelectedLesson.topics.push(topic);
              counter++;
            });
          });
      }
      this.students = [];
    },
    irzBurtgehDialog(val) {
      if (val == false) {
        this.currentSelectedClassGroup = null;
        this.selectedEelj = null;
        this.selectedMonth = new Date().getMonth() + 1;
        this.selectedDay = new Date().getDate();
        this.selectedYear = new Date().getFullYear();
      }
    },
    currentSelectedClassGroup(val) {
      console.log(val);
      if (val) {
        var x = val.classGroupRef;
        if (
          x != null &&
          this.temporarlySelectedLesson.esisLessonType &&
          this.temporarlySelectedLesson.esisLessonType.esisLessonTypeId == 1
        ) {
          this.selectedLessonGroups = null;
          if (val.editing) {
            this.findStudents(x, true);
          } else {
            this.selectedLessonGroup = null;

            this.findStudents(x, false);
          }
        } else {
          if (this.temporarlySelectedLesson.isCollected) {
            if (val.editing) {
              this.findGroupStudentsFromDifferentClasses(true);
            } else {
              this.findGroupStudentsFromDifferentClasses(false);
            }
          } else {
            this.selectedLessonGroups = null;
            this.temporarlySelectedLesson.isSubGrouped = true;
            if (val.editing) {
              this.findGroupStudents(true);
            } else {
              this.selectedLessonGroup = null;
              this.findGroupStudents(false);
            }
          }
        }
      }
    },
    selectedDay(val) {
      console.log("selectedMoth", val);
    },
  },
  methods: {
    _isMixedLesson(attendance) {
      if (attendance.subLessons) return true;
      else if (attendance.isWithSublessons) return true;
      // else if(attendance.selectedLesson
      //   &&attendance.selectedLesson.esisLessonType
      //   &&attendance.selectedLesson.esisLessonType.esisLessonTypeId==3)
      //   return true
      else return false;
    },
    // _isZaavaLesson(attendance){
    //   return attendance.selectedLesson
    //     &&attendance.selectedLesson.esisLessonType
    //     &&attendance.selectedLesson.esisLessonType.esisLessonTypeId==1
    // },
    _isPodGroupedLesson(attendance) {
      // console.log(attendance, attendance.ref.path)
      var statee = false;
      if (
        attendance.selectedLesson &&
        attendance.selectedLesson.esisLessonType &&
        attendance.selectedLesson.esisLessonType.esisLessonTypeId == 1
      ) {
        return false;
      } else {
        statee =
          !attendance.isWithSublessons &&
          attendance.selectedLesson &&
          attendance.selectedLesson.isGroupBased &&
          attendance.selectedLesson.esisLessonType.esisLessonTypeId == 2 &&
          (attendance.isSubGrouped ||
            attendance.selectedGroupIndex ||
            this.selectedLessonGroup);
        statee =
          statee ||
          (attendance.selectedLesson.esisLessonType &&
            attendance.selectedLesson.esisLessonType.esisLessonTypeId == 2);
      }
      return statee;
    },
    _checkLessonType(attendance) {
      var statee = this._isPodGroupedLesson(attendance);
      console.log("pod", statee);
      var x1 =
        attendance.selectedLesson.isGroupBased &&
        attendance.selectedLesson.esisLessonType.esisLessonTypeId == 2;
      console.log("x1", x1);
      var x2 =
        x1 ||
        attendance.isSubGrouped ||
        attendance.selectedGroupIndex ||
        this.selectedLessonGroup;
      console.log("x2", x2);
      console.log(
        "selectedGroupIndex",
        attendance.selectedGroupIndex,
        this.selectedLessonGroup
      );
      console.log(
        "attendance.selectedLesson.isSubGrouped",
        attendance.selectedLesson.isSubGrouped
      );
      console.log("selectedLessonGroup", this.selectedLessonGroup);
      console.log("esisLessonType", attendance.selectedLesson.esisLessonType);
      console.log("attendance.isWithSublessons", attendance.isWithSublessons);

      console.log("statee", statee);
    },
    _printStatistics(lesson, irzuud) {
      var counter1 = 0;
      var counter2 = 0;
      var counter3 = 0;
      for (const irz of irzuud) {
        if (irz.semester == 1) counter1++;
        else if (irz.semester == 2) counter2++;
        else if (irz.semester == 3) counter3++;
      }
      console.log(counter1, counter2, counter3);
    },
    async _transformOldIrzuud(irzuud) {
      var counter = 0;
      console.log(this.userData.ref.path, this.gizSelectedLesson);
      var batch = fb.db.batch();
      for (const oldIrz of irzuud) {
        if (
          this.gizSelectedLesson.courseInfo.COURSE_ID ==
          oldIrz.selectedLesson.courseInfo.COURSE_ID
        ) {
          counter++;
          var oldi = Object.assign({}, oldIrz);
          oldi["closedBy"] = this.userData.ref;
          oldi["teacherRef"] = this.userData.ref;
          // selectedLessonGroup
          //selectedLesson
          //selectedClassGroup
          await fb.db
            .doc(
              oldIrz.classGroupRef.path.replace(
                "XySwKSrCUve9E4XWGzJ4",
                "bSYE6D6U7DSnqgCxWs51"
              )
            )
            .get()
            .then((doc) => {
              let newProgramData = doc.data();
              newProgramData.ref = doc.ref;
              oldi["selectedLesson"] = this.gizSelectedLesson;
              oldi["classGroupRef"] = newProgramData.ref;
              oldi["departmentRef"] = newProgramData.ref.parent.parent;
              oldi["selectedClassGroup"]["departmentRef"] =
                newProgramData.ref.parent.parent;
              oldi["selectedClassGroup"]["classGroupRef"] = newProgramData.ref;
              oldi["selectedClassGroup"]["programData"] = newProgramData;

              console.log(counter, oldi);
              console.log(oldi.classGroupRef.path);
              console.log(oldi.departmentRef.path);
              console.log(oldi["selectedClassGroup"]["programData"].ref.path);
              console.log(oldi["selectedLesson"].ref.path);

              var docRef = oldi.selectedLesson.ref
                .collection("executions")
                .doc(oldi.id);
              batch.set(docRef, oldi, { merge: true });
              // console.log(counter,newClassObject);
            });
        }
      } //for
      batch.commit().then(() => {
        console.log("done!");
      });
    },
    _isShowDeleteButton(list) {
      if (this.currentSelectedLesson && list) {
        if (
          list.filter(
            (ll) =>
              ll.selectedLesson.courseInfo.COURSE_ID ==
                this.currentSelectedLesson.courseInfo.COURSE_ID &&
              ll.selectedLesson.programId ==
                this.currentSelectedLesson.programId
          ).length > 0
        )
          return true;
      } else if (list && list.length > 0) return true;
      else return false;
    },
    _checkAlreadyGenerated(gIrz, weekNumber) {
      var irzuud = this.allData[weekNumber];
      if (irzuud) {
        var found = irzuud.find(
          (att) =>
            att.year == gIrz.date.year &&
            att.month == gIrz.date.month &&
            att.day == gIrz.date.day &&
            att.xeelj == gIrz.calendarData.xeelj
        );
        if (found) {
          gIrz.alreadyGenerated = true;
          // console.log("davhar izr found", found);
          return "F";
        }
      }
      gIrz.alreadyGenerated = false;
      return "NO";
    },
    _getDayName(day) {
      var wDay = this.weekDaysNames.find((dd) => dd.dayIndex == day);
      return wDay;
    },
    _getWeekGeneratedIrzuud(day) {
      if (this.generatedIrzuud)
        return this.generatedIrzuud.filter(
          (gIrz) => gIrz.calendarData.dayIndex == day
        );
      else return [];
    },
    getSelectedIrzNumber(lesson) {
      if (this.teacherAttendances) {
        if (lesson) {
          if (lesson.isCollected) {
            return this.teacherAttendances.filter(
              (irz) =>
                irz.isAllowed &&
                irz.selectedLesson.courseInfo.COURSE_ID ==
                  lesson.courseInfo.COURSE_ID
            );
          } else {
            return this.teacherAttendances.filter(
              (irz) =>
                irz.isAllowed &&
                irz.selectedLesson.programId == lesson.programId &&
                irz.selectedLesson.courseInfo.COURSE_ID ==
                  lesson.courseInfo.COURSE_ID
            );
          }
        } else return this.teacherAttendances.filter((irz) => irz.isAllowed);
      } else return [];
    },
    getCurrentSemesterIrzuud() {
      if (this.currentSelectedLessonInDialog && this.teacherAttendances) {
        if (this.currentSelectedLessonInDialog.isCollected) {
          return this.teacherAttendances.filter(
            (irz) =>
              irz.isAllowed &&
              irz.selectedLesson.courseInfo.COURSE_ID ==
                this.currentSelectedLessonInDialog.courseInfo.COURSE_ID
          );
        } else {
          return this.teacherAttendances.filter(
            (irz) =>
              irz.isAllowed &&
              irz.selectedLesson.programId ==
                this.currentSelectedLessonInDialog.programId &&
              irz.selectedLesson.courseInfo.COURSE_ID ==
                this.currentSelectedLessonInDialog.courseInfo.COURSE_ID
          );
        }
      } else if (this.teacherAttendances)
        return this.teacherAttendances.filter((irz) => irz.isAllowed);
      else return [];
    },
    _showProblematicAttendances() {
      //
    },
    _getRightDate(dayIndex, week) {
      var dates = this._getWeekBeginEnd000(week);
      return dates
        .filter((d) => !d.isHoliday)
        .find((dd) => dd.garag == dayIndex);
    },
    getDayName(dayNumber) {
      let dayName;
      switch (dayNumber) {
        case 0:
          dayName = "Ня";
          break;
        case 1:
          dayName = "Да";
          break;
        case 2:
          dayName = "Мя";
          break;
        case 3:
          dayName = "Лx";
          break;
        case 4:
          dayName = "Пү";
          break;
        case 5:
          dayName = "Ба";
          break;
        case 6:
          dayName = "Бя";
          break;
        default:
          dayName = null;
      }
      return dayName;
    },
    _getSimpliedClassGroupData(classGroup) {
      var tmpClassGroup = {};
      if (classGroup["ACADEMIC_LEVEL"])
        tmpClassGroup["ACADEMIC_LEVEL"] = classGroup["ACADEMIC_LEVEL"];
      if (classGroup["classGroupFullName"])
        tmpClassGroup["classGroupFullName"] = classGroup["classGroupFullName"];
      if (classGroup["classGroupName"])
        tmpClassGroup["classGroupName"] = classGroup["classGroupName"];
      if (classGroup["classGroupRef"])
        tmpClassGroup["classGroupRef"] = classGroup["classGroupRef"];
      if (classGroup["departmentRef"])
        tmpClassGroup["departmentRef"] = classGroup["departmentRef"];
      tmpClassGroup["programData"] = {
        STUDENT_GROUP_ID: classGroup["programData"]["STUDENT_GROUP_ID"],
        STUDENT_GROUP_NAME: classGroup["programData"]["STUDENT_GROUP_NAME"],
        fullName: classGroup["programData"]["fullName"]
          ? classGroup["programData"]["fullName"]
          : classGroup["programData"]["STUDENT_GROUP_NAME"],
      };
      return tmpClassGroup;
    },
    _getMinimizedLesson(lesson) {
      var tmpLesson = {};
      if (lesson.ref) tmpLesson.ref = lesson.ref;
      if (lesson.courseInfo) tmpLesson["courseInfo"] = lesson.courseInfo;
      if (lesson.esisLessonType)
        tmpLesson["esisLessonType"] = lesson.esisLessonType;

      if (lesson.isGroupBased)
        tmpLesson["esisLessonType"] = lesson.isGroupBased;
      if (lesson.classGroups)
        tmpLesson["classGroups"] = lesson.classGroups.map((cg) =>
          this._getSimpliedClassGroupData(cg)
        );

      if (lesson.classGroupRefs)
        tmpLesson["classGroupRefs"] = lesson.classGroupRefs;

      if (lesson.byTeachers) tmpLesson["byTeachers"] = lesson.byTeachers;
      if (lesson.classGroupRefs)
        tmpLesson["classGroupRefs"] = lesson.classGroupRefs;
      if (lesson.lessonGroups) tmpLesson["lessonGroups"] = lesson.lessonGroups;
      if (lesson.lessonType) tmpLesson["lessonType"] = lesson.lessonType;

      if (lesson.lessonType) tmpLesson["lessonType"] = lesson.lessonType;
      if (lesson.numberOfStudents)
        tmpLesson["numberOfStudents"] = lesson.numberOfStudents;
      if (lesson.programId) tmpLesson["programId"] = lesson.programId;
      if (lesson.programRef) tmpLesson["programRef"] = lesson.programRef;
      if (lesson.semester) tmpLesson["semester"] = lesson.semester;
      if (lesson.teacherRefs) tmpLesson["teacherRefs"] = lesson.teacherRefs;
      return tmpLesson;
    },
    _generateIrzConfirmed() {
      this.$swal({
        title: "Нийт цагийн ирц үүсгэx үү?",
        text: "Эргүүлээд устгаx боломжтой!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("Confirmed!!!!");
          var batch = fb.db.batch();
          if (this.generatedIrzuud) {
            // var counter = 0;
            for (const irz of this.generatedIrzuud) {
              if (!irz.alreadyGenerated) {
                // counter++;
                // console.log(irz.date.month, irz.date.day, irz.alreadyGenerated, irz, this._getSemesterNumber(irz.date.month, irz.date.day))
                var createdDate = new Date();
                createdDate.setFullYear(Number(irz.date.year));
                createdDate.setMonth(Number(irz.date.month - 1));
                createdDate.setDate(Number(irz.date.day));
                createdDate.setHours(irz.calendarData.xeelj);

                var calEeljNumber = -1;
                if (
                  irz.calendarData.calendarEelj &&
                  irz.calendarData.calendarEelj.eeljId
                ) {
                  var cc = irz.calendarData.calendarEelj.eeljId.split("-");
                  if (cc.length > 0) {
                    calEeljNumber = parseInt(cc[cc.length - 1]);
                  }
                }

                var xSelectedClassGroup = Object.assign({}, irz.classGroup);
                xSelectedClassGroup.programData = {
                  STUDENT_GROUP_NAME:
                    irz.classGroup["programData"]["STUDENT_GROUP_NAME"],
                  STUDENT_GROUP_ID:
                    irz.classGroup["programData"]["STUDENT_GROUP_ID"],
                };
                var xSelectedLesson = Object.assign({}, irz.lesson);
                xSelectedLesson.classGroups = [];
                for (const cg of irz.lesson.classGroups) {
                  var tmp = {
                    ACADEMIC_LEVEL: cg.ACADEMIC_LEVEL,
                    classGroupFullName: cg.classGroupFullName,
                    classGroupName: cg.classGroupName,
                    classGroupRef: cg.classGroupRef,
                    departmentName: cg.departmentName,
                    departmentRef: cg.departmentRef,
                    programData: {
                      STUDENT_GROUP_NAME:
                        cg["programData"]["STUDENT_GROUP_NAME"],
                      STUDENT_GROUP_ID: cg["programData"]["STUDENT_GROUP_ID"],
                    },
                    xxx: false,
                  };
                  xSelectedLesson.classGroups.push(tmp);
                }
                delete xSelectedLesson.selectedLesson;
                delete xSelectedLesson.selectedClassGroup;
                delete xSelectedLesson.dayReport;

                var tmpLesson = this._getMinimizedLesson(xSelectedLesson);
                tmpLesson["_test_test"] = "test_test";

                var irzData = {
                  calendarSchoolEelj: calEeljNumber,
                  classGroupRef: irz.classGroup.classGroupRef,
                  closed: true,
                  closedBy: this.userData.ref,
                  createdAt: new Date(),

                  deleted: false,
                  departmentRef: irz.classGroup.classGroupRef.parent.parent,
                  teacherRef: this.userData.ref,

                  selectedClassGroup: xSelectedClassGroup,
                  selectedLesson: tmpLesson,
                  selectedLessonGroup: null,

                  studentsAbsent: 0,
                  studentsDisturbing: 0,
                  studentsExcused: 0,
                  studentsNoNotebook: null,
                  studentsNoPen: null,
                  studentsOnline: 0,
                  studentsPresent: 0,
                  studentsSick: 0,

                  teachedAt: createdDate,
                  semester: this._getSemesterNumber(
                    irz.date.month,
                    irz.date.day
                  ),
                  day: irz.date.day,
                  month: irz.date.month,
                  xeelj: irz.calendarData.xeelj,
                  year: irz.date.year,
                  weekNumber: irz.date.weekNumber,
                  _autoGenerated: true,
                };
                if (
                  irzData.selectedLesson &&
                  irzData.selectedLesson.esisLessonType &&
                  irzData.selectedLesson.esisLessonType &&
                  irzData.selectedLesson.esisLessonType.esisLessonTypeId > 1
                ) {
                  irzData.isSubGrouped = true;
                  if (irz.calendarData && irz.calendarData.lessonGroup) {
                    irzData.groupIndex =
                      irz.calendarData.lessonGroup.groupIndex;
                    irzData.selectedGroupIndex =
                      irz.calendarData.lessonGroup.groupIndex;
                  }
                }

                if (irz.dailyIrz) {
                  irzData["studentsAbsent"] = irz.dailyIrz.studentsAbsent
                    ? irz.dailyIrz.studentsAbsent
                    : 0;
                  irzData["studentsExcused"] = irz.dailyIrz.studentsExcused
                    ? irz.dailyIrz.studentsExcused
                    : 0;
                  irzData["studentsSick"] = irz.dailyIrz.studentsSick
                    ? irz.dailyIrz.studentsSick
                    : 0;
                  irzData["studentsOnline"] = irz.dailyIrz.studentsOnline
                    ? irz.dailyIrz.studentsOnline
                    : 0;
                  irzData["studentsPresent"] = irz.dailyIrz.studentsPresent
                    ? irz.dailyIrz.studentsPresent
                    : 0;
                  irzData["totalStudents"] = irz.dailyIrz.totalStudents
                    ? irz.dailyIrz.totalStudents
                    : 0;
                } else {
                  irzData["studentsPresent"] = irz.totalStudents;
                  irzData["totalStudents"] = irz.totalStudents;
                }
                // console.log(
                //   counter,
                //   irzData.year + "-" + irzData.month + "-" + irzData.day,
                //   irz.calendarData.xeelj,
                //   irzData.selectedLesson.courseInfo.COURSE_NAME,
                //   irzData.selectedClassGroup.classGroupFullName,
                //   irzData["studentsPresent"],
                //   irzData["totalStudents"],
                //   irz.alreadyGenerated,
                //   irzData,
                //   irz
                // );
                if (irz.dailyIrz && irz.dailyIrz._details) {
                  for (const stat of irz.dailyIrz._details) {
                    var docName =
                      irz.date.year +
                      "-" +
                      irz.date.month +
                      "-" +
                      irz.date.day +
                      "-" +
                      irz.calendarData.xeelj +
                      "-" +
                      stat.studentId;
                    var statDocRef = irzData.classGroupRef
                      .collection(
                        "attendances-" + this.userData.school.currentYear
                      )
                      .doc(docName);
                    var studData = {
                      createdByName: this.userData.DISPLAY_NAME,
                      createdByTeacherRef: this.userData.ref,
                      daily_attendance: false,
                      year: irz.date.year,
                      month: irz.date.month,
                      day: irz.date.day,
                      status: stat.status,
                      weekNumber: stat.weekNumber
                        ? stat.weekNumber
                        : this.selectedWeekForGeneration,
                      studentId: stat.studentId,
                      text: stat.text,
                      xeelj: irz.calendarData.xeelj,
                    };
                    batch.set(statDocRef, studData, { merge: true });
                  }
                }
                var docRef = irzData.selectedLesson.ref
                  .collection("executions")
                  .doc();

                batch.set(docRef, irzData, { merge: true });
                // console.log(
                //   "irzDatairzData",
                //   irzData.weekNumber,
                //   irzData,
                //   irzData.selectedLesson.ref.path
                // );
                // console.log(
                // counter,
                // irz.dateStr,
                // irzData.month, irzData.day,
                // irz.calendarData.xeelj,
                // irzData.selectedLesson.courseInfo.COURSE_NAME,
                // irz.alreadyGenerated
                // )
              }
            }
          }
          batch.commit().then(() => {
            console.log("Generated!");
          });
        } else {
          console.log("showGenerateProcessDialog");
          this.showGenerateProcessDialog = true;
        }
      });
    },
    _generateIrzTmp() {
      this.$swal.fire("Түр xаав. 3 сарын 28-нд нээнэ!");
    },
    // _findLessonsWithoutCal(){
    //   if(this.generatedIrzuud){
    //     for(const ex of this.generatedIrzuud){
    //       this.filteredLessons(ll=>ex.lesson.ref.path)
    //     }
    //   }
    // },
    _generateIrzLesson(week, lesson) {
      if (!lesson.isCollected) {
        for (var classGroup of lesson.classGroups) {
          this.loadingGenerateIrzuud = true;
          var query = classGroup.classGroupRef
            .collection(
              "calendar-" +
                this.userData.school.currentYear +
                "-" +
                this.currentSelectedSemester
            )
            .where("courseInfo.COURSE_ID", "==", lesson.courseInfo.COURSE_ID);
          if (
            lesson.esisLessonType &&
            lesson.esisLessonType.esisLessonTypeId > 1
          ) {
            query = query.where(
              "lessonGroup.teacher.teacherId",
              "==",
              this.teacherData.id
            );
          }
          query
            .orderBy("dayIndex", "asc")
            .orderBy("xeelj", "asc")
            .get()
            .then((docs) => {
              this.generatedIrzuud = [];
              docs.docs.forEach((doc) => {
                let zz = doc.data();
                zz.id = doc.id;
                zz.ref = doc.ref;
                console.log(
                  "zz",
                  zz,
                  zz.dayIndex,
                  this.selectedWeekForGeneration
                );
                if (zz.calendarEelj) {
                  var date = this._getRightDate(
                    zz.dayIndex,
                    this.selectedWeekForGeneration
                  );
                  if (date) {
                    var dateStr = date.year + "-" + date.month + "-" + date.day;
                    if (zz.lessonGroup && !zz.lessonGroup.allStudentsSelected) {
                      // console.log(zz.lessonGroup.ref.path);
                      zz.lessonGroup.ref
                        .collection("students")
                        .get()
                        .then((docs) => {
                          var studentsIds = docs.docs.map((dd) =>
                            String(dd.data().PERSON_ID)
                          );
                          // console.log("studentsIds",studentsIds, classGroup.classGroupRef.path)
                          var allStats = {
                            studentsAbsent: 0,
                            studentsExcused: 0,
                            studentsSick: 0,
                            studentsOnline: 0,
                            studentsPresent: docs.size,
                            totalStudents: docs.size,
                          };
                          classGroup.classGroupRef
                            .collection(
                              "attendances-" + this.userData.school.currentYear
                            )
                            // .where("studentId", "in", studentsIds)
                            .where("month", "==", date.month)
                            .where("day", "==", date.day)
                            .get()
                            .then((docs) => {
                              var _details = [];
                              docs.forEach((doc) => {
                                // console.log(
                                //   "att",
                                //   doc.data().studentId,
                                //   doc.data()
                                // );
                                var stat = doc.data();
                                var foundStudentId = studentsIds.find(
                                  (ss) => ss == stat.studentId
                                );
                                if (foundStudentId) {
                                  if (stat.status == -4) {
                                    allStats["studentsAbsent"]++;
                                    allStats["studentsPresent"]--;
                                  } else if (stat.status == -2) {
                                    allStats["studentsExcused"]++;
                                    allStats["studentsPresent"]--;
                                  } else if (stat.status == -3) {
                                    allStats["studentsSick"]++;
                                    allStats["studentsPresent"]--;
                                  } else if (stat.status == -5) {
                                    allStats["studentsOnline"]++;
                                    allStats["studentsPresent"]--;
                                  }
                                  _details.push(stat);
                                }
                              }); //forEach end
                              allStats._details = _details;
                            });
                          this.generatedIrzuud.push({
                            classGroup: classGroup,
                            lesson: lesson,
                            dailyIrz: allStats,
                            dateStr: dateStr,
                            date: date,
                            calendarData: zz,
                            totalStudents: docs.size,
                          });
                        });
                    } else {
                      classGroup.classGroupRef
                        .collection(
                          "attendances-" + this.userData.school.currentYear
                        )
                        .doc(dateStr)
                        .get()
                        .then((doc) => {
                          if (doc.exists) {
                            let dailyIrz = doc.data();
                            dailyIrz.id = doc.id;
                            dailyIrz.ref = doc.ref;
                            // console.log("dailyIrz",
                            //   dateStr,
                            //   zz.dayIndex,
                            //   zz.xeelj,
                            //   zz,
                            //   dailyIrz
                            // );
                            this.generatedIrzuud.push({
                              classGroup: classGroup,
                              lesson: lesson,
                              dailyIrz: dailyIrz,
                              dateStr: dateStr,
                              date: date,
                              calendarData: zz,
                            });
                          } else {
                            // there is no daily irz done
                            this.userData.school.ref
                              .collection(
                                "students-" + this.userData.school.currentYear
                              )
                              .where(
                                "STUDENT_GROUP_ID",
                                "==",
                                classGroup.programData.STUDENT_GROUP_ID
                              )
                              .get()
                              .then((docs) => {
                                var totalStudents = 0;
                                docs.docs.forEach((doc) => {
                                  if (!doc.data().moved) totalStudents++;
                                });
                                this.generatedIrzuud.push({
                                  classGroup: classGroup,
                                  lesson: lesson,
                                  dailyIrz: null,
                                  totalStudents: totalStudents,
                                  dateStr: dateStr,
                                  date: date,
                                  calendarData: zz,
                                });
                              });
                          }
                        });
                    }
                  }
                }
              });
              this.loadingGenerateIrzuud = false;
            });
        }
      }
    },
    _generateIrzAlert() {
      this.$swal.fire(
        "Багшийн журнал ЦЭС руу ороод улирал, xичээлээ сонгоод шинэ xувилбарыг ашиглаарай!"
      );
    },
    _generateIrz(week) {
      this.generatedIrzuud = null;
      this.selectedWeekForGeneration = week;
      this.showGenerateProcessDialog = true;
      this.loadingGenerateIrzuud = true;
      if (this.currentSelectedLesson) {
        this._generateIrzLesson(week, this.currentSelectedLesson);
      } else {
        this.filteredLessons.forEach((lesson) => {
          this._generateIrzLesson(week, lesson);
        });
      }
    },
    _printStatuses() {
      console.log(this.studentsStatuses2);
    },
    _printTeacherAttendances() {
      for (const cc of this.teacherAttendances) {
        console.log(cc.month, cc.day, cc.xeelj, cc.selectedLesson);
      }
    },
    _showAllIrz() {
      console.log("dljfsjdfjsdf");
      this.currentSelectedClassGroup = null;
      this.temporarlySelectedLesson = null;
      this.currentSelectedLesson = null;
      this.temporarlySelectedLesson = null;
      this.showingAll = true;
      console.log(
        "AFTER",
        this.existingExecutionRef,
        this.selectedExecution,
        this.currentSelectedClassGroup,
        this.temporarlySelectedLesson,
        this.currentSelectedLesson
      );
    },
    _addIrz() {
      this.studentsStatuses2 = [];
      this.selectedLessonGroupIndex = null;
      this.temporarlySelectedLesson = this.currentSelectedLesson;
      this.currentSelectedClassGroup =
        this.temporarlySelectedLesson.classGroups[0];
      this.newIrzDialog = !this.newIrzDialog;
      //abc
    },
    _getAttendancesForAtt(execution, classGroupRef, statuses) {
      classGroupRef
        .collection("attendances-" + this.userData.school.currentYear)
        .where("daily_attendance", "==", false)
        .get()
        .then((docs) => {
          for (const doc of docs.docs) {
            let att = doc.data();
            att.id = doc.id;
            att.ref = doc.ref;
            if (
              (att.studentId,
              execution.month == att.month &&
                execution.day == att.day &&
                execution.xeelj == att.xeelj)
            ) {
              console.log("retriveing att", att.month, att.day, att.xeelj, att);
              statuses.push(att);
            }
          }
        });
    },
    async _editAttendances(attendance) {
      console.log(attendance.ref.path, attendance);
      this.studentsStatuses2 = [];
      attendance.selectedLesson.editing = true;
      this.editingAttendances = true;
      if (
        attendance.selectedGroupIndex &&
        Number.isInteger(attendance.selectedGroupIndex)
      ) {
        this.selectedLessonGroupIndex = attendance.selectedGroupIndex;
      } else if (
        attendance.selectedGroupIndex &&
        attendance.selectedGroupIndex.groupIndex
      ) {
        this.selectedLessonGroupIndex =
          attendance.selectedGroupIndex.groupIndex;
      }
      await attendance.ref.parent.parent.get().then((doc) => {
        let lesson = doc.data();
        lesson.ref = doc.ref;
        lesson.id = doc.id;
        this.temporarlySelectedLesson = lesson;
        this.temporarlySelectedLesson.subLessons = attendance.subLessons;
        if (
          this.temporarlySelectedLesson.esisLessonType &&
          this.temporarlySelectedLesson.esisLessonType.esisLessonTypeId == 2
        ) {
          this.temporarlySelectedLesson.isSubGrouped = true;
        }
        this.selectedLessonGroup = attendance.selectedGroupIndex;
      });
      if (attendance.isWithSublessons) {
        this.temporarlySelectedLesson.isCollected = true;
        if (this.temporarlySelectedLesson.isSubGrouped)
          delete this.temporarlySelectedLesson.isSubGrouped;
        this.selectedLessonGroup = attendance.selectedGroupIndex;
      }
      this.temporarlySelectedLesson.ref = attendance.ref.parent.parent;
      this.currentSelectedClassGroup = attendance.selectedClassGroup;

      if (
        attendance.selectedLesson.esisLessonType &&
        (attendance.selectedLesson.esisLessonType.esisLessonTypeId == 3 ||
          attendance.isWithSublessons)
      ) {
        if (attendance.selectedClassGroup == undefined) {
          attendance.selectedClassGroup =
            attendance.selectedLesson.classGroups[0];
          this.currentSelectedClassGroup =
            attendance.selectedLesson.classGroups[0];
        } else {
          this.currentSelectedClassGroup = attendance.selectedClassGroup;
        }
      }
      if (attendance.subLessons) {
        this.studentsStatuses2 = [];
        for (const lesson of attendance.subLessons) {
          console.log("lesson.xxxxx", lesson.id);
          this._getAttendancesForAtt(
            attendance,
            lesson.classGroups[0].classGroupRef,
            this.studentsStatuses2
          );
        }
      }
      attendance.selectedClassGroup.editing = true;
      this.selectedDay = attendance.day;
      this.selectedMonth = attendance.month;
      this.selectedYear = attendance.year;
      this.selectedEelj = attendance.xeelj;
      this.selectedExecution = attendance;
    },
    _getConsideredStudents() {
      var lessonStudents = null;
      if (
        this.temporarlySelectedLesson &&
        this.temporarlySelectedLesson.isCollected
      ) {
        lessonStudents = this.students.filter(
          (stud) =>
            this.selectedLessonGroupIndex &&
            this.selectedLessonGroupIndex == stud.lessonGroup.groupIndex
        );
      } else if (
        this.temporarlySelectedLesson &&
        this.temporarlySelectedLesson.isSubGrouped
      ) {
        //for pod groups
        lessonStudents = this.students.filter(
          (stud) =>
            this.selectedLessonGroupIndex &&
            this.selectedLessonGroupIndex == stud.lessonGroup.groupIndex
        );
        console.log("tttttttnntnnttntttnnntnt", lessonStudents.length);
      } else {
        lessonStudents =
          this.students[this.currentSelectedClassGroup.classGroupRef.path];
      }
      if (lessonStudents.length == 0 && this.students.length > 0) {
        lessonStudents = this.students;
      }
      return lessonStudents;
    },

    _closeAfterSaveIrz() {
      this.generateForNextEelj = false;
      this.selectedMonth = new Date().getMonth() + 1;
      this.selectedDay = new Date().getDate();
      this.selectedEelj = null;

      this.existingExecutionRef = null;
      this.selectedExecution = null;

      this.currentSelectedClassGroup = null;
      this.temporarlySelectedLesson = null;
      // if (this.showingAll) this.currentSelectedLesson = null;

      this.newIrzDialog = false;
      this.irzBurtgehDialog = false;
      this.editingAttendances = false;

      console.log(
        "AFTER",
        this.existingExecutionRef,
        this.selectedExecution,
        this.currentSelectedClassGroup,
        this.temporarlySelectedLesson,
        this.currentSelectedLesson
      );
    },
    async _createNewIrz(lessonIrz, batch) {
      var createdDate = new Date();
      createdDate.setFullYear(Number(this.selectedYear));
      createdDate.setMonth(Number(this.selectedMonth - 1));
      createdDate.setDate(Number(this.selectedDay));
      createdDate.setHours(lessonIrz);

      var executionRef = this.selectedExecution
        ? this.selectedExecution.ref
        : this.temporarlySelectedLesson.ref.collection("executions").doc();
      if (this.existingExecutionRef) {
        // console.log(this.existingExecutionRef); //333
        executionRef = this.existingExecutionRef;
      }
      var classStudents = this._getConsideredStudents();
      // this.students[this.currentSelectedClassGroup.classGroupRef.path]
      // console.log("studentsStatuses2studentsStatuses2studentsStatuses2", this.studentsStatuses2, this.selectedMonth, this.selectedDay, classStudents, lessonIrz)

      var studentsAbsent2 = 0;
      var studentsSick2 = 0;
      var studentsExcused2 = 0;
      var studentsOnline2 = 0;
      var studentsPresent2 = classStudents.length;
      var totalStudents2 = classStudents.length;

      var studentsNoNotebook = 0;
      var studentsDisturbing = 0;
      var studentsNoPen = 0;

      // console.log(studentsAbsent2,
      //   studentsSick2,
      //   studentsExcused2,
      //   studentsOnline2,
      //   studentsPresent2,
      //   totalStudents2)

      for (const statusFound of this.studentsStatuses2) {
        if (statusFound.studentId) {
          var studentFound = classStudents.find(
            (stud) => stud.PERSON_ID == statusFound.studentId
          );
          if (!studentFound) continue;
          if (
            statusFound &&
            statusFound.daily_attendance == true &&
            this.editingAttendances
          ) {
            continue;
          }
          if (statusFound) {
            if (statusFound.text == "Тасалсан") {
              studentsAbsent2++;
              studentsPresent2--;
            } else if (statusFound.text == "Өвчтэй") {
              studentsSick2++;
              studentsPresent2--;
            } else if (statusFound.text == "Чөлөөтэй") {
              studentsExcused2++;
              studentsPresent2--;
            } else if (statusFound.text == "Зайнаас") {
              studentsOnline2++;
              studentsPresent2--;
            }
          }
          if (statusFound) {
            var docName =
              this.selectedYear +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedDay +
              "-" +
              lessonIrz +
              "-" +
              1 +
              "-" +
              String(statusFound.studentId);

            statusFound["xeelj"] = lessonIrz;
            // statusFound["calendarEelj"] = this.selectedCalDataForIrz.calendarEelj
            //classGroups
            //courseInfo
            //lesson
            //lessonRef
            var newStudentStatusData = {
              createdByName: this.userData.DISPLAY_NAME
                ? this.userData.DISPLAY_NAME
                : this.userData.firstName,
              createdByTeacherRef: this.userData.ref,
              daily_attendance: false,
              day: this.selectedDay,
              month: this.selectedMonth,
              status: statusFound.status,
              studentId: String(statusFound.studentId),
              text: statusFound.text,
              xeelj: lessonIrz,
              year: this.selectedYear,
              calendarEelj: null,
              FIRST_NAME: studentFound ? studentFound["FIRST_NAME"] : null,
              LAST_NAME: studentFound ? studentFound["LAST_NAME"] : null,
            };
            console.log("newStudentStatusData", newStudentStatusData);
            var docRef = studentFound.classGroupRef
              .collection("attendances-" + this.userData.school.currentYear)
              .doc(docName);
            batch.set(docRef, newStudentStatusData, { merge: true });
          }
        } else {
          console.log("daily", statusFound);
        }
      } // end of loop statuses
      //abc
      var executionData = {
        calendarSchoolEelj: this.selectedSchoolEelj,
        createdAt: new Date(),
        closed: true,
        closedBy: this.teacherData.ref,
        day: this.selectedDay,
        month: this.selectedMonth,
        xeelj: lessonIrz,
        teacherRef: this.teacherData.ref,
        // classGroupRef: this.currentSelectedClassGroup.classGroupRef,
        // selectedLesson: this.temporarlySelectedLesson,
        // selectedClassGroup: this.currentSelectedClassGroup,
        // departmentRef: this.currentSelectedClassGroup.departmentRef,
        year: this.selectedYear,
        deleted: false,
        studentsSick: studentsSick2,
        studentsAbsent: studentsAbsent2,
        studentsExcused: studentsExcused2,
        studentsOnline: studentsOnline2,
        studentsPresent: studentsPresent2,
        studentsNoNotebook: studentsNoNotebook,
        studentsDisturbing: studentsDisturbing,
        studentsNoPen: studentsNoPen,
        selectedLessonGroup: this.foundLessonGroup,
        teachedAt: createdDate,
        totalStudents: totalStudents2,
        //222
      };
      this._setSemeter(executionData, false);
      if (this.temporarlySelectedLesson.isCollected) {
        executionData["classGroupRef"] = null;
        executionData["courseInfo"] = this.temporarlySelectedLesson.courseInfo;
        executionData["selectedClassGroup"] = {};
        executionData["departmentRef"] = null;
        executionData["selectedLesson"] = {
          courseInfo: this.temporarlySelectedLesson.courseInfo,
        };
        executionData["isWithSublessons"] = true;
        executionData["selectedGroupIndex"] = this.selectedLessonGroupIndex;
        executionData["subLessons"] = [];
        if (this.temporarlySelectedLesson.subLessons) {
          this.temporarlySelectedLesson.subLessons.forEach((el) => {
            if (el.classGroups) {
              var eeel = Object.assign({}, el);
              eeel.subLessons = null;
              executionData["subLessons"].push(eeel);
            }
          });
        }
        console.log(
          "this.temporarlySelectedLesson.subLessons",
          this.temporarlySelectedLesson.subLessons
        );
        executionData["lessonGroups"] = this.selectedLessonGroups.filter(
          (gg) => gg.groupIndex == this.selectedLessonGroupIndex
        );
        //saving collected Lesson for selectedStudents
        var ddRef = this.temporarlySelectedLesson.ref.parent.doc(
          this.temporarlySelectedLesson.courseInfo.COURSE_ID.toString()
        );
        batch.set(
          ddRef,
          {
            courseInfo: this.temporarlySelectedLesson.courseInfo,
            createdAt: new Date(),
            isWithSublessons: true,
          },
          {
            merge: true,
          }
        );
        batch.set(executionRef, executionData, { merge: true });
        // await this.temporarlySelectedLesson.ref.parent
        // .doc(this.temporarlySelectedLesson.courseInfo.COURSE_ID.toString())
        // .set(
        //   {
        //     courseInfo: this.temporarlySelectedLesson.courseInfo,
        //     createdAt: new Date(),
        //     isWithSublessons: true,
        //   },
        //   {
        //     merge: true,
        //   }
        // )
        // .then(() => {
        //   batch.set(executionRef, executionData, { merge: true });
        // });
      } else if (
        this.temporarlySelectedLesson &&
        this.temporarlySelectedLesson.isSubGrouped
      ) {
        executionData["classGroupRef"] =
          this.currentSelectedClassGroup.classGroupRef;
        executionData["selectedLesson"] = Object.assign(
          {},
          this.temporarlySelectedLesson
        );
        delete executionData["selectedLesson"].subLessons;
        delete executionData["selectedLesson"].calendarData;

        executionData["selectedClassGroup"] = this.currentSelectedClassGroup;
        executionData["departmentRef"] =
          this.currentSelectedClassGroup.departmentRef;
        executionData["isSubGrouped"] = true;
        executionData["selectedGroupIndex"] = this.selectedLessonGroupIndex;
        executionData["groupIndex"] = this.selectedLessonGroupIndex;

        batch.set(executionRef, executionData, { merge: true });
      } else {
        executionData["classGroupRef"] =
          this.currentSelectedClassGroup.classGroupRef;
        executionData["selectedLesson"] = this.temporarlySelectedLesson;
        delete executionData["selectedLesson"].subLessons;
        delete executionData["selectedLesson"].calendarData;

        executionData["selectedClassGroup"] = this.currentSelectedClassGroup;
        executionData["departmentRef"] =
          this.currentSelectedClassGroup.departmentRef;
        batch.set(executionRef, executionData, { merge: true });
      }
    },
    saveNewIrz() {
      var batch = fb.db.batch();
      this._createNewIrz(this.selectedEelj, batch);
      if (this.generateForNextEelj) {
        this._createNewIrz(this.selectedEelj + 1, batch);
      }
      batch.commit().then(() => {
        this._closeAfterSaveIrz();
      });
    },
    findStudents(classGroupRef, editing) {
      this.loading = true;
      this.teacherData.school.ref
        .collection("students-" + this.teacherData.school.currentYear)
        .where(
          "classGroup-" + this.teacherData.school.currentYear,
          "==",
          classGroupRef
        )
        .orderBy("firstName", "asc")
        .get()
        .then((docs) => {
          this.students[classGroupRef.path] = [];
          docs.forEach(async (doc) => {
            let student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            if (!student.moved) {
              student.classGroupRef = classGroupRef;
              student.attendances = null;
              student.studentsNotes = null;
              await student.classGroupRef
                .collection(
                  "attendances-" + this.teacherData.school.currentYear
                )
                .where("studentId", "==", student.id)
                .where("xeelj", "==", this.selectedEelj)
                // this.selectedDay, this.selectedMonth, this.selectedEelj
                .onSnapshot((docs) => {
                  student.attendances = [];
                  student.studentsNotes = [];
                  if (!docs.empty) {
                    docs.forEach((doc) => {
                      if (doc.exists) {
                        let x = doc.data();
                        x.ref = doc.ref;
                        x.id = doc.id;
                        // console.log("statussss", x.ref.path, x.text)
                        if (x.id.startsWith("studentsNotes"))
                          student.studentsNotes.push(x);
                        else {
                          student.attendances.push(x);
                        }
                      }
                    });
                  }
                });

              this.students[classGroupRef.path].push(student);
            }
          });
          if (editing) {
            this.irzBurtgehDialog = true;
          }
          this.loading = false;
        });
    },
    async findGroupStudents(editing) {
      console.log(this.temporarlySelectedLesson);
      console.log(this.currentSelectedLesson);
      console.log(this.currentSelectedClassGroup);
      this.loading = true;
      this.students = [];
      this.temporarlySelectedLesson.lessonGroups = null;
      this.temporarlySelectedLesson.ref
        .collection("lesson-groups")
        .where("teacher.teacherId", "==", this.teacherData.id)
        .get()
        .then((docs) => {
          // this.temporarlySelectedLesson.lessonGroups = [];
          this.selectedLessonGroups = [];
          docs.forEach((doc) => {
            let lessonGroup = doc.data();
            lessonGroup.ref = doc.ref;
            lessonGroup.id = doc.id;
            // lessonGroup.lesson = lesson;
            console.log(lessonGroup);
            this.selectedLessonGroups.push(lessonGroup);

            if (
              this.selectedLessonGroup == undefined ||
              this.selectedLessonGroup == null
            ) {
              this.selectedLessonGroup = lessonGroup;
            }

            if (lessonGroup.allStudentsSelected) {
              console.log(this.temporarlySelectedLesson.ref.path);
              console.log(this.currentSelectedLesson);

              this.teacherData.school.ref
                .collection("students-" + this.teacherData.school.currentYear)
                .where(
                  "classGroup-" + this.teacherData.school.currentYear,
                  "==",
                  this.temporarlySelectedLesson.classGroupRefs[0]
                )
                .orderBy("firstName", "asc")
                .get()
                .then((docs) => {
                  docs.forEach(async (doc) => {
                    let student = doc.data();
                    student.id = doc.id;
                    student.ref = doc.ref;
                    if (!student.moved) {
                      let student = doc.data();
                      student.id = doc.id;
                      student.ref = doc.ref;
                      student.attendances = null;
                      student.studentsNotes = null;
                      student.requests = null;
                      student.lessonGroup = lessonGroup;
                      student.classGroupRef =
                        this.temporarlySelectedLesson.classGroupRefs[0];
                      this.temporarlySelectedLesson.classGroupRefs[0]
                        .collection(
                          "attendances-" + this.teacherData.school.currentYear
                        )
                        .where("studentId", "==", student.id)
                        .onSnapshot((docs) => {
                          student.attendances = [];
                          student.studentsNotes = [];
                          if (!docs.empty) {
                            docs.forEach((doc) => {
                              if (doc.exists) {
                                let x = doc.data();
                                x.ref = doc.ref;
                                x.id = doc.id;
                                if (x.id.startsWith("studentsNotes"))
                                  student.studentsNotes.push(x);
                                else student.attendances.push(x);
                              }
                            });
                          }
                        });
                      // student.ref.parent.parent
                      //   .collection("requests")
                      //   .where("requestedBy", "==", student.ref)
                      //   .onSnapshot((docs) => {
                      //     student.requests = [];
                      //     if (!docs.empty) {
                      //       docs.forEach((doc) => {
                      //         let req = doc.data();
                      //         req.id = doc.id;
                      //         req.ref = doc.ref;
                      //         student.requests.push(req);
                      //       });
                      //     }
                      //   });
                      this.students.push(student);
                    }
                  });
                });
            } else {
              lessonGroup.ref
                .collection("students")
                .orderBy("FIRST_NAME", "asc")
                .get()
                .then((docs) => {
                  docs.forEach((doc) => {
                    let student = doc.data();
                    student.id = doc.id;
                    student.ref = doc.ref;
                    student.attendances = null;
                    student.studentsNotes = null;
                    student.requests = null;
                    student.lessonGroup = lessonGroup;
                    student.classGroupRef =
                      this.temporarlySelectedLesson.classGroupRefs[0];
                    this.temporarlySelectedLesson.classGroupRefs[0]
                      .collection(
                        "attendances-" + this.teacherData.school.currentYear
                      )
                      .where("studentId", "==", student.id)
                      .onSnapshot((docs) => {
                        student.attendances = [];
                        student.studentsNotes = [];
                        if (!docs.empty) {
                          docs.forEach((doc) => {
                            if (doc.exists) {
                              let x = doc.data();
                              x.ref = doc.ref;
                              x.id = doc.id;
                              if (x.id.startsWith("studentsNotes"))
                                student.studentsNotes.push(x);
                              else student.attendances.push(x);
                            }
                          });
                        }
                      });
                    if (!student.moved) this.students.push(student);
                  });
                  if (editing) {
                    this.irzBurtgehDialog = true;
                  }
                });
            }

            this.loading = false;
            // this.temporarlySelectedLesson.lessonGroups.push(lessonGroup);
          });
        });
    },
    async findGroupStudentsFromDifferentClasses(editing) {
      console.log(this.temporarlySelectedLesson);
      this.loading = true;
      if (this.temporarlySelectedLesson.subLessons) {
        this.students = [];
        this.selectedLessonGroups = [];
        for (const sll of this.temporarlySelectedLesson.subLessons) {
          console.log(sll.classGroups[0].classGroupName, sll.ref.path);
          sll.ref
            .collection("lesson-groups")
            .where("teacher.teacherId", "==", this.teacherData.id)
            .get()
            .then((docs) => {
              docs.forEach(async (doc) => {
                let gg = doc.data();
                gg.id = doc.id;
                gg.ref = doc.ref;
                // console.log(
                //   gg.groupIndex,
                //   sll.classGroups[0].classGroupName,
                //   gg.name,
                //   gg.ref.path
                // );
                await gg.ref
                  .collection("students")
                  .orderBy("FIRST_NAME", "asc")
                  .get()
                  .then((docs) => {
                    docs.forEach((doc) => {
                      let student = doc.data();
                      student.id = doc.id;
                      student.ref = doc.ref;
                      student.classGroup = sll.classGroups[0];
                      student.classGroupRef = sll.classGroups[0].classGroupRef;
                      student.lessonGroup = gg;
                      student.attendances = null;
                      student.studentsNotes = null;
                      student.requests = null;
                      sll.classGroupRefs[0]
                        .collection(
                          "attendances-" + this.teacherData.school.currentYear
                        )
                        .where("studentId", "==", student.id)
                        .onSnapshot((docs) => {
                          student.attendances = [];
                          student.studentsNotes = [];
                          if (!docs.empty) {
                            docs.forEach((doc) => {
                              if (doc.exists) {
                                let x = doc.data();
                                x.ref = doc.ref;
                                x.id = doc.id;
                                if (x.id.startsWith("studentsNotes"))
                                  student.studentsNotes.push(x);
                                else student.attendances.push(x);
                              }
                            });
                          }
                        });
                      this.students.push(student);
                    });
                  });
                this.selectedLessonGroups.push(gg);
              });
            });
        }
        if (editing) {
          console.log(this.selectedLessonGroups);
          this.irzBurtgehDialog = true;
        }
      }
      this.loading = false;
    },
    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },
    async _setupp() {
      if (this.teacherData.school) {
        var query = this.teacherData.school.ref
          .collection("lessons-" + this.teacherData.school.currentYear)
          .where("deleted", "==", false);
        if (this.teacherData.role == "teacher") {
          query = query.where(
            "teacherRefs",
            "array-contains",
            this.teacherData.ref
          );
        }
        query = query.orderBy("courseInfo.COURSE_NAME", "desc");
        query.get().then((docs) => {
          this.lessons = [];
          var counter = 0;
          docs.forEach((doc) => {
            counter++;
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            lesson.topics = null;
            // if (lesson.classGroups == undefined || lesson.isGroupBased) {
            //   lesson.classGroups = [];
            // }
            lesson.name2 = lesson.courseInfo.COURSE_NAME;
            lesson.name3 = counter + ". " + lesson.courseInfo.COURSE_NAME;

            lesson.classGroups
              ? lesson.classGroups.forEach((a) => {
                  lesson.name3 =
                    lesson.name3 +
                    " - " +
                    (a.programData.STUDENT_GROUP_NAME
                      ? a.programData.STUDENT_GROUP_NAME
                      : a.programData.fullName
                    ).toUpperCase();
                })
              : "";
            this.isAllowedLesson(lesson);
            if (!lesson.isNotAllowed) this.lessons.push(lesson);
          });
          this.fetchNextPage(false);
        });
      }
    },

    _saveLessonType() {
      this.currentSelectedLesson.ref.update({
        esisLessonType: this.selectedEsisLessonType,
        esisLessonTypeChangedByRef: this.userData.ref,
        esisLessonTypeChangedByName: this.userData["DISPLAY_NAME"]
          ? this.userData["DISPLAY_NAME"]
          : this.userData.email,
      });
      this.showChangeLessonTypeDialog = !this.showChangeLessonTypeDialog;
    },
    _changeLessonType() {
      this.selectedEsisLessonType = Object.assign(
        {},
        this.currentSelectedLesson.esisLessonType
      );
      this.showChangeLessonTypeDialog = !this.showChangeLessonTypeDialog;
    },
    _showStatuses(editing) {
      var xx = {
        numberOfExcuse: 0,
        numberOfSick: 0,
        numberOfAbsent: 0,
        numberOfPresent: 0,
        numberOfOnline: 0,
      };
      if (this.studentsStatuses2) {
        for (const cc of this.studentsStatuses2) {
          if (editing && cc.daily_attendance == false) {
            if (cc.status == -2) {
              xx["numberOfExcuse"]++;
              continue;
            } else if (cc.status == -3) {
              xx["numberOfSick"]++;
              continue;
            } else if (cc.status == -4) {
              xx["numberOfAbsent"]++;
              continue;
            } else if (cc.status == -5) {
              xx["numberOfOnline"]++;
              continue;
            }
          } else if (editing == false && cc.daily_attendance == true) {
            if (cc.status == -2) {
              xx["numberOfExcuse"]++;
              continue;
            } else if (cc.status == -3) {
              xx["numberOfSick"]++;
              continue;
            } else if (cc.status == -4) {
              xx["numberOfAbsent"]++;
              continue;
            } else if (cc.status == -5) {
              xx["numberOfOnline"]++;
              continue;
            }
          }
        }
      }

      return (
        "Чөлөөтэй: " +
        xx["numberOfExcuse"] +
        ", Өвчтэй: " +
        xx["numberOfSick"] +
        ", Тасалсан: " +
        xx["numberOfAbsent"] +
        ", Зайнаас: " +
        xx["numberOfOnline"]
      );
    },
    _getAllClassGroupsFromLessons() {
      var allGroups1 = [];
      var allGroups2 = [];
      var allGroups3 = [];
      for (const ll of this.lessons) {
        if (ll.esisLessonType.esisLessonTypeId == 1) {
          for (const cg of ll.classGroups) {
            var found = allGroups1.find(
              (c) => c.classGroupRef.id == cg.classGroupRef.id
            );
            if (!found) {
              allGroups1.push(cg);
            }
          }
        } else if (ll.esisLessonType.esisLessonTypeId == 2) {
          for (const cg of ll.classGroups) {
            var found2 = allGroups2.find(
              (c) => c.classGroupRef.id == cg.classGroupRef.id
            );
            if (!found2) {
              allGroups2.push(cg);
            }
          }
        } else if (ll.esisLessonType.esisLessonTypeId == 3) {
          for (const cg of ll.classGroups) {
            var found3 = allGroups3.find(
              (c) => c.classGroupRef.id == cg.classGroupRef.id
            );
            if (!found3) {
              allGroups3.push(cg);
            }
          }
        }
      }
      console.log(
        this.userData.school.currentYear,
        allGroups1.length,
        allGroups2.length,
        allGroups3.length
      );
      this._getAllStudents();
      // var weekDays = this._getWeekBeginEnd000(this.currentSelectedWeek);
      // console.log(weekDays);
      for (const cg of allGroups1) {
        console.log(cg.classGroupName, cg);
        cg.dailyIrzuud = null;
        cg.classGroupRef
          .collection("attendances-" + this.userData.school.currentYear)
          .where("month", "in", [1, 2, 3])
          .where("closed", "==", true)
          .get()
          .then((docs) => {
            cg.dailyIrzuud = [];
            docs.forEach((doc) => {
              let dailyIrz = doc.data();
              dailyIrz.id = doc.id;
              dailyIrz.ref = doc.ref;
              cg.dailyIrzuud.push(dailyIrz);
            });
          });
      }
    },
    async _getAllStudents() {
      var counter = 0;
      for (const ll of this.lessons) {
        if (ll.esisLessonType.esisLessonTypeId == 2) {
          // ll.ref.collection("lesson-groups")
          // .where("teacher.teacherId", "==", this.teacherData.id).get((docs)=>{
          //   console.log(docs)
          // })
          ll.ref
            .collection("lesson-groups")
            .where("teacher.teacherId", "==", this.teacherData.id)
            .where("groupIndex", ">=", 1)
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                let gg = doc.data();
                gg.id = doc.id;
                gg.ref = doc.ref;
                gg.ref
                  .collection("students")
                  .get()
                  .then((docs) => {
                    docs.forEach((doc) => {
                      // counter++
                      let stud = doc.data();
                      stud.id = doc.id;
                      stud.ref = doc.ref;
                      stud.lessonGroup = gg;
                      stud.attendances = null;
                      // console.log(stud, ll.classGroups[0].classGroupRef.path)
                      ll.classGroups[0].classGroupRef
                        .collection("attendances-"+this.userData.school.currentYear)
                        .where("studentId", "==", String(stud.PERSON_ID))
                        .where("month", "in", [2, 3])
                        .get()
                        .then((docs) => {
                          stud.attendances = [];
                          docs.forEach((doc) => {
                            counter++;
                            let att = doc.data();
                            att.id = doc.id;
                            att.ref = doc.ref;
                            console.log(
                              counter,
                              stud.STUDENT_GROUP_NAME,
                              stud.FIRST_NAME,
                              att.month,
                              att.day,
                              att.text,
                              ll.classGroups[0]
                            );
                            stud.attendances.push(att);
                          });
                        });
                    });
                  });
              });
            });
          //console.log(counter, ll.ref.path, ll.esisLessonType.esisLessonTypeId)
        }
        //.collection("lesson-groups")
        //.where("teacher.teacherId", "==", this.teacherData.id)
      }
    },
    //calendariiiiii
    _getWeeks() {
      var xx = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (xx) return xx.weeks;
      else return null;
    },
    findWorkingWeekDays() {
      this.currentWeekDays = [];
      this.attendances = [];
      this.dailyAttendances = [];
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
    //end of calenariiii

    _print2(day, xeelj) {
      var classGroups = this._getClassGroupsFromCalData(day, xeelj);
      for (var cc of classGroups) {
        console.log(cc.classGroupFullName, cc.classGroupRef.id);
      }
    },
    getCalendarData() {
      this.loadingCalendarData = true;
      this.teacherData.school.ref
        .collection("lessons-" + this.teacherData.school.currentYear)
        .where("teacherRefs", "array-contains", this.teacherData.ref)
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .onSnapshot((docs) => {
          this.lessons = [];
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.id = doc.id;
            lesson.ref = doc.ref;
            lesson.lessonGroups = null;
            lesson.calendarData = null;
            lesson.name2 = lesson.courseInfo.COURSE_NAME;
            this.calendarData = [];
            this.isAllowedLesson(lesson);
            if (!lesson.isNotAllowed) {
              if (lesson.classGroups) {
                for (var classGroup of lesson.classGroups) {
                  var query = classGroup.classGroupRef
                    .collection(
                      "calendar-" +
                        this.userData.school.currentYear +
                        "-" +
                        this.currentSelectedSemester
                    ) //1127
                    .where(
                      "courseInfo.COURSE_ID",
                      "==",
                      lesson.courseInfo.COURSE_ID
                    );
                  if (
                    lesson.esisLessonType &&
                    lesson.esisLessonType.esisLessonTypeId > 1
                  ) {
                    query = query.where(
                      "lessonGroup.teacher.teacherId",
                      "==",
                      this.teacherData.id
                    );
                  }
                  query
                    .orderBy("dayIndex", "asc")
                    .orderBy("xeelj", "asc")
                    .get()
                    .then((docs) => {
                      lesson.calendarData = [];
                      this.fixProblems = [];
                      docs.docs.forEach((doc) => {
                        let zz = doc.data();
                        zz.id = doc.id;
                        zz.ref = doc.ref;
                        zz.classGroups = [];
                        if (zz.calendarEelj) {
                          var xx = this.fixProblems.find(
                            (eelj) => eelj.eeljId == zz.calendarEelj.eeljId
                          );
                          if (!xx) this.fixProblems.push(zz.calendarEelj);
                          lesson.calendarData.push(zz);
                          this.calendarData.push(zz);
                          zz.classGroups.push(classGroup);
                        }
                      });
                    });
                }
              }
              lesson.ref.collection("lesson-groups").onSnapshot((docs) => {
                lesson.lessonGroups = [];
                docs.forEach((doc) => {
                  let lessonGroup = doc.data();
                  lessonGroup.ref = doc.ref;
                  lessonGroup.id = doc.id;
                  lesson.lessonGroups.push(lessonGroup);
                });
              });
              // if (lesson.classGroups && lesson.classGroups[0]) {
              //   var prog = await lesson.classGroups[0].classGroupRef.get();
              //   if (prog.data().meetLink)
              //     lesson.meetLink = prog.data().meetLink;
              // }
              this.lessons.push(lesson);
            }
          });
          this.loadingCalendarData = false;
        });
    },

    _printSelectedLesson() {
      console.log(this.currentSelectedLesson);
      var xx = this._getCurrentSchoolWeekNumber();
      console.log(xx);
    },
    _getTotalTopics(week) {
      var total = 0;
      if (week && this.allData[week]) {
        total = this.allData[week].filter(
          (irz) => irz.isAllowed && irz.selectedParentTopic
        ).length;
      } else {
        for (const week2 of this.getCalendarWeeks) {
          if (this.allData[week2]) {
            total =
              total +
              this.allData[week2].filter(
                (irz) => irz.isAllowed && irz.selectedParentTopic
              ).length;
          }
        }
      }
      return total;
    },
    _getNumbers(week) {
      var numberOfTopic = 0;
      var selectedHomeWork = 0;
      var lessonNotes = 0;
      var lessonConclusion = 0;
      if (this.allData[week]) {
        for (var cc of this.allData[week]) {
          if (cc.selectedParentTopic) numberOfTopic++;
          if (cc.selectedHomeWork) selectedHomeWork++;
          if (cc.lessonNotes) lessonNotes++;
          if (cc.lessonConclusion) lessonConclusion++;
        }
      }
      return [numberOfTopic, selectedHomeWork, lessonNotes, lessonConclusion];
    },
    _getTotalHomeWork(week) {
      var total = 0;
      if (week && this.allData[week]) {
        total = this.allData[week].filter(
          (irz) => irz.isAllowed && irz.selectedHomeWork
        ).length;
      } else {
        for (const week2 of this.getCalendarWeeks) {
          if (this.allData[week2]) {
            total =
              total +
              this.allData[week2].filter(
                (irz) => irz.isAllowed && irz.selectedHomeWork
              ).length;
          }
        }
      }
      return total;
    },
    _getTotalNotes(week) {
      var total = 0;
      if (week && this.allData[week]) {
        total = this.allData[week].filter(
          (irz) => irz.isAllowed && irz.lessonNotes
        ).length;
      } else {
        for (const week2 of this.getCalendarWeeks) {
          if (this.allData[week2]) {
            total =
              total +
              this.allData[week2].filter(
                (irz) => irz.isAllowed && irz.lessonNotes
              ).length;
          }
        }
      }
      return total;
    },
    _getTotalLessonConclusion(week) {
      var total = 0;
      if (week && this.allData[week]) {
        total = this.allData[week].filter(
          (irz) => irz.isAllowed && irz.lessonConclusion
        ).length;
      } else {
        for (const week2 of this.getCalendarWeeks) {
          if (this.allData[week2]) {
            total =
              total +
              this.allData[week2].filter(
                (irz) => irz.isAllowed && irz.lessonConclusion
              ).length;
          }
        }
      }
      return total;
    },
    fixSubLessonExecutions() {
      this.$swal({
        title: "Илүү үүссэн xолимог xичээлийн ирцийг засаx уу?",
        text: "Олон бүлгээс нийлж нэг анги бол xичээл орж байгаа тоxиолдолд дунд нь нэг л ирц үүснэ, нэг даалгавар, нэг сэдэв бүртгэгдэнэ. Түр зуурын кодны алдаанаас автоматаар үүсгэx дээр бүлэг бүрд нь ирц үүсгэсэн тоxиолдол гарсан. Үүнээс илүү ирц үүссэн. Xэрэв илүү ирцийг цэвэрлэж устгаx бол энэxүү үйлдлийг ТИЙМ гэж xариулсан илүү ирцийг устгаж ирцийн бүртгэлээ xэвийн болгоно уу.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          for (var weekData in this.allData) {
            if (weekData != "undefined") {
              for (var att of this.allData[weekData]) {
                if (att.isSubGrouped) {
                  att.isWithSublessons = true;
                  delete att.isSubGrouped;
                  att.subLessons = [];
                  let lesson = Object.assign({}, this.currentSelectedLesson);
                  lesson.subLessons.forEach((subLesson) => {
                    att.subLessons.push({
                      byTeachers: subLesson.byTeachers,
                      classGroupRefs: subLesson.classGroupRefs,
                      classGroups: subLesson.classGroups,
                      courseInfo: subLesson.courseInfo,
                      createdAt: subLesson.createdAt,
                      deleted: subLesson.deleted,
                      departmentId: subLesson.departmentId,
                      description: subLesson.description,
                      esisLessonType: subLesson.esisLessonType,
                      id: subLesson.id,
                      isGroupBased: subLesson.isGroupBased,
                      lessonType: subLesson.lessonType,
                      lessonTypeName: subLesson.lessonTypeName,
                      name2: subLesson.name2,
                      name3: subLesson.name3,
                      programId: subLesson.programId,
                      programRef: subLesson.programRef,
                      ref: subLesson.ref,
                      teacherRefs: subLesson.teacherRefs,
                      year: subLesson.year,
                    });
                  });
                  att.selectedGroupIndex = att.selectedGroupIndex.groupIndex;
                  console.log(att);
                  att.cleaned = true;
                  att.ref.update(att);
                }
              }
            }
          }
        }
      });
    },

    printZaasanTsag() {
      var counter = 0;
      for (const xx of this.filteredSemesterAttendances) {
        counter++;
        console.log(
          xx.year + "-" + xx.month + "-" + xx.day,
          counter,
          xx,
          xx.id,
          xx.semester
        );
      }
    },
    printAllWeek(item) {
      for (const w of item) {
        if (!w.isAllowed)
          console.log(
            w.year + "-" + w.month + "-" + w.day,
            w.selectedLesson.courseInfo.COURSE_NAME
          );
      }
    },
    _getWeekBeginEnd000(week) {
      var list = [];
      if (this.currentSelectedSemester) {
        var ss = this.calendarButez.find(
          (ss) => ss.session == this.currentSelectedSemester
        );
        for (const mm of ss.months) {
          for (const dd of mm.days) {
            if (dd.weekNumber == week) {
              dd.month = mm.name;
              list.push(dd);
            }
          }
        }
      }
      return list;
    },
    getWeekBeginEndTD(week) {
      var list = this._getWeekBeginEnd000(week);
      if (list.length > 1) {
        return [
          list[0].month + "/" + list[0].day,

          list[list.length - 1].month + "/" + list[list.length - 1].day,
        ];
      } else return null;
    },
    getWeekBeginEnd(week) {
      var list = this._getWeekBeginEnd000(week);
      if (list.length > 1) {
        return (
          list[0].month +
          "/" +
          list[0].day +
          " - " +
          list[list.length - 1].month +
          "/" +
          list[list.length - 1].day
        );
      } else return null;
    },
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      // var year = date.getFullYear()
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      if (this.calendarButez) {
        this.calendarButez.forEach((sem) => {
          if (sem.months) {
            sem.months.forEach((mm) => {
              if (mm.name == month) {
                if (mm.days) {
                  mm.days.forEach((dd) => {
                    if (dd.day == day) {
                      cc = dd.weekNumber;
                      return;
                    }
                  });
                }
              }
            });
          }
        });
      }
      return cc;
    },
    _getWeekDates(week) {
      // var zz = this.calendarButez.find(ss=> ss.session== this.currentSelectedSemester)
      var s = this.calendarButez.find(
        (ss) => ss.session == this.currentSelectedSemester
      );
      var found = [];
      s.months.forEach((mm) => {
        mm.days.forEach((dd) => {
          if (dd.weekNumber == week) {
            dd.month = mm.name;
            found.push(dd);
          }
        });
      });
      return found;
    },

    _debug(week) {
      console.log(week);
      if (this.allData && this.allData[week]) {
        var counter = 0;
        for (var cc of this.allData[week].sort(
          (a, b) => b.teachedAt - a.teachedAt || b.xeelj - a.xeelj
        )) {
          if (cc.isAllowed) {
            counter++;
            console.log(
              counter,
              cc.year + "-" + cc.month + "-" + cc.day,
              cc.xeelj
            );
          }
        }
        console.log("----------------------");
        for (var xx of this.allData[week].sort(
          (a, b) => b.teachedAt - a.teachedAt || b.xeelj - a.xeelj
        )) {
          counter++;
          if (!xx.isAllowed)
            console.log(
              counter,
              xx.year + "-" + xx.month + "-" + xx.day,
              xx.xeelj,
              xx.isAllowed2,
              xx.semester,
              xx.ref.path
            );
        }
      }
    },
    _getWeekAttendances(week) {
      // console.log("currentSelectedLessoncurrentSelectedLessoncurrentSelectedLesson", this.currentSelectedLesson)
      if (this.currentSelectedLesson) {
        if (
          this.currentSelectedLesson &&
          this.currentSelectedLesson.isCollected
        ) {
          if (this.allData && this.allData[week]) {
            this.showingAll = false;
            return this.allData[week]
              .filter(
                (irz) =>
                  irz.isAllowed &&
                  irz.selectedLesson &&
                  irz.selectedLesson.courseInfo.COURSE_ID ==
                    this.currentSelectedLesson.courseInfo.COURSE_ID
              )
              .sort((a, b) => b.teachedAt - a.teachedAt || b.xeelj - a.xeelj);
          } else return [];
        } else {
          if (this.allData && this.allData[week]) {
            return this.allData[week]
              .filter(
                (irz) =>
                  irz.isAllowed &&
                  irz.selectedLesson &&
                  irz.selectedLesson.programId ==
                    this.currentSelectedLesson.programId &&
                  irz.selectedLesson.courseInfo.COURSE_ID ==
                    this.currentSelectedLesson.courseInfo.COURSE_ID
              )
              .sort((a, b) => b.teachedAt - a.teachedAt || b.xeelj - a.xeelj);
          }
        }
      } else {
        if (this.allData && this.allData[week]) {
          return this.allData[week]
            .filter((irz) => irz.isAllowed)
            .sort((a, b) => b.teachedAt - a.teachedAt || b.xeelj - a.xeelj);
        } else return [];
      }
    },
    isAllowed(att) {
      if (att.semester == this.currentSelectedSemester) {
        if (att.selectedLesson && att.selectedLesson.byTeachers) {
          var xx = att.selectedLesson.byTeachers.find(
            (teacher) => teacher.teacherId == this.teacherData.id
          );
          if (xx) att.isAllowed = true;
          else {
            var yy = this.lessons.find(
              (lesson) =>
                lesson.courseInfo.COURSE_ID ==
                att.selectedLesson.courseInfo.COURSE_ID
            );
            if (yy) att.isAllowed = true;
            else {
              // console.log("12345678990",att.selectedLesson.courseInfo.COURSE_NAME)
              att.isAllowed = false;
              att.isAllowed2 = "1";
            }
          }
        } else {
          // console.log("attattattattattatt", att, att.ref.path)
          // console.log(att.ref.path, att.courseInfo.COURSE_NAME, att.selectedLesson)
          var zz = this.lessons.find(
            (lesson) =>
              lesson.courseInfo.COURSE_ID ==
              att.selectedLesson.courseInfo.COURSE_ID
          );
          //subLessons ued byTeachers bhgui
          if (zz) att.isAllowed = true;
          else {
            // console.log("12345678990",att.selectedLesson.courseInfo.COURSE_NAME)
            att.isAllowed = false;
            att.isAllowed2 = "2";
          }
        }
      } else {
        att.isAllowed = false;
        att.isAllowed2 = "3";
      }
    },
    _getAllowed(att, theSemester) {
      var isAllowedd = false;
      if (att.semester == theSemester) {
        if (att.selectedLesson && att.selectedLesson.byTeachers) {
          var xx = att.selectedLesson.byTeachers.find(
            (teacher) => teacher.teacherId == this.teacherData.id
          );
          if (xx) isAllowedd = true;
          else {
            var yy = this.lessons.find(
              (lesson) =>
                lesson.courseInfo.COURSE_ID ==
                att.selectedLesson.courseInfo.COURSE_ID
            );
            if (yy) isAllowedd = true;
            else {
              // console.log("12345678990",att.selectedLesson.courseInfo.COURSE_NAME)
              isAllowedd = false;
              att.isAllowed2 = "1";
            }
          }
        } else {
          // console.log("attattattattattatt", att, att.ref.path)
          // console.log(att.ref.path, att.courseInfo.COURSE_NAME, att.selectedLesson)
          var zz = this.lessons.find(
            (lesson) =>
              lesson.courseInfo.COURSE_ID ==
              att.selectedLesson.courseInfo.COURSE_ID
          );
          //subLessons ued byTeachers bhgui
          if (zz) isAllowedd = true;
          else {
            // console.log("12345678990",att.selectedLesson.courseInfo.COURSE_NAME)
            isAllowedd = false;
            att.isAllowed2 = "2";
          }
        }
      } else {
        isAllowedd = false;
        att.isAllowed2 = "3";
      }
      return isAllowedd;
    },
    calculateSemestersDataForManager(att, executionData, theSemester) {
      if (this._getAllowed(att, theSemester)) {
        if (att.closed && executionData[att.semester - 1]) {
          executionData[att.semester - 1].burtgesenNumber++;
        }
        if (att.selectedHomeWork && executionData[att.semester - 1]) {
          executionData[att.semester - 1].homeworkNumber++;
        }
        if (
          (att.lessonConclusion || att.lessonNotes) &&
          executionData[att.semester - 1]
        ) {
          executionData[att.semester - 1].temdeglelNumber++;
        }
        if (att.selectedParentTopic && executionData[att.semester - 1]) {
          executionData[att.semester - 1] &&
            executionData[att.semester - 1].sedevNumber++;
        }
      }
    },
    backe() {
      this.$swal({
        title: "Анхны сэдвүүдийг сэргээx үү?",
        type: "warning",
        text: "Сэдвүүдийг хуулсан тохиолдолд анх оруулсан сэдвүүдийг нөөцөөлж хадгалсан байдаг.",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var batch = fb.db.batch();
          for (var i = 0; i < this.filteredSemesterAttendances.length; i++) {
            if (this.filteredSemesterAttendances) {
              //
            }
            var oldTopic1 =
              this.filteredSemesterAttendances[i]["selectedParentTopicOld"];
            var oldTopic2 =
              this.filteredSemesterAttendances[i]["selectedSubTopicOld"];
            var oldTopic3 =
              this.filteredSemesterAttendances[i]["selectedSubSubTopicOld"];

            var oldTmp = {
              copiedAt: new Date(),
            };

            if (oldTopic1) {
              oldTmp["selectedParentTopic"] = oldTopic1;
            }
            if (oldTopic2) {
              oldTmp["selectedSubTopic"] = oldTopic2;
            }
            if (oldTopic3) {
              oldTmp["selectedSubSubTopic"] = oldTopic3;
            }

            batch.update(this.filteredSemesterAttendances[i].ref, oldTmp);
          }
          batch.commit().then(() => {
            this.$forceUpdate();
          });
        }
      });
    },
    pastee() {
      if (this.copiedTopics && this.copiedTopics.length > 0) {
        if (this.copiedLesson.id != this.currentSelectedLesson.id) {
          var currentSelectedIrzuud = null;
          if (
            this.currentSelectedLesson &&
            this.currentSelectedLesson.isCollected
          ) {
            currentSelectedIrzuud = this.filteredSemesterAttendances.filter(
              (att) =>
                att.selectedLesson.courseInfo.COURSE_ID ==
                this.currentSelectedLesson.courseInfo.COURSE_ID
            );
          } else {
            currentSelectedIrzuud = this.filteredSemesterAttendances.filter(
              (att) =>
                att.isAllowed &&
                att.selectedLesson.programId ==
                  this.currentSelectedLesson.programId &&
                att.selectedLesson.courseInfo.COURSE_ID ==
                  this.currentSelectedLesson.courseInfo.COURSE_ID
            );
          }
          if (this.copiedTopics.length == currentSelectedIrzuud.length) {
            this.$swal({
              title:
                this.copiedLesson.classGroups[0].classGroupName +
                "-ийн сэдвийг " +
                this.currentSelectedLesson.classGroups[0].classGroupName +
                "-д xуулаx уу?",
              type: "warning",
              showCancelButton: true,
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
              confirmButtonText: "Тийм",
              cancelButtonText: "Үгүй",
              reverseButtons: true,
            }).then(async (result) => {
              if (result.isConfirmed) {
                this.pastedLesson = this.currentSelectedLesson;
                var batch = fb.db.batch();
                for (var i = 0; i < currentSelectedIrzuud.length; i++) {
                  var oldTopic1 =
                    currentSelectedIrzuud[i]["selectedParentTopic"];
                  var oldTopic2 = currentSelectedIrzuud[i]["selectedSubTopic"];
                  var oldTopic3 =
                    currentSelectedIrzuud[i]["selectedSubSubTopic"];

                  // this.currentSelectedIrzuud[i]["selectedParentTopic"] =
                  //   this.copiedTopics[i]["selectedParentTopic"]
                  //     ? this.copiedTopics[i]["selectedParentTopic"]
                  //     : null;
                  // this.currentSelectedIrzuud[i]["selectedSubTopic"] = this
                  //   .copiedTopics[i]["selectedSubTopic"]
                  //   ? this.copiedTopics[i]["selectedSubTopic"]
                  //   : null;
                  // this.currentSelectedIrzuud[i]["selectedSubSubTopic"] =
                  //   this.copiedTopics[i]["selectedSubSubTopic"]
                  //     ? this.copiedTopics[i]["selectedSubSubTopic"]
                  //     : null;

                  var tmp = {
                    selectedParentTopic:
                      this.copiedTopics[i]["selectedParentTopic"],
                    selectedSubTopic: this.copiedTopics[i]["selectedSubTopic"],
                    selectedSubSubTopic:
                      this.copiedTopics[i]["selectedSubSubTopic"],

                    selectedParentTopicOld: oldTopic1 ? oldTopic1 : null,
                    selectedSubTopicOld: oldTopic2 ? oldTopic2 : null,
                    selectedSubSubTopicOld: oldTopic3 ? oldTopic3 : null,

                    copiedAt: new Date(),
                    copiedByRef: this.teacherData.ref,
                    selectedHomeWork: this.copiedTopics[i].selectedHomeWork
                      ? this.copiedTopics[i].selectedHomeWork
                      : null,
                    lessonConclusion: this.copiedTopics[i].lessonConclusion
                      ? this.copiedTopics[i].lessonConclusion
                      : null,
                    lessonNotes: this.copiedTopics[i].lessonNotes
                      ? this.copiedTopics[i].lessonNotes
                      : null,
                  };
                  // console.log(tmp);
                  batch.update(currentSelectedIrzuud[i].ref, tmp);
                } // end of for
                batch.commit();
              }
            });
          } else {
            this.$swal.fire(
              "Хуулаx хичээлийн цагуудын тоо ижил байx ёстой! " +
                this.filteredSemesterAttendances.length
            );
          }
          // this.copiedTopics = null
        } else {
          this.$swal.fire(
            "Та энэ хичээлээс хуулалт хийсэн. Өөр xуулаx xичээл сонгоно уу!"
          );
        }
      } else {
        this.$swal.fire("Xуулаx сэдэв байxгүй байна!");
      }
    },
    pastee2() {
      if (this.copiedLesson.id != this.currentSelectedLesson.id) {
        if (
          this.copiedTopics.length == this.filteredSemesterAttendances.length
        ) {
          this.$swal({
            title:
              this.copiedLesson.classGroups[0].classGroupName +
              "-ийн сэдвийг " +
              this.currentSelectedLesson.classGroups[0].classGroupName +
              "-д xуулаx уу?",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Тийм",
            cancelButtonText: "Үгүй",
            reverseButtons: true,
          }).then(async (result) => {
            if (result.isConfirmed) {
              var batch = fb.db.batch();
              var k =
                this.copiedTopics.length <
                this.filteredSemesterAttendances.length
                  ? this.filteredSemesterAttendances.length -
                    this.copiedTopics.length
                  : 0;
              console.log(k);
              for (
                var i = 0;
                i < this.filteredSemesterAttendances.length;
                i++
              ) {
                var oldTopic1 =
                  this.filteredSemesterAttendances[i]["selectedParentTopic"];
                var oldTopic2 =
                  this.filteredSemesterAttendances[i]["selectedSubTopic"];
                var oldTopic3 =
                  this.filteredSemesterAttendances[i]["selectedSubSubTopic"];

                this.filteredSemesterAttendances[i]["selectedParentTopic"] =
                  this.copiedTopics[i]["selectedParentTopic"]
                    ? this.copiedTopics[i]["selectedParentTopic"]
                    : null;
                this.filteredSemesterAttendances[i]["selectedSubTopic"] = this
                  .copiedTopics[i]["selectedSubTopic"]
                  ? this.copiedTopics[i]["selectedSubTopic"]
                  : null;
                this.filteredSemesterAttendances[i]["selectedSubSubTopic"] =
                  this.copiedTopics[i]["selectedSubSubTopic"]
                    ? this.copiedTopics[i]["selectedSubSubTopic"]
                    : null;
                var tmp = {
                  selectedParentTopic:
                    this.copiedTopics[i]["selectedParentTopic"],
                  selectedSubTopic: this.copiedTopics[i]["selectedSubTopic"],
                  selectedSubSubTopic:
                    this.copiedTopics[i]["selectedSubSubTopic"],

                  selectedParentTopicOld: oldTopic1 ? oldTopic1 : null,
                  selectedSubTopicOld: oldTopic2 ? oldTopic2 : null,
                  selectedSubSubTopicOld: oldTopic3 ? oldTopic3 : null,
                  copiedAt: new Date(),
                  copiedByRef: this.teacherData.ref,

                  selectedHomeWork: this.copiedTopics[i].selectedHomeWork
                    ? this.copiedTopics[i].selectedHomeWork
                    : null,
                  lessonConclusion: this.copiedTopics[i].lessonConclusion
                    ? this.copiedTopics[i].lessonConclusion
                    : null,
                  lessonNotes: this.copiedTopics[i].lessonNotes
                    ? this.copiedTopics[i].lessonNotes
                    : null,
                };
                console.log(tmp);
                batch.update(this.filteredSemesterAttendances[i].ref, tmp);
              }
              batch.commit().then(() => {
                console.log(this.filteredSemesterAttendances, "PASTE done!!!");
                this.$forceUpdate();
              });
            }
          });
        } else {
          this.$swal.fire("Хуулаx хичээлийн цагуудын тоо ижил байx ёстой!");
        }
        // this.copiedTopics = null
      } else {
        this.$swal.fire(
          "Та энэ хичээлээс хуулалт хийсэн. Өөр xуулаx xичээл сонгоно уу!"
        );
      }
    },
    copye() {
      // var counter = 0
      var irzuud = null;
      if (
        this.currentSelectedLesson &&
        this.currentSelectedLesson.isCollected
      ) {
        irzuud = this.filteredSemesterAttendances.filter(
          (att) =>
            att.selectedLesson.courseInfo.COURSE_ID ==
            this.currentSelectedLesson.courseInfo.COURSE_ID
        );
      } else {
        irzuud = this.filteredSemesterAttendances.filter(
          (att) =>
            att.isAllowed &&
            att.selectedLesson.programId ==
              this.currentSelectedLesson.programId &&
            att.selectedLesson.courseInfo.COURSE_ID ==
              this.currentSelectedLesson.courseInfo.COURSE_ID
        );
      }
      this.copiedTopics = [];
      for (const ss of irzuud) {
        // counter++
        // var topic1 = ss.selectedParentTopic?ss.selectedParentTopic.parentCourseTopicName:null
        // var topic2 = ss.selectedSubTopic?ss.selectedSubTopic.courseTopicName:null
        // var topic3 = ss.selectedSubSubTopic?ss.selectedSubSubTopic.topicName:null
        // console.log(counter,ss.year+"-"+ss.month+"-"+ss.day, ss, topic1, topic2, topic3)
        console.log(ss);
        var tt = {};
        tt["selectedParentTopic"] = ss.selectedParentTopic
          ? ss.selectedParentTopic
          : null;
        if (tt["selectedParentTopic"])
          tt["selectedParentTopic"].children = null;

        tt["selectedSubTopic"] = ss.selectedSubTopic
          ? ss.selectedSubTopic
          : null;
        if (tt["selectedSubTopic"]) tt["selectedSubTopic"].children = null;

        tt["selectedSubSubTopic"] = ss.selectedSubSubTopic
          ? ss.selectedSubSubTopic
          : null;
        if (tt["selectedSubSubTopic"])
          tt["selectedSubSubTopic"].children = null;

        tt.selectedHomeWork = ss.selectedHomeWork ? ss.selectedHomeWork : null;
        tt.lessonConclusion = ss.lessonConclusion ? ss.lessonConclusion : null;
        tt.lessonNotes = ss.lessonNotes ? ss.lessonNotes : null;
        if (tt.selectedParentTopic) this.copiedTopics.push(tt);
      }
      console.log(this.copiedTopics);
      if (this.copiedTopics.length == 0)
        this.$swal.fire("Xуулаx сэдэв байxгүй байна!");
      this.copiedLesson = this.currentSelectedLesson;
      this.snackbar = true;
    },
    _getSemesterNumber(month, day) {
      var semester = this.calendarButez.find(
        (cc) => cc.session == this.currentSelectedSemester
      );
      if (semester) {
        var mm = semester.months.find((mm) => mm.name == month);
        if (mm) {
          var foundDay = mm.days.find((dd) => dd.day == day);
          if (foundDay) return semester.session;
        }
      }
      return null;
    },
    _setSemeter(att, save) {
      this.calendarButez.forEach((semester) => {
        var month = semester.months.find((month) => month.name == att.month);
        if (month) {
          var day = month.days.find((day) => day.day == att.day);
          if (day) {
            if (save) att.ref.update({ semester: semester.session });
            else att.semester = semester.session;
            console.log("semester changedds");
          }
        }
      });

      // if (
      //   (att.month >= 9 && att.month <= 10) ||
      //   (att.month == 11 && att.day <= 3)
      // ) {
      //   if (save) att.ref.update({ semester: 1 });
      //   else att.semester = 1;
      // } else if (
      //   att.month == 12 ||
      //   (att.month == 11 && att.day >= 13) ||
      //   (att.month == 1 && att.day <= 5)
      // ) {
      //   if (save) att.ref.update({ semester: 2 });
      //   else att.semester = 2;
      // }
    },
    _getSimplifiedName(courseInfo) {
      return courseInfo.COURSE_NAME.replace(" Заавал", "");
      //return courseInfo.SUBJECT_AREA_NAME;
    },
    _getSubLessonNames(attendance) {
      var names = null;
      if (attendance.subLessons) {
        attendance.subLessons.forEach((kk) => {
          if (names) names = names + ", " + kk.classGroups[0].classGroupName;
          else names = kk.classGroups[0].classGroupName;
        });
      }
      return names;
    },
    async checkExecutionExists(lessonRef) {
      if (
        this.selectedYear &&
        this.selectedMonth &&
        this.selectedDay &&
        this.selectedEelj
      ) {
        if (lessonRef) {
          await lessonRef
            .collection("executions")
            .where("year", "==", this.selectedYear)
            .where("month", "==", this.selectedMonth)
            .where("day", "==", this.selectedDay)
            .where("xeelj", "==", this.selectedEelj)
            .where("teacherRef", "==", this.teacherData.ref) ///333
            .get()
            .then((docs) => {
              console.log(docs.size);
              if (docs.size > 0) {
                this.existingExecutionRef = docs.docs[0].ref;
                console.log("existingExecutionRef", this.existingExecutionRef);
                setTimeout(() => {
                  this.executionExistsAlert = true;
                }, 1000);
              } else {
                this.existingExecutionRef = null;
              }
            });
        } else {
          await this.temporarlySelectedLesson.ref
            .collection("executions")
            .where("year", "==", this.selectedYear)
            .where("month", "==", this.selectedMonth)
            .where("day", "==", this.selectedDay)
            .where("xeelj", "==", this.selectedEelj)
            .where("teacherRef", "==", this.teacherData.ref) ///333
            .get()
            .then((docs) => {
              console.log(docs.size);
              if (docs.size > 0) {
                this.existingExecutionRef = docs.docs[0].ref;
                this.executionExistsAlert = true;
              } else {
                this.existingExecutionRef = null;
              }
            });
        }
      }
    },

    async _delete(attendance) {
      console.log(attendance.ref.path, attendance);
      this.studentsStatuses2 = [];
      var selectedLessonGroupIndex = null;
      if (
        attendance.selectedGroupIndex &&
        Number.isInteger(attendance.selectedGroupIndex)
      ) {
        selectedLessonGroupIndex = attendance.selectedGroupIndex;
      } else if (
        attendance.selectedGroupIndex &&
        attendance.selectedGroupIndex.groupIndex
      ) {
        selectedLessonGroupIndex = attendance.selectedGroupIndex.groupIndex;
      }
      await attendance.ref.parent.parent.get().then((doc) => {
        let lesson = doc.data();
        lesson.ref = doc.ref;
        lesson.id = doc.id;
        this.temporarlySelectedLesson = lesson;
        this.temporarlySelectedLesson.subLessons = attendance.subLessons;
        if (
          this.temporarlySelectedLesson.esisLessonType &&
          this.temporarlySelectedLesson.esisLessonType.esisLessonTypeId == 2
        ) {
          this.temporarlySelectedLesson.isSubGrouped = true;
        }
        this.selectedLessonGroup = attendance.selectedGroupIndex;
      });
      if (attendance.isWithSublessons) {
        this.temporarlySelectedLesson.isCollected = true;
        if (this.temporarlySelectedLesson.isSubGrouped)
          delete this.temporarlySelectedLesson.isSubGrouped;
        this.selectedLessonGroup = attendance.selectedGroupIndex;
      }
      // else{

      //   attendance.selectedLesson.esisLessonType &&
      //   (attendance.selectedLesson.esisLessonType.esisLessonTypeId == 3 ||
      //     attendance.isWithSublessons)
      // }

      console.log(selectedLessonGroupIndex);
    },
    _deleteStatusesInClass(execution, classGroupRef) {
      this.loadingDelete = true;
      var batch2 = fb.db.batch();
      classGroupRef
        .collection("attendances-" + this.userData.school.currentYear)
        .where("daily_attendance", "==", false)
        .get()
        .then((docs) => {
          for (const doc of docs.docs) {
            let att = doc.data();
            att.id = doc.id;
            att.ref = doc.ref;
            if (
              (att.studentId,
              execution.month == att.month &&
                execution.day == att.day &&
                execution.xeelj == att.xeelj)
            ) {
              console.log("deleting att", att.month, att.day, att.xeelj, att);
              batch2.delete(att.ref);
            }
          } //for
          batch2.delete(execution.ref);
          batch2.commit().then(() => {
            this.loadingDelete = false;
          });
        });
    },
    _isNoteAdded(execution) {
      if (
        execution.lessonConclusion ||
        execution.lessonNotes ||
        execution.selectedHomeWork ||
        execution.selectedParentTopic ||
        execution.selectedSubSubTopic ||
        execution.selectedSubTopic
      ) {
        return true;
      } else return false;
    },
    deleteWeeklyDuplicateAttendance(week) {
      this.selectedWeekToDelete = week;
      this.loadingDelete = true;

      var filteredExs = this._getWeekAttendances(week);
      var counter = 0;
      var list = [];
      for (const exec of filteredExs.sort(
        (a, b) => a.teachedAt - b.teachedAt || a.xeelj - b.xeelj
      )) {
        counter++;
        console.log(
          counter,
          exec.year,
          exec.month,
          exec.day,
          exec.xeelj,
          exec.selectedLesson.courseInfo.COURSE_NAME
        );
        // var found = list.find(xxx=> xxx.year==exec.year && xxx.month==exec.month && xxx.day)

        list.push(exec);
      }
    },
    deleteWeeklyAttendance() {
      this.loadingDelete = true;
      if (this.selectedWeekToDelete) {
        var executions = null;

        if (this.currentSelectedLesson) {
          executions = this.allData[this.selectedWeekToDelete].filter(
            (irz) =>
              irz.isAllowed &&
              irz.selectedLesson &&
              irz.selectedLesson.ref.path == this.currentSelectedLesson.ref.path
          );
        } else {
          executions = this.allData[this.selectedWeekToDelete].filter(
            (irz) => irz.isAllowed
          );
        }

        var batch = fb.db.batch();
        executions.forEach((execution) => {
          if (this._isNoteAdded(execution)) {
            if (this.selectDeleteAllIrzWithTopics)
              this._deleteIrz(execution, batch);
          } else {
            if (!execution.isWithSublessons) this._deleteIrz(execution, batch);
          }
        });
        // var dateStr = date.year + "-" + date.month + "-" + date.day;
        batch.commit().then(() => {
          this.loadingDelete = false;
          this.selectedWeekToDelete = null;
          this.selectDeleteAllIrzWithTopics = false;
          this.showDeleteAllIrzDialog = !this.showDeleteAllIrzDialog;
        });
      }
    },
    _deleteIrz(execution, batch) {
      this.loadingDelete = true;
      if (
        execution.studentsAbsent == 0 &&
        execution.studentsExcused == 0 &&
        execution.studentsSick == 0 &&
        execution.studentsOnline == 0
      ) {
        batch.delete(execution.ref);
      } else {
        if (execution.isWithSublessons && execution.subLessons) {
          for (const ll of execution.subLessons) {
            this._deleteStatusesInClass(
              execution,
              ll.classGroups[0].classGroupRef
            );
          }
        } else {
          this._deleteStatusesInClass(
            execution,
            execution.selectedLesson.classGroups[0].classGroupRef
          );
        } //if ==2 lesson
      }
    },
    _deleteAttendanceAndDetailed() {
      var ttext =
        "Өдөр: " +
        this.selectedExecution.month +
        "/" +
        this.selectedExecution.day +
        "/" +
        this.selectedExecution.year;
      this.$swal({
        title: this.selectedExecution.xeelj + " цагийн ирцийг устгаx уу?",
        text: ttext,
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var batch2 = fb.db.batch();
          var classStudents = this._getConsideredStudents();
          for (const statusFound of this.studentsStatuses2) {
            if (statusFound.studentId) {
              var studentFound = classStudents.find(
                (stud) => stud.PERSON_ID == statusFound.studentId
              );
              if (!studentFound) continue;
              if (
                statusFound &&
                statusFound.daily_attendance == true &&
                this.editingAttendances
              ) {
                continue;
              }
              if (statusFound && statusFound.status != 1) {
                batch2.delete(statusFound.ref);
              }
            }
          }
          batch2.delete(this.selectedExecution.ref);
          batch2.commit().then(() => {
            this._closeAfterSaveIrz();
          });
        }
      });
    },
    deleteAttendance(attendance) {
      console.log(attendance);
      this.$swal.fire("Ирц засаx руугаа ороод арилгана уу!");
      // var ttext =
      //   "Өдөр: " +
      //   attendance.month +
      //   "/" +
      //   attendance.day +
      //   "/" +
      //   attendance.year;
      // this.$swal({
      //   title: attendance.xeelj + " цагийн ирцийг устгаx уу?",
      //   text: ttext,
      //   type: "warning",
      //   showCancelButton: true,
      //   customClass: {
      //     confirmButton: "btn bg-gradient-success",
      //     cancelButton: "btn bg-gradient-danger",
      //   },
      //   confirmButtonText: "Тийм",
      //   cancelButtonText: "Үгүй",
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.isConfirmed) {
      //     attendance.ref.delete();
      //   }
      // });
    },
    _addHomeWork(attendance) {
      this.selectedAttendance = attendance;
      this.selectedPreviousHomework = null;
      if (attendance.selectedHomeWork) {
        // this.selectedAttendance = Object.assign({}, attendance);
        this.selectedHomeWork = Object.assign({}, attendance.selectedHomeWork);
      } else {
        // this.selectedAttendance = Object.assign({}, attendance);
        this.selectedHomeWork = {};
      }
      this.loadingHomeworkDatabase = true;
      attendance.ref.parent.parent.parent
        .where(
          "courseInfo.COURSE_ID",
          "==",
          attendance.selectedLesson.courseInfo.COURSE_ID
        )
        .get()
        .then((docs) => {
          this.issuedHomeworks = [];
          var counter = 0;
          this.loadingHomeworkDatabase = true;
          docs.docs.forEach((doc) => {
            let llesson = doc.data();
            llesson.ref = doc.ref;
            llesson.id = doc.id;
            llesson.ref
              .collection("executions")
              .limit(10)
              .orderBy("homeWorkIssuedAt", "desc")
              .get()
              .then((docs) => {
                docs.docs.forEach((doc) => {
                  let ex = doc.data();
                  if (ex.selectedHomeWork) {
                    counter++;
                    if (ex.selectedHomeWork.name) {
                      ex.selectedHomeWork.name3 = counter + ". ";
                      ex.selectedHomeWork.name3 =
                        ex.selectedHomeWork.name3 +
                        ex.selectedHomeWork.name.substring(0, 50) +
                        " ...";
                    } else if (ex.selectedHomeWork.description) {
                      ex.selectedHomeWork.name3 = counter + ". ";
                      ex.selectedHomeWork.name3 =
                        ex.selectedHomeWork.name3 +
                        ex.selectedHomeWork.description.substring(0, 50) +
                        " ...";
                    }
                    this.issuedHomeworks.push(ex.selectedHomeWork);
                  }
                });
                this.loadingHomeworkDatabase = false;
                this.$forceUpdate();
              });
          });
        });
      console.log(this.currentSelectedLesson);
      this.showHomeWorkDialog = !this.showHomeWorkDialog;
    },
    _saveHomeWork(value) {
      console.log(this.selectedHomeWork);
      if (value == -1) {
        this.selectedHomeWork.name = null;
        this.selectedHomeWork.description = null;
        this.$forceUpdate();
      } else {
        if (
          (this.selectedHomeWork.name &&
            this.selectedHomeWork.name.length > 0) ||
          (this.selectedHomeWork.description &&
            this.selectedHomeWork.description.length > 0)
        ) {
          this.selectedAttendance.ref
            .update({
              selectedHomeWork: this.selectedHomeWork
                ? this.selectedHomeWork
                : null,
              homeWorkIssuedAt: new Date(),
            })
            .then(() => {
              this.selectedHomeWork = null;
              this.selectedAttendance.selectedHomeWork = this.selectedHomeWork
                ? this.selectedHomeWork
                : null;
              this.showHomeWorkDialog = !this.showHomeWorkDialog;
              this.$forceUpdate();
            });
        } else {
          this.selectedAttendance.ref
            .update({
              selectedHomeWork: null,
              homeWorkIssuedAt: new Date(),
            })
            .then(() => {
              this.selectedHomeWork = null;
              // this.currentSelectedLesson = null;
              this.showHomeWorkDialog = !this.showHomeWorkDialog;
              //this.$forceUpdate();
            });
        }
      }
    },
    _cancelTeacherNotes() {
      this.selectedAttendance = null;
      this.showTeachersNotesDialog = !this.showTeachersNotesDialog;
      this.editedLessonConclusion = null;
      this.editedLessonNotes = null;
    },
    _getLessonClassGroupsName(lesson) {
      var str = null;
      if (!lesson.isCollected) {
        for (const gg of lesson.classGroups) {
          str = str ? str + ", " + gg.classGroupName : gg.classGroupName;
        }
      } else if (lesson.subLessons) {
        // console.log(lesson.isCollected, lesson.name2, lesson);
        for (const xx of lesson.subLessons) {
          if (xx.classGroups && xx.classGroups[0]) {
            str = str
              ? str + ", " + xx.classGroups[0].classGroupFullName
              : xx.classGroups[0].classGroupFullName;
          }
        }
      }
      return str;
    },
    _getTopicString(attendance, showTopicsDetailed) {
      var str = null;
      if (showTopicsDetailed) {
        if (attendance.selectedPreviousPlannedTopic) {
          str = attendance.selectedPreviousPlannedTopic.name;
        } else {
          if (
            attendance.selectedSubSubTopic &&
            attendance.selectedSubSubTopic.topicName
          )
            str = attendance.selectedSubSubTopic.topicName;
          else str = "";

          if (attendance.selectedSubTopic)
            str =
              str +
              (str ? ": " : "") +
              attendance.selectedSubTopic.courseTopicName;
          if (attendance.selectedParentTopic)
            str =
              str +
              (str ? ": " : "") +
              attendance.selectedParentTopic.parentCourseTopicName;
        }
      } else str = "Сэдэвтэй";
      return str ? str.substring(0, 60) + " ... " : str;
    },
    _getTopicString2(attendance) {
      var str = null;
      if (
        attendance.selectedSubSubTopic &&
        attendance.selectedSubSubTopic.topicName
      )
        str = attendance.selectedSubSubTopic.topicName;
      else str = "";

      if (attendance.selectedSubTopic)
        str =
          str + (str ? ": " : "") + attendance.selectedSubTopic.courseTopicName;
      if (attendance.selectedParentTopic)
        str =
          str +
          (str ? ": " : "") +
          attendance.selectedParentTopic.parentCourseTopicName;

      return str ? str.substring(0, 60) + " ... " : str;
    },
    _getHoweworkString(attendance, showTopicsDetailed) {
      var str = null;
      if (showTopicsDetailed) {
        if (attendance.selectedHomeWork && attendance.selectedHomeWork.name) {
          str = (str ? str + ": " : "") + attendance.selectedHomeWork.name;
        }
        if (
          attendance.selectedHomeWork &&
          attendance.selectedHomeWork.description
        ) {
          str =
            (str ? str + ": " : "") + attendance.selectedHomeWork.description;
        }
      } else str = "Даалгавар өгсөн";
      return str ? str.substring(0, 50) + " ... " : str;
    },
    _showTeacherNotesDialog(attendance) {
      attendance.ref.parent.parent.parent
        .where(
          "courseInfo.COURSE_ID",
          "==",
          attendance.selectedLesson.courseInfo.COURSE_ID
        )
        .get()
        .then((docs) => {
          this.issuedClassNotes = [];
          this.issuedClassConclusionNotes = [];
          this.loadingClassNotes = true;
          docs.docs.forEach((doc) => {
            let llesson = doc.data();
            llesson.ref = doc.ref;
            llesson.id = doc.id;
            llesson.ref
              .collection("executions")
              .limit(10)
              .orderBy("createdAt", "desc")
              .get()
              .then((docs) => {
                docs.docs.forEach((doc) => {
                  let ex = doc.data();
                  if (ex.lessonNotes) {
                    this.issuedClassNotes.push(
                      ex.lessonNotes.substring(0, 50) + " ..."
                    );
                  }
                  if (ex.lessonConclusion) {
                    this.issuedClassConclusionNotes.push(
                      ex.lessonConclusion.substring(0, 50) + " ..."
                    );
                  }
                });
                this.loadingClassNotes = false;
                this.$forceUpdate();
              });
          });
        });
      this.selectedAttendance = attendance;
      this.editedLessonNotes = this.selectedAttendance.lessonNotes;
      this.editedLessonConclusion = this.selectedAttendance.lessonConclusion;
      this.showTeachersNotesDialog = !this.showTeachersNotesDialog;
    },
    _saveTeacherNotes(value) {
      if (value == -1) {
        this.$swal({
          title: "Тэмдэглэлийг устгаx уу?",
          text: "Дүгнэлт, xичээлийн үйл ажиллагаа бүгд устгагдана. Xэрэв та арилгаxыг xүсвэл тус тусад нь засаад xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.selectedAttendance.ref
              .update({
                lessonNotes: null,
                lessonConclusion: null,
              })
              .then(() => {
                this.selectedAttendance.lessonNotes = null;
                this.selectedAttendance.lessonConclusion = null;
                this.selectedAttendance = null;
                this.showTeachersNotesDialog = !this.showTeachersNotesDialog;
              });
          }
        });
      } else {
        var xx = {};
        if (this.editedLessonNotes && this.editedLessonNotes.length > 0) {
          xx.lessonNotes = this.editedLessonNotes;
        } else {
          xx.lessonNotes = null;
        }

        if (
          this.editedLessonConclusion &&
          this.editedLessonConclusion.length > 0
        ) {
          xx.lessonConclusion = this.editedLessonConclusion;
        } else {
          xx.lessonConclusion = null;
        }

        this.selectedAttendance.ref.update(xx).then(() => {
          this.selectedAttendance = null;
          this.editedLessonConclusion = null;
          this.editedLessonNotes = null;
          this.showTeachersNotesDialog = !this.showTeachersNotesDialog;
        });
      }
    },
    _print(attendance) {
      console.log(attendance.ref.path);
      console.log(attendance);
    },

    _saveLessonTopic() {
      if (
        this.selectedParentTopicForAttendance &&
        this.selectedParentTopicForAttendance.children
      ) {
        delete this.selectedParentTopicForAttendance.children;
      }
      if (this.selectedSubTopic && this.selectedSubTopic.children) {
        delete this.selectedSubTopic.children;
      }
      var xx = {
        selectedSubSubTopic: this.selectedSubSubTopic
          ? this.selectedSubSubTopic
          : null,
        selectedSubSubTopicDescription: this.selectedSubSubTopicDescription
          ? this.selectedSubSubTopicDescription
          : null,
        selectedSubTopic: this.selectedSubTopic ? this.selectedSubTopic : null,
        selectedParentTopic: this.selectedParentTopicForAttendance
          ? this.selectedParentTopicForAttendance
          : null,
      };

      if (this.editedLessonNotes && this.editedLessonNotes.length > 0) {
        xx.lessonNotes = this.editedLessonNotes;
      } else {
        xx.lessonNotes = null;
      }
      if (
        this.editedLessonConclusion &&
        this.editedLessonConclusion.length > 0
      ) {
        xx.lessonConclusion = this.editedLessonConclusion;
      } else {
        xx.lessonConclusion = null;
      }

      if (
        (this.selectedHomeWork.name && this.selectedHomeWork.name.length > 0) ||
        (this.selectedHomeWork.description &&
          this.selectedHomeWork.description.length > 0)
      ) {
        xx.selectedHomeWork = this.selectedHomeWork;
      } else {
        xx.selectedHomeWork = null;
      }
      this.selectedAttendance.ref.update(xx).then(() => {
        this.selectedParentTopicForAttendance = null;
        this.selectedSubTopic = null;
        this.selectedSubSubTopic = null;
        this.selectedSubSubTopicDescription = null;

        this.editedLessonNotes = null;
        this.editedLessonConclusion = null;
        this.selectedAttendance = null;
        this.selectedPreviousPlannedTopic = null;
        this.showSubSubTopicDialog = !this.showSubSubTopicDialog;
      });
    },
    _cancelAddTopic() {
      this.selectedParentTopicForAttendance = null;
      this.selectedSubTopic = null;
      this.selectedSubSubTopic = null;
      this.selectedSubSubTopicDescription = null;
      this.showSubSubTopicDialog = !this.showSubSubTopicDialog;
    },
    _saveNewTopic(newTopicIndex) {
      if (newTopicIndex == 1 && this.newTopic) {
        var tt = {
          parentCourseTopicName: this.newTopic,
          parentCourseTopicId: null,
          createdByESIS: false,
          ownedBySchool: true,
          createdAt: new Date(),
          createdByFirstName: this.teacherData.FIRST_NAME,
          createdByLastName: this.teacherData.LAST_NAME,
          createdByTeacherRef: this.teacherData.ref,
          numbering: 0,
        };
        this.teacherData.school.ref
          .collection(
            "_schoolCurriculums-" + this.teacherData.school.currentYear
          )
          .doc(this.selectedCourseInfo.COURSE_ID.toString())
          .collection("esisTopics")
          .add(tt, { merge: true })
          .then((zz) => {
            zz.get().then((doc) => {
              var fff = doc.data();
              fff.ref = doc.ref;
              fff.id = doc.id;
              this.newTopicIndex = -1;
              this.newTopic = null;
              this.selectedParentTopicForAttendance = fff;
              this.$forceUpdate();
              this.showAddNewTopicDialog = !this.showAddNewTopicDialog;
            });
          });
      } else if (
        newTopicIndex == 2 &&
        this.newTopic &&
        this.selectedParentTopicForAttendance
      ) {
        var tt2 = {
          courseTopicName: this.newTopic,
          courseTopicId: null,
          createdByESIS: false,
          ownedBySchool: true,
          createdAt: new Date(),
          createdByFirstName: this.teacherData.FIRST_NAME,
          createdByLastName: this.teacherData.LAST_NAME,
          createdByTeacherRef: this.teacherData.ref,
          numbering: 0,
        };
        console.log(tt2, this.selectedCourseInfo.COURSE_ID.toString(), "xxx");
        this.selectedParentTopicForAttendance.ref
          .collection("subTopics")
          .add(tt2, { merge: true })
          .then((zz) => {
            zz.get().then((doc) => {
              var fff = doc.data();
              fff.ref = doc.ref;
              fff.id = doc.id;
              this.newTopicIndex = -1;
              this.newTopic = null;
              this.selectedSubTopic = fff;
              this.$forceUpdate();
              this.showAddNewTopicDialog = !this.showAddNewTopicDialog;
            });
          });
      } else if (newTopicIndex == 3 && this.newTopic && this.selectedSubTopic) {
        var tt3 = {
          topicName: this.newTopic,
          topicId: null,
          createdByESIS: false,
          ownedBySchool: true,
          createdAt: new Date(),
          createdByFirstName: this.teacherData.FIRST_NAME,
          createdByLastName: this.teacherData.LAST_NAME,
          createdByTeacherRef: this.teacherData.ref,
          numbering: 0,
        };
        this.selectedSubTopic.ref
          .collection("subSubTopics")
          .add(tt3, { merge: true })
          .then((zz) => {
            zz.get().then((doc) => {
              var fff = doc.data();
              fff.ref = doc.ref;
              fff.id = doc.id;
              this.newTopicIndex = -1;
              this.newTopic = null;
              this.selectedSubSubTopic = fff;
              this.$forceUpdate();
              this.showAddNewTopicDialog = !this.showAddNewTopicDialog;
            });
          });
      }
    },
    async _addTopicToLesson(attendance) {
      this.selectedCourseInfo = attendance.selectedLesson.courseInfo;
      this.plannedTopics = [];
      this.selectedPreviousPlannedTopic = null;
      this.selectedAttendance = attendance;
      this.selectedSubSubTopic = attendance.selectedSubSubTopic;
      this.selectedSubTopic = attendance.selectedSubTopic;
      this.selectedParentTopicForAttendance = attendance.selectedParentTopic;
      this.selectedSubSubTopicDescription =
        attendance.selectedSubSubTopicDescription;
      if (attendance.selectedHomeWork) {
        this.selectedHomeWork = Object.assign({}, attendance.selectedHomeWork);
      } else {
        this.selectedHomeWork = {};
      }
      this.editedLessonNotes = this.selectedAttendance.lessonNotes;
      this.editedLessonConclusion = this.selectedAttendance.lessonConclusion;
      this.showSubSubTopicDialog = !this.showSubSubTopicDialog;
      var courseId = attendance.selectedLesson.courseInfo.COURSE_ID.toString();
      this.teacherData.school.ref
        .collection("_schoolCurriculums-" + this.teacherData.school.currentYear)
        .doc(courseId)
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this._readCourseTopics(attendance.selectedLesson.courseInfo);
          } else {
            this._readFromPlan3(attendance);
            this._readTopicsFromFirebase(courseId);
          }
        });
    },
    _readFromPlan3(attendance) {
      for (const att of this.teacherAttendances) {
        if (
          att.selectedLesson &&
          attendance.selectedLesson &&
          att.selectedLesson.courseInfo.COURSE_ID ==
            attendance.selectedLesson.courseInfo.COURSE_ID &&
          (att.selectedParentTopic ||
            att.selectedSubTopic ||
            att.selectedSubSubTopic)
        ) {
          this.plannedTopics.push({
            topic1: att.selectedParentTopic ? att.selectedParentTopic : null,
            topic2: att.selectedSubTopic ? att.selectedSubTopic : null,
            topic3: att.selectedSubSubTopic ? att.selectedSubSubTopic : null,
            notes: att.lessonNotes,
            selectedHomeWork: att.selectedHomeWork,
            conclusion: att.lessonConclusion,
            name3: att.selectedSubSubTopic
              ? att.selectedSubSubTopic.topicName
              : att.selectedSubTopic
              ? att.selectedSubTopic.name
              : att.selectedParentTopic
              ? att.selectedParentTopic.name
              : null,
          });
        }
      }
    },
    async _readCourseTopics(courseInfo) {
      this.loadingESIS = true;
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.teacherData.school._esisUserName,
            password: this.teacherData.school._esisPword,
          }
        )
        .then(async (res) => {
          this.token = res.data.token;
          var topics = await esisAPIS.getTOPICS_COURSE(
            this.token,
            courseInfo.COURSE_ID
          );

          var batch = fb.db.batch();
          var docRef = this.teacherData.school.ref
            .collection(
              "_schoolCurriculums-" + this.teacherData.school.currentYear
            )
            .doc(courseInfo.COURSE_ID.toString());
          if (topics) {
            batch.set(
              docRef,
              { courseInfo: courseInfo, createdAt: new Date() },
              { merge: true }
            );
            topics.forEach((topic) => {
              if (topic.parentCourseTopicId) {
                batch.set(
                  docRef
                    .collection("esisTopics")
                    .doc(topic.parentCourseTopicId.toString()),
                  {
                    parentCourseTopicId: topic.parentCourseTopicId,
                    parentCourseTopicName: topic.parentCourseTopicName,
                    createdAt: new Date(),
                    createdByESIS: true,
                  },
                  { merge: true }
                );
                topic.createdByESIS = true;
                topic.createdAt = new Date();
                batch.set(
                  docRef
                    .collection("esisTopics")
                    .doc(topic.parentCourseTopicId.toString())
                    .collection("subTopics")
                    .doc(topic.courseTopicId.toString()),
                  topic,
                  { merge: true }
                );
              } else {
                batch.set(
                  docRef.collection("esisTopics").doc("noParent"),
                  {
                    parentCourseTopicId: "noParent",
                    parentCourseTopicName: "noParent",
                    createdAt: new Date(),
                    createdByESIS: true,
                  },
                  { merge: true }
                );
                batch.set(
                  docRef
                    .collection("esisTopics")
                    .doc("noParent")
                    .collection("subTopics")
                    .doc(topic.courseTopicId.toString()),
                  topic,
                  { merge: true }
                );
              }
            });
          }
          batch.commit().then(() => {
            this.loadingESIS = false;
          });
        });
    },
    _readFromPlan(courseId) {
      this.loadingPreviousPlannedTopics = true;
      this.teacherData.school.ref
        .collection("_schoolCurriculums-" + this.teacherData.school.currentYear)
        .doc(courseId)
        .collection("uliral-" + this.currentSelectedSemester)
        .orderBy("numbering", "asc")
        .get()
        .then((docs) => {
          this.plannedTopics = [];
          docs.forEach((doc) => {
            let topic = doc.data();
            topic.id = doc.id;
            topic.ref = doc.ref;
            if (topic.name || topic.topicName) {
              topic.name3 =
                topic.numbering +
                1 +
                ". " +
                (topic.name
                  ? topic.name
                  : topic.topicName
                  ? topic.topicName
                  : null);
              this.plannedTopics.push(topic);
            }
          });
          this.loadingPreviousPlannedTopics = false;
        });
    },
    _readTopicsFromFirebase(courseId) {
      this.loadingEsisTopics = true;
      this.teacherData.school.ref
        .collection("_schoolCurriculums-" + this.teacherData.school.currentYear)
        .doc(courseId)
        .collection("esisTopics")
        .onSnapshot((docs) => {
          this.lessonTopicsFromFirebase = [];
          var counter = 0;
          docs.docs.forEach((doc) => {
            counter++;
            var parentTopic = doc.data();
            parentTopic.name =
              counter +
              ". " +
              this._getCapText(parentTopic.parentCourseTopicName);
            parentTopic.id = doc.id;
            parentTopic.ref = doc.ref;
            parentTopic.children = null;
            if (!parentTopic.deleted)
              this.lessonTopicsFromFirebase.push(parentTopic);
          });
          this.loadingEsisTopics = false;
        });
    },
    _getCapText(inputString) {
      if (!inputString) {
        return inputString;
      }
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    },
    async updateLessonExecutions() {
      console.log(this.currentSelectedLesson.ref.path);
    },
    _saveTopics() {
      this.selectedTopic.teachedBy = {
        teacherRef: this.teacherData.ref,
        teacherFirstName: this.teacherData.firstName
          ? this.teacherData.firstName
          : null,
        teacherLastName: this.teacherData.lastName
          ? this.teacherData.lastName
          : null,
        teacherDisplayName: this.teacherData.DISPLAY_NAME
          ? this.teacherData.DISPLAY_NAME
          : null,
        teacherId: this.teacherData.id,
      };
      console.log(this.selectedExecution.ref.path);
      this.selectedExecution.ref
        .update({
          topics: fb.firestore.FieldValue.arrayUnion(this.selectedTopic),
        })
        .then(() => {
          this.openLessonTopicDialog = false;
        });
    },
    deletingTopics() {
      var yes = confirm("Та энэ xичээлийг устгаxдаа итгэлтэй байна уу?");
      if (yes) {
        this.selectedExecution.topics.forEach((topic) => {
          if (topic.deleting) {
            delete topic.deleting;
            this.selectedExecution.ref
              .update({
                topics: fb.firestore.FieldValue.arrayRemove(topic),
              })
              .then(() => {
                this.openLessonTopicDialog = false;
              });
          }
        });
      }
    },

    _saveHWs() {
      this.selectedHW.givenBy = {
        teacherRef: this.teacherData.ref,
        teacherFirstName: this.teacherData.firstName
          ? this.teacherData.firstName
          : null,
        teacherLastName: this.teacherData.lastName
          ? this.teacherData.lastName
          : null,
        teacherDisplayName: this.teacherData.DISPLAY_NAME
          ? this.teacherData.DISPLAY_NAME
          : null,
        teacherId: this.teacherData.id,
      };
      this.selectedExecution.ref
        .update({
          hws: fb.firestore.FieldValue.arrayUnion(this.selectedHW),
        })
        .then(() => {
          this.openLessonHWDialog = false;
        });
    },
    deletingHWs() {
      var yes = confirm("Та энэ xичээлийг устгаxдаа итгэлтэй байна уу?");
      if (yes) {
        this.selectedExecution.hws.forEach((hw) => {
          if (hw.deleting) {
            delete hw.deleting;
            this.selectedExecution.ref
              .update({
                hws: fb.firestore.FieldValue.arrayRemove(hw),
              })
              .then(() => {
                this.openLessonHWDialog = false;
              });
          }
        });
      }
    },
    async openLessonHWs() {
      await this.selectedExecution.ref.onSnapshot((doc) => {
        this.selectedExecution = doc.data();
        this.selectedExecution.ref = doc.ref;
        this.selectedExecution.id = doc.id;
      });
      this.selectedExecution.ref.parent.parent
        .collection("homeworks")
        .onSnapshot((docs) => {
          this.selectedLessonHWs = [];
          docs.forEach((doc) => {
            let hw = doc.data();
            hw.ref = doc.ref;
            hw.id = doc.id;
            this.selectedLessonHWs.push(hw);
          });
          this.openLessonHWDialog = true;
        });
    },
    fixSemester(att) {
      this.calendarButez.forEach((semester) => {
        var month = semester.months.find((month) => month.name == att.month);
        if (month) {
          var day = month.days.find((day) => day.day == att.day);
          if (day) {
            if (att.semester != semester.session) {
              var query = att.ref.parent
                .where("year", "==", att.year)
                .where("month", "==", att.month)
                .where("day", "==", att.day)
                .where("xeelj", "==", att.xeelj);

              if (att.selectedLessonGroup) {
                query = query.where(
                  "selectedLessonGroup.id",
                  "==",
                  att.selectedLessonGroup.id
                );
              }
              query.get().then((docs) => {
                if (docs.size == 0) {
                  att.ref.update({ semester: semester.session }).then(() => {
                    console.log("semester updated", att.ref.path);
                  });
                } else {
                  // att.ref.delete();
                  att.ref.update({ deleted: true });
                }
              });
            }
          }
        }
      });
    },
    fetchNextPage(toUpdate) {
      var query = fb.db
        .collectionGroup("executions") //66
        .where("deleted", "==", false);
      if (this.teacherData.role == "teacher") {
        query = query
          .where("teacherRef", "==", this.teacherData.ref)
          .where("semester", "==", this.currentSelectedSemester);
        console.log("reading with semester", this.currentSelectedSemester);
      }
      if (this.currentSelectedLesson) {
        if (
          this.currentSelectedLesson &&
          this.currentSelectedLesson.isCollected
        ) {
          query = query.where(
            "courseInfo.COURSE_ID",
            "==",
            this.currentSelectedLesson.courseInfo.COURSE_ID
          );
        } else {
          query = query
            .where(
              "selectedLesson.programId",
              "==",
              this.currentSelectedLesson.programId
            )
            .where(
              "selectedLesson.courseInfo.COURSE_ID",
              "==",
              this.currentSelectedLesson.courseInfo.COURSE_ID
            );
        }
        this.showingAll = false;
      } else {
        this.showingAll = true;
      }
      this.loading = true;
      query.onSnapshot((docs) => {
        this.teacherAttendances = [];
        this.allData = [];
        var overAllExecutionDatas = [
          {
            burtgesenNumber: 0,
            temdeglelNumber: 0,
            homeworkNumber: 0,
            sedevNumber: 0,
          },
          {
            burtgesenNumber: 0,
            temdeglelNumber: 0,
            homeworkNumber: 0,
            sedevNumber: 0,
          },
          {
            burtgesenNumber: 0,
            temdeglelNumber: 0,
            homeworkNumber: 0,
            sedevNumber: 0,
          },
          {
            burtgesenNumber: 0,
            temdeglelNumber: 0,
            homeworkNumber: 0,
            sedevNumber: 0,
          },
        ];
        docs.forEach((doc) => {
          let att = doc.data();
          att.ref = doc.ref;
          att.id = doc.id;
          att.teachedAt = new Date(att.year + "-" + att.month + "-" + att.day);
          if (
            att.selectedPreviousPlannedTopic &&
            att.selectedPreviousPlannedTopic.topicOriginRef &&
            (!att.selectedParentTopic ||
              !att.selectedSubSubTopic ||
              !att.selectedSubTopic)
          ) {
            att.selectedPreviousPlannedTopic.topicOriginRef
              .get()
              .then((doc) => {
                let topic3 = doc.data();
                topic3.id = doc.id;
                topic3.ref = doc.ref;
                topic3.ref.parent.parent.get().then((doc) => {
                  let topic2 = doc.data();
                  topic2.id = doc.id;
                  topic2.ref = doc.ref;
                  topic2.ref.parent.parent.get().then((doc) => {
                    let topic1 = doc.data();
                    topic1.id = doc.id;
                    topic1.ref = doc.ref;
                    // console.log(
                    //   att.month,
                    //   att.day,
                    //   att.selectedClassGroup.programData.STUDENT_GROUP_NAME,
                    //   att.selectedParentTopic,
                    //   att.selectedSubSubTopic,
                    //   att.selectedSubTopic
                    // );
                    att.ref.update({
                      selectedParentTopic: topic1,
                      selectedSubTopic: topic2,
                      selectedSubSubTopic: topic3,
                      selectedPreviousPlannedTopic: null,
                    });
                  });
                });
              });
          }
          if (!att.weekNumber) {
            var wN = this._calWeekNumber(att);
            if (wN) att.ref.update({ weekNumber: wN });
          }
          this._calWeekNumber(att);
          this._calDayName(att);
          this.fixSemester(att);
          if (att.semester == undefined) {
            this._setSemeter(att, true);
            if (att.semester == undefined) {
              console.log(
                "YESSSSSSYESSSSSSYESSSSSSYESSSSSSYESSSSSS!!!!!!!!",
                att.ref.path
              );
            }
          }
          this.isAllowed(att);
          this.fixExecutionData(att);
          this.fixExecutionYear(att);
          this.calculateSemestersDataForManager(
            att,
            overAllExecutionDatas,
            att.semester
          );
          this.teacherAttendances.push(att); //99
          if (!this.allData[att.weekNumber]) {
            this.allData[att.weekNumber] = [att];
          } else {
            this.allData[att.weekNumber].push(att);
          }
        });
        for (var k = 0; k < overAllExecutionDatas.length; k++) {
          var docRef = this.teacherData.school.ref
            .collection(
              "teachersExecutionOverview-" + this.teacherData.school.currentYear
            )
            .doc(this.teacherData.id)
            .collection("semesters")
            .doc(String(k + 1));
          if (this.currentSelectedSemester == k + 1) {
            docRef.set(overAllExecutionDatas[k], { merge: true });
          }
        }
        this.loading = false;
        if (toUpdate) this.$forceUpdate();
      });
    },
    async fixExecutionData(att) {
      var go = false;
      var go2 = false;
      if (att) {
        if (go) {
          console.log(att);
          if (att.subLessons) {
            att.subLessons.forEach((lesson) => {
              var xx = lesson.byTeachers.find(
                (teacher) =>
                  teacher.teacherRef.path == this.teacherData.ref.path
              );
              if (xx) {
                att.selectedLesson = lesson;
              }
            });
            console.log(att.selectedLesson, " 111");
          }
          if (
            att.selectedLesson &&
            att.selectedLesson.esisLessonType &&
            att.selectedLesson.esisLessonType.esisLessonTypeId > 1
          ) {
            console.log(att);
            att.selectedLesson.ref
              .collection("lesson-groups")
              .where("teacher.teacherRef", "==", this.teacherData.ref)
              .get()
              .then((docs) => {
                var studentIds = [];
                docs.forEach(async (doc) => {
                  await doc.ref
                    .collection("students")
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        studentIds.push(doc.data().PERSON_ID);
                      });
                    });
                });
                setTimeout(() => {
                  if (studentIds.length > 0) {
                    att.selectedLesson.classGroups[0].classGroupRef
                      .collection(
                        "attendances-" + this.teacherData.school.currentYear
                      )
                      .where("day", "==", att.day)
                      .where("month", "==", att.month)
                      .where("year", "==", att.year)
                      .where("xeelj", "==", att.xeelj)
                      .get()
                      .then((docs) => {
                        var sick = 0;
                        var absent = 0;
                        var excused = 0;
                        var online = 0;
                        docs.forEach((doc) => {
                          var attData = doc.data();
                          attData.ref = doc.ref;
                          attData.id = doc.id;
                          if (!(attData.studentId in studentIds)) {
                            if (attData.text == "Тасалсан") {
                              absent++;
                            } else if (attData.text == "Өвчтэй") {
                              sick++;
                            } else if (attData.text == "Чөлөөтэй") {
                              excused++;
                            } else if (attData.text == "Зайнаас") {
                              online++;
                            }
                          }
                        });

                        var tmp = {
                          studentsAbsent: absent,
                          studentsExcused: excused,
                          studentsOnline: online,
                          studentsPresent:
                            studentIds.length -
                            sick -
                            absent -
                            excused -
                            online,
                          studentsSick: sick,
                        };
                        att.ref.update(tmp).then(() => {
                          console.log(
                            studentIds.length,
                            sick,
                            absent,
                            excused,
                            online,
                            att.ref.path,
                            "updated"
                          );
                        });
                      });
                  }
                }, 2000);
              });
          }
          if (go2) {
            if (
              att.selectedLessonGroup == null &&
              att.generated == true &&
              att.selectedLesson &&
              att.selectedLesson.calendarData
            ) {
              att.selectedLesson.calendarData.forEach(async (cd) => {
                if (cd.lessonGroup && cd.xeelj == att.xeelj) {
                  var selectedYear = att.year;
                  var selectedMonth = att.month;
                  var selectedDay = att.day;
                  var studentsSick = 0;
                  var studentsAbsent = 0;
                  var studentsExcused = 0;
                  var studentsOnline = 0;
                  var studentsPresent = 0;
                  var lgs = await att.selectedLesson.ref
                    .collection("lesson-groups")
                    .where("groupIndex", "==", cd.lessonGroup.groupIndex)
                    .get();
                  var lg2 = lgs.docs[0];
                  let lgData = lg2.data();
                  lgData.ref = lg2.ref;
                  lgData.id = lg2.id;
                  var lgStud = await lg2.ref.collection("students").get();
                  if (lg2.exists) {
                    if (lg2.data().allStudentsSelected) {
                      var classIrz =
                        await att.selectedLesson.classGroups[0].classGroupRef
                          .collection(
                            "attendances-" + this.teacherData.school.currentYear
                          )
                          .doc(
                            selectedYear +
                              "-" +
                              selectedMonth +
                              "-" +
                              selectedDay
                          )
                          .get();
                      var irz = classIrz.data();
                      console.log(irz, classIrz);
                      studentsPresent =
                        classIrz.exists && irz.studentsPresent
                          ? irz.studentsPresent
                          : 0;
                      studentsAbsent =
                        classIrz.exists && irz.studentsAbsent
                          ? irz.studentsAbsent
                          : 0;
                      studentsExcused =
                        classIrz.exists && irz.studentsExcused
                          ? irz.studentsExcused
                          : 0;
                      studentsSick =
                        classIrz.exists && irz.studentsSick
                          ? irz.studentsSick
                          : 0;
                      studentsOnline =
                        classIrz.exists && irz.studentsOnline
                          ? irz.studentsOnline
                          : 0;
                    } else {
                      var classIrz2 =
                        await att.selectedLesson.classGroups[0].classGroupRef
                          .collection(
                            "attendances-" + this.teacherData.school.currentYear
                          )
                          .where("day", "==", Number(selectedDay))
                          .where("month", "==", Number(selectedMonth))
                          .where("year", "==", Number(selectedYear))
                          .where("xeelj", "==", cd.xeelj)
                          .where("daily_attendance", "==", false)
                          .get();
                      studentsSick = 0;
                      studentsAbsent = 0;
                      studentsExcused = 0;
                      studentsOnline = 0;
                      lgStud.forEach((doc) => {
                        let student = doc.data();
                        student.ref = doc.ref;
                        student.id = doc.id;
                        if (
                          student.STUDENT_GROUP_ID ==
                          att.selectedLesson.classGroupRefs[0].id
                        ) {
                          classIrz2.forEach((doc) => {
                            let att = doc.data();
                            att.ref = doc.ref;
                            att.id = doc.id;

                            if (student.PERSON_ID == att.studentId) {
                              if (att.text == "Тасалсан") {
                                studentsAbsent++;
                              } else if (att.text == "Өвчтэй") {
                                studentsSick++;
                              } else if (att.text == "Чөлөөтэй") {
                                studentsExcused++;
                              } else if (att.text == "Зайнаас") {
                                studentsOnline++;
                              }
                            }
                          });
                        }
                      });
                      studentsPresent =
                        lgStud.size -
                        studentsSick -
                        studentsAbsent -
                        studentsExcused -
                        studentsOnline;
                    }
                    setTimeout(() => {
                      att.ref.update({
                        selectedLessonGroup: lgData,
                        studentsSick: studentsSick,
                        studentsAbsent: studentsAbsent,
                        studentsExcused: studentsExcused,
                        studentsOnline: studentsOnline,
                        studentsPresent: studentsPresent,
                      });
                      // .then(() => {
                      //   console.log(att.ref.path);
                      // });
                    }, 5000);
                  }
                }
              });
            }
          }
        }
      }
    },
    fixExecutionYear(att) {
      var go = true;
      if (go) {
        if (att.semester <= 2 && att.year == 2024) {
          var teachedAt = new Date(
            this.teacherData.school.currentYear,
            att.month - 1,
            att.day
          );
          att.ref.update({
            year: Number(this.teacherData.school.currentYear),
            teachedAt: teachedAt,
          });
        }
      }
    },
    _calWeekNumber(att) {
      var found = null;
      var ss = this.calendarButez.find(
        (ss) => ss.session == this.currentSelectedSemester
      );
      for (const mm of ss.months) {
        if (mm.name == att.month) {
          for (const dd of mm.days) {
            if (dd.day == att.day) {
              att.weekNumber = dd.weekNumber;
              found = dd.weekNumber;
              break;
            }
          }
        }
      }
      return found;
    },
    _calDayName(att) {
      var tmpDate = new Date(
        att.year +
          "-" +
          (att.month < 10 ? "0" + att.month : att.month) +
          "-" +
          (att.day < 10 ? "0" + att.day : att.day)
      );
      var dayName = tmpDate.toUTCString().substring(0, 3);

      if (dayName == "Mon") {
        att.dayName = this.dayNames[1];
      } else if (dayName == "Tue") {
        att.dayName = this.dayNames[2];
      } else if (dayName == "Wed") {
        att.dayName = this.dayNames[3];
      } else if (dayName == "Thu") {
        att.dayName = this.dayNames[4];
      } else if (dayName == "Fri") {
        att.dayName = this.dayNames[5];
      } else if (dayName == "Sat") {
        att.dayName = this.dayNames[6];
      } else if (dayName == "Sun") {
        att.dayName = this.dayNames[0];
      }
    },

    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
  },
  created() {
    if (this.selectedTeacher) {
      this.teacherData = this.selectedTeacher;
    } else {
      this.teacherData = this.userData;
    }
    // this.currentSchoolWeek = this._getCurrentSchoolWeekNumber();
    this.currentSchoolWeek = this._getCurrentSchoolWeekNumber();
    this.currentSelectedWeek = this.currentSchoolWeek;
    if (this.filterClass) {
      this.currentSelectedLesson = this.filterClass;
    }
    if (this.lessonObject != null) {
      this.temporarlySelectedLesson = this.lessonObject;
      if (this.temporarlySelectedLesson.classGroups) {
        this.temporarlySelectedLesson.classGroups.forEach((doc) => {
          if (!doc.name2) doc.name2 = doc.departmentName + doc.classGroupName;
        });
      }
    }
    this.selectedMonth = new Date().getMonth() + 1;
    var tmpDate = new Date(
      new Date().getFullYear() + "-" + this.selectedMonth < 10
        ? "0" + this.selectedMonth
        : this.selectedMonth + "-" + this.selectedDay < 10
        ? "0" + this.selectedDay
        : this.selectedDay
    );
    if (tmpDate.getDay() != 0 && tmpDate.getDay != 6) {
      this.selectedDay = new Date().getDate();
    }
    // this.selectedYear = Number(this.teacherData.school.currentYear);
    this.selectedYear = new Date().getFullYear();
    this._setupp();
    fb.db.collection("xlessonStudentNote").onSnapshot((querySnapshot) => {
      this.xlessonStudentNotes = [];
      querySnapshot.forEach((doc) => {
        let xDuty = doc.data();
        xDuty.id = doc.id;
        xDuty.ref = doc.ref;
        this.xlessonStudentNotes.push(xDuty);
      });
    });
    this.userData.school.ref
      .collection("xeelj")
      .orderBy("classLevelID", "asc")
      .get()
      .then((docs) => {
        this.schoolEeljs = [];
        docs.docs.forEach((doc) => {
          let eelj = doc.data();
          eelj.id = doc.id;
          eelj.ref = doc.ref;
          this.schoolEeljs.push(eelj);
        });
      });
    this.bodyNames = ["firstName"];
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.bborder td {
  border-right: 1px solid #bbb !important;
}

.bborder th {
  border-right: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.bborder .borderCell:hover {
  background-color: red;
}
.half-screen-dialog {
  width: 50% !important;
  max-width: none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: none;
}
