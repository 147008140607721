<template>
  <div style="background-color: white !important">
    <v-progress-linear
      v-if="$attrs.loadingCalendarData"
      color="red"
      height="6"
      indeterminate
    ></v-progress-linear>
    <v-toolbar flat style="height: 100px">
      <v-tabs v-model="xSelectedTab" class="px-0 pt-10px">
        <v-tab :class="xSelectedTab == 0 ? 'amber' : ''">
          Ээлж 1({{ _getCalNumber(1) }})</v-tab
        >
        <v-tab :class="xSelectedTab == 1 ? 'amber' : ''">
          Ээлж 2({{_getCalNumber(2)}})
        </v-tab>
        <v-spacer></v-spacer>
        <!-- <v-sparkline
          :smooth="16"
          :fill="true"
          color="#e0e0e0"
          stroke-linecap="round"
          auto-draw
          :line-width="4"
          :value="[0, 4, 0, 1, 0, 4, 0]"
        ></v-sparkline>
        <v-btn
          @click="_generateIrzAutomatically"
          class="mt-4 text-uppercase border-radius-sm"
          elevation="0"
          color="green"
          >Автоматаар үүсгэx</v-btn
        > -->
      </v-tabs>
    </v-toolbar>
    <v-tabs-items v-model="xSelectedTab" class="px-0">
      <v-tab-item>
        <TeacherCalendarTable
          :schoolEeljs="schoolEeljs"
          :calendarXeelj="1"
          :calendarData="$attrs.calendarData"
          v-bind="$attrs"
          :filteredLessons="$attrs.filteredLessons"
          :currentSelectedSemester="$attrs.currentSelectedSemester"
          :currentSelectedWeek="$attrs.currentSelectedWeek"
          :currentWeekDays="$attrs.currentWeekDays"
          :allData="$attrs.allData"
          :selectedWeekExecutions="$attrs.selectedWeekExecutions"
        >
        </TeacherCalendarTable>
      </v-tab-item>
      <v-tab-item>
        <TeacherCalendarTable
          :schoolEeljs="schoolEeljs"
          :calendarXeelj="2"
          :calendarData="$attrs.calendarData"
          v-bind="$attrs"
          :filteredLessons="$attrs.filteredLessons"
          :currentSelectedSemester="$attrs.currentSelectedSemester"
          :currentSelectedWeek="$attrs.currentSelectedWeek"
          :allData="$attrs.allData"
          :selectedWeekExecutions="$attrs.selectedWeekExecutions"
        >
        </TeacherCalendarTable>
      </v-tab-item>
    </v-tabs-items>
    <!-- <v-tabs>
      <v-tab item-key="tab1" class="amber" v-model="selectedTab">Ээлж 1</v-tab>
      <v-tab item-key="tab2"  v-model="selectedTab">Ээлж 2 {{ selectedTab }}</v-tab>
      <v-tab-item item-key="tab1">
    
      </v-tab-item>
      <v-tab-item item-key="tab2">
       
      </v-tab-item>
    </v-tabs> -->
  </div>
</template>
<script>
import { sync } from "vuex-pathify";
import TeacherCalendarTable from "./TeacherCalendarTable.vue";
export default {
  data() {
    return {
      xSelectedTab: null,
      kBgColorGroup: "#c4ddf6",
      kBgColorElective: "#eccaff",
      kBgColor: "#fff2ca",
      kFgColor: "black",
      days: [
        { name: "Даваа", dayIndex: 1 },
        { name: "Мягмар", dayIndex: 2 },
        { name: "Лхагва", dayIndex: 3 },
        { name: "Пүрэв", dayIndex: 4 },
        { name: "Баасан", dayIndex: 5 },
        // { name: "Бямба", dayIndex: 6 },
        // { name: "Ням", dayIndex: 7 },
      ],
      xeeljs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      schoolEeljs: null,
    };
  },
  components: {
    TeacherCalendarTable,
  },
  computed: {
    ...sync("*"),
    xxx() {
      if (this.$attrs.calendarData) this.$attrs.calendarData.length;
      return 0;
    },
  },
  methods: {
    _getCalNumber(val) {
      var counter = 0
      for (const ca of this.$attrs.calendarData) {
        if (
          ca.calendarEelj &&
          ca.calendarEelj.eeljId.endsWith("-" + val)
        ) {
          counter++
        }  
      }
      return counter
    },
    _getDate(dayIndex) {
      var cc = this.$attrs.currentWeekDays
        ? this.$attrs.currentWeekDays.find((cwd) => cwd.garag == dayIndex)
        : null;
      if (cc) return "2024" + "-" + cc.month + "-" + cc.day;
      else return null;
    },
    _findClassGroupsNameOfDay(dayIndex) {
      var list = this._findClassGroupsOfMandetoryLessons(dayIndex);
      const concatenatedString = list
        .map((cg) => cg.classGroupFullName)
        .join(",");
      return concatenatedString;
    },
    _findClassGroupsOfMandetoryLessons(dayIndex) {
      var list = [];
      for (const cal of this.$attrs.calendarData) {
        if (cal.dayIndex == dayIndex) {
          for (const cg of cal.classGroups) {
            var found = list.find(
              (c) => c.classGroupRef.id == cg.classGroupRef.id
            );
            if (!found) {
              list.push(cg);
            }
          } //for
        }
      }
      return list;
    },
    async _generateDayIrz(dayIndex) {
      var theDateStr = this._getDate(dayIndex);
      var classGroupsZaaval = this._findClassGroupsOfMandetoryLessons(dayIndex);
      for (const cg of classGroupsZaaval) {
        var dailyIrz = await cg.classGroupRef
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(theDateStr)
          .get();
        if (dailyIrz.exists) {
          var dd = dailyIrz.data();
          console.log(
            cg.classGroupFullName,
            "exists",
            dd.studentsPresent,
            dd.studentsAbsent,
            dd.studentsSick,
            dd.studentsExcused,
            dd.studentsOnline
          );
        } else {
          console.log(cg.classGroupFullName, "no");
        }
      }
    },
    async _generateIrzAutomatically() {
      var startTime = Date.now();
      for (const dd of [1, 2, 3, 4, 5]) {
        console.log("Day-" + dd);
        await this._generateDayIrz(dd);
        console.log("------");
      }
      var endTime = Date.now();
      const elapsedTimeInSeconds = (endTime - startTime) / 1000;
      console.log(`Time taken: ${elapsedTimeInSeconds} seconds`);
      // var classGroupsZaaval = []
      // // var startTime = Date.now()
      // for(const ll of this.$attrs.lessons){
      //   if(ll.esisLessonType.esisLessonTypeId==1){
      //     for(const cg of ll.classGroups){
      //       var found = classGroupsZaaval.find(c=>c.classGroupRef.id == cg.classGroupRef.id)
      //       if(!found) {
      //         classGroupsZaaval.push(cg)
      //       }
      //     }
      //   }
      // }
      // for(const cg of classGroupsZaaval){
      //   console.log(cg.classGroupFullName)
      // }
      // for(const dd of this.$attrs.currentWeekDays){
      //   // console.log(dd.year, dd.month+"/"+dd.day, dd.isHoliday)
      //   if(!dd.isHoliday){
      //     for(const kk of [1,2,3,4,5,6,7,8,9,10,11,12,13 ]){
      //       for(const cc of classGroupsZaaval){
      //       // console.log(cc.classGroupFullName)
      //       // console.log(cc.classGroupRef.path,this.userData.school.currentYear)
      //       var dailyIrz = await cc.classGroupRef.collection("attendances-"+ this.userData.school.currentYear)
      //       .doc("2024"+"-"+dd.month+"-"+dd.day)
      //       .get()
      //       console.log(dailyIrz,kk)
      //       }
      //     }
      //   }
      // }
      // var endTime = Date.now()
      // const elapsedTimeInSeconds = (endTime - startTime) / 1000;
      // console.log(`Time taken: ${elapsedTimeInSeconds} seconds`);
      // for(const cc of this.$attrs.calendarData){
      //   console.log(cc)
      // }
    },
  },
  created() {
    console.log("userData", this.userData);
    this.userData.school.ref
      .collection("xeelj")
      .get()
      .then((docs) => {
        this.schoolEeljs = [];
        docs.docs.forEach((doc) => {
          let eelj = doc.data();
          eelj.id = doc.id;
          eelj.ref = doc.ref;
          this.schoolEeljs.push(eelj);
        });
      });
  },
};
</script>

<style>
.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
</style>
